
import React from "react";
import styled from "styled-components";
import Loader from "components/UI/Loader";
import { useHistory } from "react-router-dom";
import Add from "components/UI/Icons/Add";
import Button from "components/UI/Buttons/Button";
import Status from "components/UI/Status";

const Container = styled.div`
  margin-top:2rem;
  width: 100%;
  table {
    margin-top:2rem;
  }
`;

const InvitesGrid = ({ items }) => {

  return (
    <Container> 
     <label>Uitnodigen</label>
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Naam</th>
            <th>E-mail</th> 
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {!items 
          ? <Loader center />
           : <> 
              {items.map((item, idx) => (          
                  <tr key={item.id + '_' + idx}> 
                    <td></td>              
                    <td>{item.name}</td>   
                    <td>{item.email}</td>    
                    {item.status && <td><Status className={item.status.replace(/\s/g, '')} label={item.status}/></td> } 
                  </tr>      
              ))}
             </>
        }
        </tbody>
      </table>
    </Container>
  );
};

export default InvitesGrid;
