import React from "react";
import styled from "styled-components";

const Body = styled.div`
  width: ${(props) => (props.fullWidth ? "100%" : "95%")};
  margin: 0 auto;
  padding: ${(props) => (props.fullWidth ? "0" : "4rem 0")};
  max-width: ${(props) => (props.fullWidth ? "100%" : "120rem")};
  .page__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    & .page__header__sub {
      ul {
        display: flex;
        li {
          margin-left: 1rem;
        }
      }
    }
  }
`;

const Page = ({ fullWidth, children }) => {
  return <Body fullWidth={fullWidth}>{children}</Body>;
};

export default Page;
