import { getAxiosClient } from "../axios";

export const getContacts = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/contacts`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addContact = async (organizationId, contact) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .post(`/organizations/${organizationId}/contacts`, contact)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getContact = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/contacts/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const updateContact = async (contact) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/contacts/${contact.id}`, contact)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const deleteContact = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .delete(`/contacts/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

