import React, { useState } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useHistory } from "react-router-dom";
import { UseErrorContext } from "contexts";
import { addCampaign } from "api/services/campaign";
import { UseOrganizationContext } from "contexts";
import CampaignWizard from "./Components/CampaignWizard";

const AddCampaign = () => {
  const history = useHistory();
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const organizationId = UseOrganizationContext()?.id;

  const onSave = async (campaignData) => {
    try{
      setIsLoading(true);
      const rep = await addCampaign(organizationId, campaignData); 
      setIsLoading(false);         
      history.push("/campaigns/view/" + rep.id);
      return rep;          
    } catch (err) {
      updateError(err);
    }    
    setIsLoading(false);        
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <PageHeaderSub>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
           <CampaignWizard save={onSave}/>
        </div>
      </Page>
    </>
  );
};

export default AddCampaign;
