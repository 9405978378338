import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getVoucher, updateVoucher, deleteVoucher } from "api/services/voucher";
import { VoucherForm } from "./Components/VoucherForm";
import PageHeaderSub from "components/PageHeaderSub";
import Status from "components/UI/Status";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import Delete from "components/UI/Icons/Delete";
import { UseOrganizationContext } from "contexts";

const Container = styled.div``;

const EditVoucher = () => {
  const { id } = useParams();
  const history = useHistory();
  const [voucher, setVoucher] = useState(null);
  const { updateError } = UseErrorContext();
  const [isSaving, setIsSaving] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const organizationId = UseOrganizationContext()?.id;

  useEffect(async () => {
    try{
      if (!organizationId) return;
      const voucherData = await getVoucher(organizationId, id);
      setVoucher(voucherData);    
    } catch (err) {
      updateError(err);
    }
  }, [organizationId]);

  const onSave = async (voucherData) => {
    try{
      setIsSaving(true);
      const newObject = { ...voucher, ...voucherData };
      const resp = await updateVoucher(newObject);
      setIsSaving(false);
      history.push("/vouchers/view/" + voucher.id + "/false");   
    } catch (err) {
      updateError(err);
    }
  };

  const onClose = async () => {
    history.push("/vouchers/view/" + voucher.id + "/false");   
  };

  const _delete = async () => {
    try{
      await deleteVoucher(voucher.id);
      setShowModalDelete(false);
      onClose();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      <Page>
        {!voucher ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link={`/vouchers/view/${voucher.id}/false`}/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/vouchers/view">Cadeaubonnen</a>
                </li>
                <li>
                  <a href={`/vouchers/view/${voucher.id}/false`}>{voucher.barcode}</a>
                </li>
              </ul>
            </div>                 
            {showModalDelete && (
              <Modal title={`Verwijder cadeaubon "${voucher.barcode}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
                <div>Bent u zeker dat u deze cadeaubon wil verwijderen?</div>
              </Modal>
            )}  
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>    
                { voucher.status == "Actief" && 
                  <Button label="Verwijderen" onClick={async () => setShowModalDelete(true)} type="secondary"><Delete/></Button> 
                }
                <Status className={voucher.status} label={voucher.status}/> 
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Cadeaubon" extraOption={isSaving && "Opslaan..."}>
                <VoucherForm voucher={voucher} save={onSave} close={onClose} isEditing/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default EditVoucher;
