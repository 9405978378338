import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import { redeemVoucher } from "api/services/voucher";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import { UseOrganizationContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  redeemedPrice: Yup.number().required("Verplicht"),
});

const RedeemVoucherForm = ({ voucher, onClose }) => {
  const { updateError } = UseErrorContext();
  const organizationId = UseOrganizationContext()?.id;

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          redeemedPrice: voucher?.remainingPrice,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          redeemVoucher(organizationId, voucher?.id, values)
            .then((value) => {
              onClose();  
            })
            .catch((err) => {
              console.log(err)
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="redeemedPrice" className="required">
                  Bedrag (€)
                </label>
                <Field type="number" name="redeemedPrice" />
                <ErrorMessage className="error-message" name="redeemedPrice" component="div" />
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => onClose()} type="secondary" />
                <Button onClick={handleSubmit} label="Bevestig" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default RedeemVoucherForm;
