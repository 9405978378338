import { getAxiosClient } from "../axios";

export const getUser = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/users`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addUser = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/me/users`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrganizationUser = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/organizationusers`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addOrganizationUser = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/me/organizations/${id}/organizationusers`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updatePermissions = async (userID, permissions) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizationusers/${userID}/permissions`, { permissions })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const confirmInvite = async (id, orgUserId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/invites/${id}/confirm/${orgUserId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};
