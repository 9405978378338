import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getYearReport } from "api/services/admin";
import Loader from "components/UI/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Container = styled.div`
  margin-top:4rem;
  table{
    margin-top:2rem;
  }
`;

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

const AdminYearReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [chartData, setChartData] = useState({datasets: [],});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);
        const today = new Date();
        const year = today.getFullYear();
        const reportData = await getYearReport({
          year: year
        });
        
        setChartData({
          labels: reportData.labels,
          datasets: [
            {
              label: 'Verkocht winkel (€)',
              data: reportData.storeCreatedVouchers,
              backgroundColor: 'rgba(153, 102, 255, 0.5)',
            },
            {
              label: 'Verkocht online (€)',
              data: reportData.onlineCreatedVouchers,
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
              label: 'Ingewisseld (€)',
              data: reportData.redeemedVouchers,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
              label: 'Vervallen (€)',
              data: reportData.expiredVouchers,
              backgroundColor: 'rgba(233, 15, 15, 0.5)',
            },
          ]
        }); 
        
        setIsLoading(false);
      } catch (err) {
        updateError(err);
      }   
    }    
  }, [organizationId])

  return (
    <>
      {isLoading 
        ? <Loader />
        : <Bar options={options} data={chartData} />
      }
    </>    
  );
};

export default AdminYearReport;
