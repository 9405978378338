import { getAxiosClient } from "../axios";

export const AddSupportMessage = async (data) => {
  const axiosClient = await getAxiosClient();
console.log(data)
  return await axiosClient
    .put(`/support`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};