import React from "react";
import {Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard.js";
import Products from "../pages/products";
import Campaigns from "../pages/campaigns";
import Contacts from "../pages/contacts";
import EditProfile from "../pages/profile/edit-profile.js";
import Support from "../pages/support/support.js";
import SupportSuccess from "pages/support/support-success.js";
import Enveloppes from "../pages/enveloppes/enveloppes.js";
import EnveloppesSuccess from "pages/enveloppes/enveloppes-success.js";
import Login from "../pages/login.js";
import PrivateRoute from "components/PrivateRoute.js";
import User from "../pages/user/user.js";
import Vouchers from "../pages/vouchers";
import Unauthorized from "../pages/unauthorized.js";
import AdminOrganizations from "pages/admin/organizations/organizations.js";
import ViewAdminOrganization from "pages/admin/organizations/view-organization.js";
import AdminVouchers from "pages/admin/vouchers/vouchers.js";
import ViewAdminVoucher from "pages/admin/vouchers/view-voucher.js";
import Invoices from "pages/invoices/invoices.js";
import ViewInvoice from "pages/invoices/view-invoice.js";
import ViewBalanceLine from "pages/invoices/view-balanceline.js";
import AdminInvoices from "pages/admin/invoices/invoices.js";
import ViewAdminInvoice from "pages/admin/invoices/view-invoice.js";
import ViewAdminBalanceLine from "pages/admin/invoices/view-balanceline.js";
import Orders from "pages/admin/orders/orders.js";
import ViewOrder from "pages/admin/orders/view-order.js";
import ViewAdminCustomer from "pages/admin/orders/view-customer.js";
import InviteRegistration from "pages/user/invite-registration.js";
import ViewPersonnel from "pages/Personnel/view-personnel.js";

export const LoginRoutes = () => {
  return (
    <Switch>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
};

export const AppRoutes = () => {
  return (
    <Switch>

      {/* Routes */}
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route> 
      <Route exact path="/support">
        <Support />
      </Route>
      <Route exact path="/support/success">
        <SupportSuccess />
      </Route>
      <Route exact path="/user">
        <User />
      </Route>
      <Route exact path="/unauthorized">
        <Unauthorized />
      </Route>
      <Route exact path="/invites/:id">
        <InviteRegistration />
      </Route>
      <Route exact path="/enveloppes">
        <Enveloppes />
      </Route>
      <Route exact path="/enveloppes/success">
        <EnveloppesSuccess />
      </Route>

      {/* Private routes */}
      <PrivateRoute exact path="/products" roles={["Products.Read"]} component={Products.Products}/>
      <PrivateRoute exact path="/products/view/:id" roles={["Products.Read"]} component={Products.ViewProduct}/>
      <PrivateRoute exact path="/products/edit/:id" roles={["Products.Create"]} component={Products.EditProduct}/>
      <PrivateRoute exact path="/products/add" roles={["Products.Create"]} component={Products.AddProduct}/>

      <PrivateRoute exact path="/vouchers/view" roles={["Vouchers.Read"]} component={Vouchers.Vouchers}/>
      <PrivateRoute exact path="/vouchers/view/:id/:openRedeem" roles={["Vouchers.Read"]} component={Vouchers.ViewVoucher}/>
      <PrivateRoute exact path="/vouchers/edit/:id" roles={["Vouchers.Create"]} component={Vouchers.EditVoucher}/>
      <PrivateRoute exact path="/vouchers/add" roles={["Vouchers.Create"]} component={Vouchers.AddVoucher}/>
      <PrivateRoute exact path="/vouchers/add/:productId" roles={["Vouchers.Create"]} component={Vouchers.AddVoucher}/>
      <PrivateRoute exact path="/vouchers/scan" roles={["Vouchers.Read"]} component={Vouchers.SearchVoucher}/>

      <PrivateRoute exact path="/profile" roles={["Organization.Read"]} component={EditProfile}/>

      <PrivateRoute exact path="/campaigns" roles={["Campaigns.Read"]} component={Campaigns.Campaigns}/>
      <PrivateRoute exact path="/campaigns/view/:id" roles={["Campaigns.Read"]} component={Campaigns.ViewCampaign}/>
      <PrivateRoute exact path="/campaigns/add" roles={["Campaigns.Create"]} component={Campaigns.AddCampaign}/>
      <PrivateRoute exact path="/campaigns/view/:id/payments" roles={["Campaigns.Create"]} component={Campaigns.CampaignPayments}/>

      <PrivateRoute exact path="/contacts" roles={["Contacts.Read"]} component={Contacts.Contacts}/>
      <PrivateRoute exact path="/contacts/view/:id" roles={["Contacts.Read"]} component={Contacts.ViewContact}/>
      <PrivateRoute exact path="/contacts/edit/:id" roles={["Contacts.Create"]} component={Contacts.EditContact}/>
      <PrivateRoute exact path="/contacts/add" roles={["Contacts.Create"]} component={Contacts.AddContact}/>


      <PrivateRoute exact path="/invoices" roles={["Invoices.Read"]} component={Invoices}/>
      <PrivateRoute exact path="/invoices/view/:id" roles={["Invoices.Read"]} component={ViewInvoice}/>
      <PrivateRoute exact path="/invoices/view/:invoiceId/:id" roles={["Invoices.Read"]} component={ViewBalanceLine}/>

      <PrivateRoute exact path="/personnel" roles={["Organization.Read"]} component={ViewPersonnel}/>

      {/* Admin Private routes */}
      <PrivateRoute exact path="/admin/organizations" roles={["Admin"]} component={AdminOrganizations}/>
      <PrivateRoute exact path="/admin/organizations/:id" roles={["Admin"]} component={ViewAdminOrganization}/>
      <PrivateRoute exact path="/admin/vouchers" roles={["Admin"]} component={AdminVouchers}/>
      <PrivateRoute exact path="/admin/vouchers/:id" roles={["Admin"]} component={ViewAdminVoucher}/>
      <PrivateRoute exact path="/admin/invoices" roles={["Admin"]} component={AdminInvoices}/>
      <PrivateRoute exact path="/admin/invoices/:id" roles={["Admin"]} component={ViewAdminInvoice}/>
      <PrivateRoute exact path="/admin/invoices/:invoiceId/:id" roles={["Admin"]} component={ViewAdminBalanceLine}/>
      <PrivateRoute exact path="/admin/orders" roles={["Admin"]} component={Orders}/>
      <PrivateRoute exact path="/admin/orders/:id" roles={["Admin"]} component={ViewOrder}/>
      <PrivateRoute exact path="/admin/orders/:orderId/customer/:id" roles={["Admin"]} component={ViewAdminCustomer}/>
      
    </Switch>
  );
};
