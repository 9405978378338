import React from "react";
import styled from "styled-components";
import Back from "../Icons/Back";
import { useHistory } from "react-router-dom";

const Container = styled.div`
`;

export const BackButton = ({link}) => {  
  const history = useHistory();

  return (
    <div onClick={() => history.push(link)} className="icon-button back">
      <Back />
      <label>terug</label>
    </div>
  );
};