import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { ProductForm } from "pages/products/Components/ProductForm";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getProduct, updateProduct, uploadCover } from "api/services/product";

const Container = styled.div``;

const EditProduct = () => {
  const { id } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { updateError } = UseErrorContext();

  const _updateProduct = async (productData) => {
    try{
      setIsSaving(true);
      const newObject = { ...product, ...productData };
      const resp = await updateProduct(newObject);
      
      if (productData.cover) {
        if (productData.cover != product.cover) {
          await uploadCover(product.id, productData.cover);
        }
      }

      setIsSaving(false);
      history.push("/products");    
    } catch (err) {
      updateError(err);
    }
    finally{
      setIsSaving(false);
    }
  };

  useEffect(async () => {
    try{
      const productData = await getProduct(id);      
      setProduct(productData);    
    } catch (err) {
      updateError(err);
    }
  }, []);

  return (
    <>
      <Page>
        {!product ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/products"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/products">Producten</a>
                </li>
                <li>
                  <span className="selected">{product?.displayName}</span>
                </li>
              </ul>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Product wijzigen" extraOption={isSaving && "Opslaan..."}>
                {isSaving 
                  ? <Loader />
                  : <ProductForm product={product} save={_updateProduct} />
                }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default EditProduct;
