import { getAxiosClient } from "../axios";

  export const getDayReport = async (id, params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/reports/day`, { params: {...params}} )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getMonthReport = async (id, params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/reports/month`, { params: {...params}} )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getYearReport = async (id, params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/reports/year`, { params: {...params}} )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getCampaignReport = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/reports/campaign`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getProductReport = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/reports/product`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getProfitReport = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/reports/profit` )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };