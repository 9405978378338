import React, { useState, useEffect } from "react";
import { UseOrganizationContext } from "contexts";
import { ProfileForm } from "./Components/ProfileForm";
import Loader from "components/UI/Loader";
import { getOrganization } from "api/services/organization";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { editOrganization, uploadLogo } from "api/services/organization";
import { UseErrorContext } from "contexts";
import Status from "components/UI/Status";
import Button from "components/UI/Buttons/Button";
import Shop from "components/UI/Icons/Shop";
import { TownForm } from "./Components/TownForm";
import { CompanyForm } from "./Components/CompanyForm";

const EditProfile = () => {
  const { id, status } = UseOrganizationContext();
  const [isSaving, setIsSaving] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refreshData();
  }, [id]);

  const refreshData = async () => {
    if(id){
      try{
        let organizationData = await getOrganization(id);
        setProfileData(organizationData);    
      } catch (err) {
        updateError(err);
      }
    }
  };

  const _editOrganization = async (data) => {
    setIsSaving(true);
    const newObject = { ...profileData, ...data };

    try{
      await editOrganization(id, newObject);  
      if (data.cover) {
        if (data.cover != profileData.logoUrl) {
          await uploadLogo(id, data.cover);
        }
      }

      await refreshData();

    } catch (err) {
      updateError(err);
    }  
    finally{
      setIsSaving(false);
    }
  };

  return (
      <Page>
        <div className="page__header">
          <h1>Profiel</h1>
              <PageHeaderSub show>   
                { status && status == "Actief" && 
                  <a href={`https://www.allesbon.be/products/${profileData?.slug ?? id}`} target="_blank">
                    <Button label="Shop" type="secondary"><Shop/></Button> 
                  </a>
                }
                { status && <Status className={status.replace(/\s/g, '')} label={status}/> }
              </PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Profiel wijzigen" extraOption={isSaving && "Opslaan..."}>
            { !profileData || isSaving
            ? <Loader/>
            : profileData.isCompany 
                  ? <CompanyForm profile={profileData} save={_editOrganization}/> 
                  : profileData.isTown 
                    ? <TownForm profile={profileData} save={_editOrganization}/> 
                    : <ProfileForm profile={profileData} save={_editOrganization}/> 
            }                    
          </HeaderDropDown>
        </div>
      </Page>    
  );
};

export default EditProfile;
