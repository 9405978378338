import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getOrganizationsReport } from "api/services/admin";
import Loader from "components/UI/Loader";
import Pagination from "components/Pagination";
import { GetPagination } from "global/datahelper";

const Container = styled.div`
  table{
    margin-top:2rem;
  }
`;

const AdminOrganizationReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [organizationsData, setOrganizationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);

        const today = new Date();
        const year = today.getFullYear();
        setYear(year);
        const reportData = await getOrganizationsReport({
          'page': currentPage,
          'year': year
        });
        setOrganizationsData(reportData.data);

        // Pagination
        let header = reportData.headers; 
        
        let pagination = GetPagination(header);
        let totalCount = pagination.totalCount;
        let pageSize = pagination.pageSize;
        let nbrOfPages = Math.ceil(totalCount / pageSize);
              
        setNumberOfPages(nbrOfPages);

        setIsLoading(false);
      } catch (err) {
        updateError(err);
      }   
    }    
  }, [organizationId])

  return (
    <Container>
      {isLoading 
        ? <Loader />
        : 
        <>
        {/* <p>Jaar: {year}</p> */}
          <table cellSpacing="500">
            <thead>
              <tr>            
                <th></th>
                <th>Naam</th>
                <th>Open</th>
                <th>Ingewisseld</th>
                <th>Vervallen</th>
              </tr>
            </thead>
            <tbody>
              {organizationsData.map((organization, idx) => (            
                  <tr key={organization.id + '_' + idx}>   
                    <td></td>           
                    <td>{organization.name}</td>
                    <td>{organization.open}</td>   
                    <td>{organization.closed}</td>  
                    <td>{organization.expired}</td>    
                  </tr>          
              ))}
            </tbody>
          </table>
          <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
      }
    </Container>    
  );
};

export default AdminOrganizationReport;
