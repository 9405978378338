import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString, getPrice } from "global/datahelper";
import Status from "components/UI/Status";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }
`;

const AdminVoucherGrid = ({ vouchers }) => {
  const history = useHistory();

  const open = (voucherId) => {   
    history.push("/admin/vouchers/" + voucherId);
  };

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Organisatie</th>
            <th>Product</th>
            <th>Barcode</th>
            <th>Prijs</th>
            <th>Aangemaakt op</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {vouchers.map((voucher, idx) => (            
              <tr key={voucher.id + '_' + idx} onClick={() => open(voucher.id)} className="clickable">   
                <td></td>           
                <td>{voucher.product.organization.name}</td>
                <td>{voucher.product.displayName}</td>
                <td>{voucher.barcode}</td>
                <td>{getPrice(voucher)}</td>
                <td>{FormatDateTimeToString(voucher.createDate)}</td>   
                <td><Status className={voucher.status} label={voucher.status}/></td> 
             </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

AdminVoucherGrid.defaultProps = {
  vouchers: Array.from(Array(0)),
};

export default AdminVoucherGrid;
