import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import { generateInvoice, getAdminOrganizations } from "api/services/admin";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  month: Yup.number().required("Verplicht"),
  year: Yup.number().required("Verplicht"),
  organizationId: Yup.string().required("Verplicht"),
});

const CreateInvoiceForm = ({ onClose }) => {
  const { updateError } = UseErrorContext();
  const [ organizations, setOrganizations ] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    try{
      var res = await getAdminOrganizations();
      setOrganizations(res.data);
    } catch (err) {
      updateError(err);
    }
  }, []);

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
          organizationId: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          generateInvoice(values)
            .then((value) => {
              history.push(`/admin/invoices/${value}`)
            })
            .catch((err) => {
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form"> 
           <div className="form_row">
              <div className="field">
                <label htmlFor="organizationId" className="required">Organisatie</label> 
                <Field as="select" name="organizationId">
                  <option value=""></option>
                  {organizations?.map((x, idx) => (
                    <option value={x.id} key={idx}>
                      {x.name}
                    </option>
                  ))}
                </Field>  
                <ErrorMessage className="error-message" name="organizationId" component="div" />
              </div>
            </div>  
            <div className="form_row">
              <div className="field">
                <label htmlFor="month" className="required">
                  Maand
                </label>
                <Field type="number" name="month" />
                <ErrorMessage className="error-message" name="month" component="div" />
              </div>
              <div className="field">
                <label htmlFor="year" className="required">
                  Jaar
                </label>
                <Field type="number" name="year" />
                <ErrorMessage className="error-message" name="year" component="div" />
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => onClose()} type="secondary" />
                <Button onClick={handleSubmit} label="Bevestig" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default CreateInvoiceForm;
