import React from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";

const SupportSuccess = () => {

  return (
    <Page>
      <div className="page__header">
        <h1>SUPPORT</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Verstuurd">
          <p>Uw ticket is succesvol aangemaakt</p>
          <p>Ons support team neemt zo snel mogelijk contact met u op!</p>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default SupportSuccess;
