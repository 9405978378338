import React, { useState, useEffect } from "react";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { delay } from "global/datahelper";
import SearchBar from "components/UI/SearchBar";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import { getAdminVouchers } from "api/services/admin";
import AdminVoucherGrid from "./Components/AdminVoucherGrid";
import { getFilters } from "api/services/admin";
import Confirm from "components/UI/Icons/Confirm";

const AdminVouchers = () => {
  const [search, setSearch] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [organizationFilter, setOrganizationFilter] = useState(null);
  const [organizationFilters, setOrganizationFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [statusFilters, setStatusFilters] = useState(["Nieuw", "Actief", "Ingewisseld", "Vervallen", "Finaal vervallen", "Verwijderd"]);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);

  // Init
  useEffect(async () => {
    refresh();
  }, [search, currentPage, organizationFilter, statusFilter]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);

      // GET endpoint
      var params = {
        'page': currentPage,
        'search': search,
        'fromDate': fromDateFilter,
        'toDate': toDateFilter,
        'status': statusFilter
      }
      if (organizationFilter){
        params["organizationIds"] = parseInt(organizationFilter);
      }
      const vouchersResponse = await getAdminVouchers(params);
      
      // Set data
      let vouchersData = vouchersResponse.data;
      setVouchers(vouchersData);

      // Pagination
      let vouchersHeader = vouchersResponse.headers; 
      
      let pagination = GetPagination(vouchersHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      // Filters
      const filtersData = await getFilters();
      setOrganizationFilters(filtersData["organizations"]);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
      await delay(1000);
      const val = e.target.value.toString();
      setSearch(val);
  };
  
  // Filters
  const onChangeOrganizationFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setOrganizationFilter(val);
  };

  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };
  const onChangeFromDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  return (
    <Page>
      <div className="page__header">
        <h1>Cadeaubonnen</h1>
        <PageHeaderSub>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch} />
        </div>
        <HeaderDropDown titleHeader=" ">
          {!vouchers || loading ? (
            <Loader center />
          ) : (
            <>
            <div className="filters">
                <div className="row">
                  <div>
                    <label>Organisaties</label>
                    <select onChange={onChangeOrganizationFilter} value={organizationFilter}>
                      <option value="">Alle</option>
                      {organizationFilters?.map((filter, idx) => (
                        <option key={idx} value={filter.id}>{filter.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Status</label>
                    <select onChange={onChangeStatusFilter} value={statusFilter}>
                      <option value="">Alle</option>
                      {statusFilters.map((filter, idx) => (
                        <option key={idx}>{filter}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Aanmaakdatum (van)</label>
                    <input type="date" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>Aanmaakdatum (tot)</label>
                    <input type="date" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                  <div onClick={refresh} ><Confirm/></div>
                </div>
              </div>
              <AdminVoucherGrid canEdit vouchers={vouchers} refresh={refresh} />
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default AdminVouchers;
