import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import CampaignInfo from "./Components/CampaignInfo";
import { getCampaign, deleteCampaign, getCampaignsForParticipantVouchers, confirmCampaign, sendInvites, getCampaignForParticipant, updateCampaign, printVouchers } from "api/services/campaign";
import Modal from "components/UI/Modal";
import ParticipantsGrid from "./Components/ParticipantsGrid";
import VouchersGrid from "./Components/VouchersGrid";
import { UseOrganizationContext } from "contexts";
import Status from "components/UI/Status";
import Button from "components/UI/Buttons/Button";
import Delete from "components/UI/Icons/Delete";
import InvitesGrid from "./Components/InvitesGrid";
import Send from "components/UI/Icons/Send";
import Launch from "components/UI/Icons/Launch";
import AddParticipantsGrid from "./Components/AddParticipantsGrid";
import Add from "components/UI/Icons/Add";
import Edit from "components/UI/Icons/Edit";
import Print from "components/UI/Icons/Print";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pay from "components/UI/Icons/Pay";
import { Link } from "react-router-dom";
import OpenExternal from "components/UI/Icons/OpenExternal";

const Container = styled.div`

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
  }
`;

const ViewCampaign = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [vouchers, setVouchers] = useState([]);
  const { updateError } = UseErrorContext();
  const [showModalDelete, setShowModalDelete] = useState(false);   
  const [showModalConfirm, setShowModalConfirm] = useState(false);   
  const [showModalParticipants, setShowModalParticipants] = useState(false);  
  const { isCompany, isTown, name } = UseOrganizationContext();
  const organizationId = UseOrganizationContext()?.id;
  const history = useHistory();

  useEffect(async () => {
    if (!organizationId) return;
    await refresh();
  }, [organizationId]);

  const refresh = async () => {
    try{
      setLoading(true);

      if (isCompany || isTown || name == "AllesBon"){
        const campaignData = await getCampaign(id);
        setCampaign(campaignData); 
        setVouchers(campaignData.vouchers)
        console.log(campaignData)
      }
      else{
        const campaignData = await getCampaignForParticipant(id);
        setCampaign(campaignData); 
        const vouchersData = await getCampaignsForParticipantVouchers(id, organizationId);
        setVouchers(vouchersData); 
      }
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };
  
  const _deleteCampaign = async () => {
    try{
      setLoading(true);
      await deleteCampaign(id);
      setShowModalDelete(false);
      history.push("/campaigns")
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  const _confirm = async () => {   
    try{
      setLoading(true);
      setShowModalConfirm(false);
      await confirmCampaign(campaign.id);
      refresh();
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  const _checkConfirm = async () => {   
    if (campaign.invites.some(x => x.status != "Toegevoegd"))
    {
      setShowModalConfirm(true);
    }
    else{
      await _confirm();
    }
  };

  const _sendInvites = async () => {   
    try{
      setLoading(true);
      await sendInvites(campaign.id);
      refresh();
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  const _updateCampaign = async (participants, invites) => {   
    try{
      setLoading(true);
      const newObject = { id, invites };
      newObject["participantIds"] = participants.map(x => x.id);
      const resp = await updateCampaign(newObject);
      await refresh();
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  const _print = async () => {
    
    try{      
      setLoading(true);
      const result = await printVouchers(id);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});

      // OPEN IN NEW TAB   
      //  const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);

      // DOWNLOAD FILE
      var url = URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'vouchers.pdf');
      tempLink.click();

    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };

  return (
    <>
      {showModalDelete && (
        <Modal title={`Verwijder "${campaign.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _deleteCampaign()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u deze campagne wil verwijderen?</div>
        </Modal>
      )}
      {showModalConfirm && (
        <Modal title={`Lanceer "${campaign.name}"`} onClose={() => setShowModalConfirm(false)} onSubmit={() => _confirm()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>LET OP nog niet alle uitgenodigde zijn succesvol toegevoegd, bent u zeker dat u wil doorgaan?</div>
        </Modal>
      )}
      {showModalParticipants && (
          <Modal title={`Deelnemers toevoegen`} hideActions>
            <AddParticipantsGrid items={campaign.participants} inv={campaign.invites} onClose={() => setShowModalParticipants(false)} onSubmit={(selected, invites) => {_updateCampaign(selected, invites); setShowModalParticipants(false);}}/>
          </Modal>
        )}
      <Page>
        {!campaign || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/campaigns"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/campaigns">Campagnes</a>
                </li>
                <li>
                  <span className="selected">{campaign?.name}</span>
                </li>
              </ul>
            </div>     
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>    
                {((organizationId === campaign.organizationId || name == "AllesBon")) &&
                  <>
                    {campaign.invites.some(x => x.status == "Te versturen") &&
                        <Button label="Verstuur uitnodigingen" onClick={async () => _sendInvites()}><Send/></Button>
                    }
                    {campaign.status == "Nieuw" &&
                      <>
                        <Button label="Verwijderen" onClick={() => {setShowModalDelete(true)}} type="secondary"><Delete/></Button>
                        <Button label="Lanceer nu" onClick={async () => _checkConfirm()}><Launch/></Button>
                      </>
                    }
                    {campaign.status == "Gelanceerd" &&
                      <>
                        <a href={`https://www.allesbon.be/campagne/${campaign.slug}`} target="_blank">
                            <Button label="Deelnemers pagina" type="secondary"><OpenExternal/></Button> 
                        </a>
                        <Link to={`/campaigns/view/${id}/payments`}>
                          <Button label="Betalingen" roles={["Campaigns.Create"]}><Pay/></Button>
                        </Link>
                        <Button label="Bonnen afdrukken" onClick={() => _print()}><Print/></Button>
                      </>
                    }
                  </>
                }        
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Informatie">              
                <CampaignInfo campaign={campaign}/>
              </HeaderDropDown>
              {(organizationId === campaign.organizationId || name == "AllesBon") &&
                <HeaderDropDown titleHeader="Deelnemers">    
                  <ParticipantsGrid items={campaign.participants}/>
                  <div style={{marginTop:'2rem'}}>
                      <InvitesGrid items={campaign.invites}/>
                        <div className="buttons">
                          <>
                            {(campaign.invites.some(x => x.status == "Te versturen")) &&
                              <Button label="Verstuur uitnodigingen" onClick={async () => _sendInvites()}><Send/></Button>
                            }
                            <Button label="Wijzigen" onClick={() => setShowModalParticipants(true)}><Edit/></Button>
                          </>
                        </div>
                  </div>
                </HeaderDropDown>
              }
              <HeaderDropDown titleHeader="Cadeaubonnen">    
                <VouchersGrid items={vouchers} refresh={refresh}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewCampaign;
