import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ValidateAccess } from "global/authorization";
import { UseUserContext } from "contexts";

const B = styled.button`
  display: inline-flex;
  align-items: center;
  //padding: 0.8rem 1.8rem;
  border-radius: .4rem;
  background-color: var(--color-accent-prim);
  color: white;
  border: none;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  border: solid 1px transparent;
  cursor: pointer;
  width: 140px;
  height: 35px;
  justify-content: center;
  &.secondary {
    background-color: var(--color-accent-sec);
  }
  &:hover {
    /*background-color: transparent;
    border: solid 1px var(--color-accent-prim);*/
    opacity: 0.7;
    transition: all 0.25s ease-in;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .button-icon{
    margin-right: .4rem;
    font-size: 1.2rem;
    > * {
      color: white;
    }
  }
`;
const A = styled.a``;

export default function Button({label, url, onClick, type, buttonType, disabled, roles, children}) {
  const [hide, setHide] = useState(true);
  const { permissions } = UseUserContext();

  useEffect(async () => {
    const access = await ValidateAccess(roles, permissions);
    setHide(!access);
  }, [permissions]);

  if (url) {
    return (
      <a href={url}>
        <B onClick={onClick} roles={roles}>{label}</B>
      </a>
    );
  } else {
    return (
      <>
      { !hide &&
        <B className={`${type} ${disabled ? "disabled" : ""}`} onClick={onClick} roles={roles} type={buttonType || "button"} disabled={disabled}>
          <div className="button-icon">{children}</div>
          {label}
        </B>}
      </>
    );
  }
}

Button.defaultProps = {
  label: "button",
  onClick: () => console.log("click"),
  type: "primary",
};