import styled from "styled-components";
import React, { useState } from "react";
import HeaderArrow from "../../../components/UI/Icons/HeaderArrow";
import HeaderBullets from "../../../components/UI/Icons/HeaderBullets";
import SwitchButtonOn from "../Buttons/SwitchButtonOn";

const DropDown = styled.div`
  margin-top: 3.2rem;
  width: 100%;
  .options {
    display: flex;
    justify-content: end;
    padding: 1rem;
    background-color: var(--color-black);
    font-style: italic;
  }
  .option-row {
    display: flex;
    label {
      margin-right: 1rem;
    }
  }
`;
const DropDownHeader = styled.div`  
  background-color: var(--color-accent-purple);
  color: var(--color-text-light);
  display: grid;
  grid-template-columns: auto 50px 30px;
  width: 100%;
  margin: auto;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  cursor: ${props => props.showCollapse ? 'pointer' : 'auto'}};
`;
const DropDownBody = styled.div`
background-color: var(--color-accent-pink);
  width: 100%;
  margin: auto;
  padding: 16px;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: auto;
  box-shadow: 5px 6px 12px -5px black;
`;

export default function DropDownComponent({ titleHeader, children, extraOption, showOptions, showCollapse, collapseBody, options, setOptions, bodyStyle, link }) {
  const [collapse, setCollapse] = useState(collapseBody);
  const [optionsView, setOptionsView] = useState(false);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const toggleSetOptionsView = async (e) => {
    // This part stops the click from propagating to parent onClick event
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();

    setOptionsView(!optionsView);
  };

  const updateOptions = async (key, value) => {
    options[key] = value;
    setOptions(options);
  };

  return (
    <DropDown>
      <DropDownHeader onClick={() => showCollapse && toggleCollapse()} showCollapse={showCollapse}>
        {link 
        ? <h2><a href={link}>{titleHeader}</a></h2>
        : <h2>{titleHeader}</h2>
        }
        {extraOption}
        <div onClick={(e) => toggleSetOptionsView(e)}> {showOptions && options && <HeaderBullets />} </div>
        {showCollapse && <HeaderArrow transform={collapse} />}
      </DropDownHeader>
      {!collapse && optionsView && options && (
        <div className="options">
          {Object.keys(options).map((option, idx) => (
            <div className="option-row" key={idx}>
              <label>{option}</label>
              <SwitchButtonOn on={options[option]} onSwitchOn={() => updateOptions(option, true)} onSwitchOff={() => updateOptions(option, false)} />
            </div>
          ))}
        </div>
      )}
      {!collapse && <DropDownBody style={bodyStyle}>{children}</DropDownBody>}
    </DropDown>
  );
}

DropDownComponent.defaultProps = {
  titleHeader: "Header title",
  children: <div>Content</div>,
  showOptions: false,
};
