import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import ParticipantsGrid from "./ParticipantsGrid";
import InvitesGrid from "./InvitesGrid";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const Container = styled.div`  
  .table{
    margin: 4rem 0;
    > .field h2{
      margin: 1rem 0;
    }
  }
  .image {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
`;

const ReviewSubmit = ({ campaign, participants, invites, backStep, save }) => {

  return (
    <Container>       
        <div className="form">
          <div className="form_row">
            <div className="field">
              <label>Naam</label>
              <input disabled value={campaign.name}/>
            </div>
          </div> 
          <div className="form_row">
            <div className="field">
              <label>Bedrag</label>
              <input value={campaign.price} disabled/>
            </div>
          </div> 
          <div className="form_row">
            <div className="field">
              <label>Bedrag verbergen</label>
              <SwitchButtonOn
                on={campaign.hidePrice}
                disabled
              />
            </div>
          </div> 
          <div className="form_row">
            <div className="field">
              <label>Aantal</label>
              <input disabled value={campaign.quantity}/>
            </div>
          </div>  
          <div className="form_row">
            <div className="field">
              <label>Bericht</label>
              <input disabled value={campaign.message}/>
            </div>
          </div>  
          { participants?.length > 0 && 
            <ParticipantsGrid items={participants}/>
          }
          { invites?.length > 0 && 
            <InvitesGrid items={invites}/>
          }
          <div className="buttons">
            <Button label="Terug" onClick={() => backStep()} type="secondary" />
            <Button label="Opslaan" onClick={() => save()} type="primary" />
            {/* <PublishButton publish={publish}/> */}
          </div>
        </div> 
      </Container>
  );
};

export default ReviewSubmit;
