import React, { useEffect, useState } from "react";
import GlobalStyle from "./components/styles/GlobalStyle";
import Grid from "./components/styles/Grid";
import Layout from "./components/Layout.js";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { LoginRoutes, AppRoutes } from "./routes";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { OrganizationContext, UserContext, ErrorContext } from "./contexts";
import { getOrganization } from "./api/services/organization";
import Error from "components/UI/Error";

window.msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [userName, setUserName] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const updateOrganizationData = async (id) => {
    if(id){
      const orgData = await getOrganization(id);
      setData({ ...orgData });
      setLoading(false);
    }
  };

  const updateUserData = async ( permissionData ) => {
    setPermissions(permissionData);    
  };

  const updateUserName = async ( userName ) => {
    setUserName(userName);    
  };

  const updateError = async ( error ) => {
    setErrorMessage(error);    
  };

  return (
    <MsalProvider instance={window.msalInstance}>
      <Router>
        <GlobalStyle />
        <Grid />
        <AuthenticatedTemplate>
          <OrganizationContext.Provider value={{ ...data, updateOrganizationData, loading }}>
            <UserContext.Provider value={{ permissions, updateUserData, userName, updateUserName }}>
              <ErrorContext.Provider value={{ errorMessage, updateError }}>
                <Error/>
                <Layout>
                  <AppRoutes />
                </Layout>
              </ErrorContext.Provider>
            </UserContext.Provider>
          </OrganizationContext.Provider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginRoutes />
        </UnauthenticatedTemplate>
      </Router>
    </MsalProvider>
  );
}
