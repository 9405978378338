import React, { useState } from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { ContactForm } from "pages/contacts/Components/ContactForm";
import { useHistory } from "react-router-dom";
import { UseErrorContext } from "contexts";
import Loader from "components/UI/Loader";
import { addContact } from "api/services/contact";
import { UseOrganizationContext } from "contexts";
import { ValidateEmail } from "global/datahelper";

const AddContact = () => {
  const history = useHistory();
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const organizationId = UseOrganizationContext()?.id;

  const onSave = async (contactData) => {
    try{
      setIsLoading(true);
      const rep = await addContact(organizationId, contactData); 
      setIsLoading(false);         
      history.push("/contacts/view/" + rep.id);
      return rep;               
    } catch (err) {
      updateError(err);
    }    
    finally{
      setIsLoading(false);   
    }     
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <PageHeaderSub>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Nieuw contact">
          {isLoading 
            ? <Loader />
            : <ContactForm save={onSave}/>
          }
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default AddContact;
