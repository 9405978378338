import { getAxiosClient } from "../axios";
import { getActiveProducts, getProducts } from "./product";

export const getVouchers = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/vouchers`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addVoucher = async (voucher) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .post(`/vouchers`, voucher)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getVoucher = async (organizationId, id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${organizationId}/vouchers/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const updateVoucher = async (voucher) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/vouchers/${voucher.id}`, voucher)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const deleteVoucher = async (voucherID) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .delete(`/vouchers/${voucherID}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const redeemVoucher = async (organizationId, id, data) => {
    const axiosClient = await getAxiosClient();
  console.log("ID", id)
    return await axiosClient
      .put(`/organizations/${organizationId}/vouchers/${id}/redeem`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getVoucherHistory = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/vouchers/${id}/history`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getFilters = async (organizationId) => {
    const products = await getProducts(organizationId);
    const status = ["Actief", "Ingewisseld", "Vervallen", "Finaal vervallen"];
    return {"products": products.data, "status": status};
  };
  
  export const mailVoucher = async (id, data) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/vouchers/${id}/mail`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getVouchersReport = async (id, params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/vouchers-report`, { params: {...params}} )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const printVoucher = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/vouchers/${id}/print`, {
         responseType: 'blob', 
         headers: {
          Accept: 'application/pdf',
        }, 
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const scanVoucher = async (id, scan) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/organizations/${id}/vouchers/scan/${scan}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const undoRedeemVoucher = async (organizationId, id, data) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/organizations/${organizationId}/voucherhistoryitems/${id}/undo`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const updateContactVoucher = async (id, contactId) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/vouchers/${id}/contact/${contactId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };