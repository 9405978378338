import React, { useState, useEffect } from "react";
import { Route, Redirect } from 'react-router-dom';
import { ValidateAccess } from 'global/authorization';
import { UseUserContext } from "contexts";

 const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const [hasAccess, setHasAccess] = useState([]);
    const { permissions } = UseUserContext();

    useEffect(async () => {
      const access = await ValidateAccess(roles);
      setHasAccess(access, permissions);
    }, [permissions]);
    
    return (
        <>
        <Route {...rest} render={props => {                
            // Check if route is allowed by role   
            if (hasAccess) {  
                // Authorised so return component
                return <Component {...props} />         
            }
            else{                
                // Role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/unauthorized'}} />
            }
        }} />
        </>
    );
 };

 export default PrivateRoute;