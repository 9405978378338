import React from "react";
import styled, {css} from "styled-components";
import Loader from "../../../components/UI/Loader";
import { useHistory } from "react-router-dom";
import { FormatDateTimeToString } from "global/datahelper";
import { UseOrganizationContext } from "contexts";
import Status from "components/UI/Status";

const Container = styled.div`
`;

const CampaignPaymentGrid = ({ items }) => {
  const history = useHistory();

  return (
    <Container> 
      <table cellSpacing="500">
        <thead>
          <tr>    
            <th></th>
            <th>Aangemaakt op</th>
            <th>Deelnemer</th>
            <th>Rekeningnummer</th>
            <th>Bedrag</th>
            <th>Referentie</th>
          </tr>
        </thead>
        <tbody>
        {!items 
          ? <Loader center />
            : <> 
              {items.map((item, idx) => (          
                  <tr key={item.id + '_' + idx}>      
                    <td></td>
                    <td>{FormatDateTimeToString(item.createDate)}</td>    
                    <td>{item.participantName}</td>   
                    <td>{item.accountNumber}</td>  
                    <td>€ {item.quantity}</td>    
                    <td>{item.reference}</td>    
                  </tr>      
              ))}
              </>
        }
        </tbody>
      </table>
   </Container>
  );
};

CampaignPaymentGrid.defaultProps = {
  items: Array.from(Array(3)),
  direction: 'row'
};

export default CampaignPaymentGrid;
