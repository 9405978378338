import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString } from "global/datahelper";
import Status from "components/UI/Status";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }
`;

const OrganizationGrid = ({ organizations }) => {
  const history = useHistory();

  const open = (organizationId) => {   
    history.push("/admin/organizations/" + organizationId);
  };

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Naam</th>
            <th>Aangemaakt op</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((organization, idx) => (            
              <tr key={organization.id + '_' + idx} onClick={() => open(organization.id)} className="clickable">   
                <td></td>           
                <td>{organization.name}</td>
                <td>{FormatDateTimeToString(organization.createDate)}</td>    
                <td><Status className={organization.status.replace(/\s/g, '')} label={organization.status}/></td>               
              </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

OrganizationGrid.defaultProps = {
  organizations: Array.from(Array(0)),
};

export default OrganizationGrid;
