import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { BaDoughnutr, Doughnut } from 'react-chartjs-2';
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getDayReport, getProductReport } from "api/services/report";
import { FormatDateTime, Round, generateRandomRGBAColors } from "global/datahelper";
import Loader from "components/UI/Loader";

ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  table{
    margin: 0 0 2rem 0;
  }
`;

const ProductReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [chartData, setChartData] = useState({datasets: [],});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);
        const reportData = await getProductReport(organizationId);
        const data = reportData.products;
        setData(data); 
      
        setChartData({
          labels: data.map(x => x.product),
          datasets: [
            {
              label: '',
              data: data.map(x => x.quantity),
              backgroundColor: generateRandomRGBAColors(6, .2),
              borderWidth: 1,
              }
            ]
          }); 
          setIsLoading(false); 
      } catch (err) {
        updateError(err);
      }  
    }      
  }, [organizationId])

  return (
    <>
      {isLoading 
        ? <Loader />
        : <Container> 
        <table cellSpacing="500">
          <thead>
            <tr>  
              <th></th>   
              <th></th>    
              <th></th>    
              <th>Aantal</th>    
            </tr>
          </thead>
          <tbody>
            {data.map((p, idx) => (            
                  <tr key={idx}>   
                    <td></td>           
                    <td>{idx + 1}</td>
                    <td>{p.product}</td>  
                    <td>{p.quantity}</td>   
                  </tr>          
              ))}
            </tbody>
        </table>
        {/* <Doughnut data={chartData} /> */}
      </Container>
      }
    </>    
  );
};

export default ProductReport;
