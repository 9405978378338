import React from "react";
import styled, {css} from "styled-components";
import Loader from "../../../components/UI/Loader";
import { useHistory } from "react-router-dom";
import { FormatDateTimeToString } from "global/datahelper";
import { UseOrganizationContext } from "contexts";
import Status from "components/UI/Status";

const Container = styled.div`
`;


const CampaignGrid = ({ items }) => {
  const history = useHistory();
  const { isCompany, isTown } = UseOrganizationContext();

  const open = (id) => {   
    history.push("/campaigns/view/" + id);
  };

  return (
    <Container> 
      <table cellSpacing="500">
        <thead>
          <tr>    
            <th></th>
            {(!isCompany && !isTown) && <th>Eigenaar</th>}
            {(!isCompany && !isTown) && <th>Type</th>}
            <th>Naam</th>
            <th>Aangemaakt op</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
        {!items 
          ? <Loader center />
            : <> 
              {items.map((item, idx) => (          
                  <tr key={item.id + '_' + idx} onClick={() => open(item.id)} className="clickable">  
                   <td></td>           
                    {(!isCompany && !isTown) && <td>{item.organizationName}</td>}               
                    {(!isCompany && !isTown) && <td>{item.organizationType}</td>}       
                    <td>{item.name}</td>   
                    <td>{FormatDateTimeToString(item.createDate)}</td>   
                    <td><Status className={item.status} label={item.status}/></td>    
                  </tr>      
              ))}
              </>
        }
        </tbody>
      </table>
   </Container>
  );
};

CampaignGrid.defaultProps = {
  items: Array.from(Array(3)),
  direction: 'row'
};

export default CampaignGrid;
