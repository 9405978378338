import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString } from "global/datahelper";
import Status from "components/UI/Status";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }
`;

const OrganizationProductGrid = ({ products }) => {
  const history = useHistory();

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Weergave naam</th>
            <th>Bedrag</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, idx) => (            
              <tr key={product.id + '_' + idx}>   
                <td></td>           
                <td>{product.displayName}</td>
                <td>{product.price}</td>   
                <td>{product.status}</td>             
              </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

OrganizationProductGrid.defaultProps = {
  products: Array.from(Array(0)),
};

export default OrganizationProductGrid;
