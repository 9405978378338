import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
`;

const InviteParticipantsBox = ({ id, name, email, status, callback }) => {
  const [nameValue, setNameValue] = useState(name);
  const [emailValue, setEmailValue] = useState(email);

    // callback
    useEffect(() => {
      callback({
        "id": id,
        "name": nameValue,
        "email": emailValue,
        "status": status,
      });
    }, [nameValue, emailValue]);

  return (
    <>
              <div className="field">
                <label className="required">Naam</label>
                <input value={nameValue} onChange={(e) => setNameValue(e.target.value)}/>
              </div>
              <div className="field">
                <label className="required">E-mail</label>
                <input value={emailValue} onChange={(e) => setEmailValue(e.target.value)}/>
              </div>
    </>
  );
};

export default InviteParticipantsBox;
