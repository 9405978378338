import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getInvoice, printInvoice } from "api/services/invoice";
import PageHeaderSub from "components/PageHeaderSub";
import InvoiceInfo from "./Components/InvoiceInfo";
import Status from "components/UI/Status";
import Button from "components/UI/Buttons/Button";
import Print from "components/UI/Icons/Print";
import InvoiceLineGrid from "pages/admin/invoices/Components/InvoiceLineGrid";
import BalanceLineGrid from "pages/admin/invoices/Components/BalanceLineGrid";

const Container = styled.div``;

const ViewInvoice = () => {
  const { id } = useParams();
  const history = useHistory();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const invoiceData = await getInvoice(id);
      setInvoice(invoiceData);    
    } catch (err) {
      updateError(err);
    }
  };

  const _printInvoice = async () => {
    try{      
      setLoading(true);
      const result = await printInvoice(id);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };

  const open = (detailId) => {   
    history.push("/invoices/view/" + id + "/" + detailId);
  };

  return (
    <> 
      <Page>
        {!invoice || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/invoices"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/invoices">Facturen</a>
                </li>
                <li>
                  <span className="selected">{invoice?.reference}</span>
                </li>
              </ul>
            </div>   
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>
                <Button label="Afdrukken" onClick={async () => _printInvoice()} type="secondary"><Print/></Button> 
                 <Status className={invoice.status} label={invoice.status}/>
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Factuurgegevens">  
              {!invoice || loading ? (
                <Loader center />
              ) : ( 
                <>           
                  <InvoiceInfo invoice={invoice}/>
                  <InvoiceLineGrid invoiceId={invoice.id} items={invoice.invoiceLines}/>
                  <BalanceLineGrid items={invoice.balanceLines} open={open}/>
                </>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewInvoice;
