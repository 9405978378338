import React from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { EnveloppesForm } from "./Components/EnveloppesForm";
import { useHistory } from "react-router-dom";
import Button from "components/UI/Buttons/Button";

const Enveloppes = () => {

  return (
    <Page>
      <div className="page__header">
        <h1>Enveloppes</h1>
        <PageHeaderSub/>    
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Plaats bestelling">
          <p><b>Prijs:</b> €39,95 / pakket van 30 stuks</p>
          <p><i>Dit wordt toegevoegd aan uw maandelijkse factuur.</i></p>
          <br></br>
          <EnveloppesForm/>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Enveloppes;
