import React from "react";

export default function Back() {

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      x="0px" 
      y="0px" 
      width="24" 
      height="24" 
      viewBox="0 0 92 92" 
      xmlSpace="preserve">
    <path id="XMLID_986_" d="M71.7,43.7C64.4,36.4,54,32.4,42,31.7V21c0-1.6-1.2-3.1-2.7-3.7c-1.5-0.6-3.3-0.3-4.5,0.9l-24.6,25
    c-1.5,1.6-1.6,4.1,0,5.6l24.6,25c1.1,1.2,3,1.5,4.5,0.9c1.5-0.6,2.7-2.1,2.7-3.7V60.2C50,60,68.8,60.8,75.7,73c0.7,1.3,2,2,3.4,2
    c0.3,0,0.6,0,1-0.1c1.8-0.5,3.1-2,3.1-3.9C83.1,70.4,83.1,55.1,71.7,43.7z M37.4,52.4c-2,0.2-3.4,1.9-3.4,4v4.8L19,46l15-15.2v4.8
    c0,2.2,1.6,4,3.8,4c21.5,0,30.6,10.3,34.5,19.1C58.2,50.3,38.4,52.3,37.4,52.4z"
    fill="#262626" />
  </svg>
  );
}
