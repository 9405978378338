import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { UseOrganizationContext } from "contexts";
import Logo from "./UI/Logo";
import PrivateNavLink from "./PrivateNavLink";
import MenuItem from "./MenuItem";
import { FaIdCard, FaHome, FaGripHorizontal, FaCreditCard, FaCalendar, FaMale, FaAddressBook} from "react-icons/fa";
import Status from "./UI/Status";
import defaultLogo from "../assets/logo.png"

// All icons
// https://react-icons.github.io/react-icons/icons?name=fa

const Container = styled.div`
  .side_menu__primary {
    // overflow-y: auto;
  }

  .sub_menu {
    padding-left: 3.5rem;
  }

  .reviewed_message {
    display: flex;
    justify-content: center;
  }

`;

export default function Navigation2() {
  const { status } = UseOrganizationContext();

  return (
    <Container>
        <div className="side_menu__primary">
          { status == "In behandeling" &&
            <div className="reviewed_message">
              <Status className="Vervallen" label="In behandeling"/>  
            </div>
          }
          <ul>
            <li>
              <NavLink activeClassName="active" to="/dashboard">
                <FaHome />
                dashboard
              </NavLink>
            </li>
            <li>
              <PrivateNavLink to="/campaigns" roles={["Campaigns.Read"]} icon={FaCalendar} label="Campagnes" />
            </li>  
            <li>
              <PrivateNavLink to="/contacts" roles={["Contacts.Read"]} icon={FaAddressBook} label="Contacts" />
            </li>  
            <li> 
              <PrivateNavLink to="/profile" roles={["Organization.Read"]} icon={FaIdCard} label="Profiel" />
            </li>       
          </ul>
        </div>
    </Container>
  );
}
