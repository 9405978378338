import React from "react";
import { FaCheck, FaRocket } from "react-icons/fa";

export default function Launch(props) {

  return (
    <>
      <div className="icon-button">
        <FaRocket/>
      </div>
    </>
  );
}
