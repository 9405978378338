import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function OpenExternal(props) {

  return (
    <>
      <div className="icon-button">
        <FaExternalLinkAlt/>
      </div>
    </>
  );
}
