import React, { useState, useEffect } from "react";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { delay } from "global/datahelper";
import SearchBar from "components/UI/SearchBar";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import { getInvoices } from "api/services/invoice";
import { useHistory } from "react-router-dom";
import InvoiceGrid from "pages/admin/invoices/Components/InvoiceGrid";
import { UseOrganizationContext } from "contexts";
import Confirm from "components/UI/Icons/Confirm";

const Invoices = () => {
  const [search, setSearch] = useState("");
  const [invoices, setInvoices] = useState([]);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;  

  // Init
  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search, currentPage]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);

      // GET endpoint
      var params = {
        'page': currentPage,
        'search': search,
        'fromDate': fromDateFilter,
        'toDate': toDateFilter
      }
      const invoicesResponse = await getInvoices(organizationId, params);
      
      // Set data
      let invoicesData = invoicesResponse.data;
      setInvoices(invoicesData);
      console.log(invoicesData)

      // Pagination
      let invoicesHeader = invoicesResponse.headers; 
      
      let pagination = GetPagination(invoicesHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
      await delay(1000);
      const val = e.target.value.toString();
      setSearch(val);
  };
  
  // Filters
  const onChangeFromDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  const open = (invoiceId) => {   
    history.push("/invoices/view/" + invoiceId);
  };

  return (
    <Page>
      <div className="page__header">
        <h1>Facturen</h1>
        <PageHeaderSub>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="description">
          <p>Hier kan u uw maandelijkse facturen vinden. Deze worden een keer per maand gegenereerd en ook toegestuurd per mail.</p>
        </div>
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch} />
        </div>
        <HeaderDropDown titleHeader=" ">
          {!invoices || loading ? (
            <Loader center />
          ) : (
            <>
              <div className="filters">
                <div className="row">
                  <div>
                    <label>Aanmaakdatum (van)</label>
                    <input type="date" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>Aanmaakdatum (tot)</label>
                    <input type="date" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                  <div onClick={refresh} ><Confirm/></div>
                </div>
              </div>
              <InvoiceGrid canEdit invoices={invoices} refresh={refresh} open={open}/>
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Invoices;
