import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getProfitReport } from "api/services/report";
import Loader from "components/UI/Loader";

const Container = styled.div`
  table{
    margin-top:2rem;
  }
  .table-total{
    font-weight: bold;
    font-style: italic;
  }
`;

const ProfitReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [profitData, setProfitData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);
        
        const data = await getProfitReport(organizationId);
        console.log(data)
        setProfitData(data.profits);

        setIsLoading(false);
      } catch (err) {
        updateError(err);
      }   
    }    
  }, [organizationId])

  return (
    <Container>
      {isLoading
        ? <Loader />
        : 
        <>
          <table cellSpacing="500">
            <thead>
              <tr>            
                <th>Jaar</th>
                <th>Verkocht</th>
                <th>Open</th>
                <th>Ingewisseld</th>
                <th>Vervallen</th>
              </tr>
            </thead>
            <tbody> 
              {profitData.map((data, idx) => (           
                  <tr key={idx}>      
                    <td>{data.year}</td>  
                    <td>€ {data.sold}</td>
                    <td>€ {data.open}</td>   
                    <td>€ {data.redeemed}</td>  
                    <td>€ {data.expired}</td>      
                  </tr>          
              ))}
              <tr className="table-total">   
                    <td>TOTAAL:</td>      
                    <td>€ {profitData.reduce((sum, d) => sum + d.sold, 0).toFixed(2).toString().replace(/\.00$/, '')}</td>
                    <td>€ {profitData.reduce((sum, d) => sum + d.open, 0).toFixed(2).toString().replace(/\.00$/, '')}</td>   
                    <td>€ {profitData.reduce((sum, d) => sum + d.redeemed, 0).toFixed(2).toString().replace(/\.00$/, '')}</td>  
                    <td>€ {profitData.reduce((sum, d) => sum + d.expired, 0).toFixed(2).toString().replace(/\.00$/, '')}</td>      
              </tr>  
            </tbody>
          </table>
        </>
      }
    </Container>    
  );
};

export default ProfitReport;
