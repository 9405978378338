import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TooltipIcon from "./Icons/Tooltip";

const Container = styled.div`
  display: inline;

  .tooltip-button {
    display: inline-flex;
    & > * {
    font-size: 1rem;
    }
  }
  .tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    width: 70%;
    z-index: 999999;
    font-size: 1.4rem;
    font-weight: 500;
  }
  .tooltip-arrow {
    position: absolute;
    bottom: 100%;
    //left: 50%;
    border: 5px solid transparent;
    border-top: 0;
    border-bottom: 10px solid rgba(0,0,0,0.7)
  }
`;

export const Tooltip = ({ text }) => {
  const [show, setShow] = useState(false);

  return (
    <Container>
      <div className="tooltip-button" 
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}>
        <TooltipIcon/>
      </div>
      <div className="tooltip" style={!show ? { display: "none"} : {}}>
        <span className="tooltip-arrow" />
        {text}
      </div>
    </Container>
  );
};