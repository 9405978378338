import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import ParticipantsGrid from "./ParticipantsGrid";
import Add from "components/UI/Icons/Add";
import Modal from "components/UI/Modal";
import AddParticipantsGrid from "./AddParticipantsGrid";
import InvitesGrid from "./InvitesGrid";

const Container = styled.div`
`;

export const ParticipantsForm = ({ participantsData, invitesData, nextStep, backStep }) => {
  const [participants, setParticipants] = useState(participantsData ?? []);
  const [invites, setInvites] = useState(invitesData ?? []);
  const [showModalParticipants, setShowModalParticipants] = useState(false);  
  const [showModalWarning, setShowModalWarning] = useState(false); 

  const _back = async () => {
    if(participants != participantsData || invites != invitesData)
      setShowModalWarning(true);
    else
      backStep();
  };

  return (
    <Container className="form">
          {showModalWarning && (
              <Modal title={`Wijzigingen gaan verloren`} onSubmit={backStep} onClose={() =>setShowModalWarning(false)} submitLabel="Ga toch terug">
                <p>U heeft wijzigingen aangebracht en als u nu teruggaat gaan deze verloren. Als u deze wenst op te slaan druk dan op 'Annuleer' en vervolgens op 'Verder'.</p>
              </Modal>
          )}
          {showModalParticipants && (
              <Modal title={`Deelnemers toevoegen`} hideActions>
                <AddParticipantsGrid items={participants} inv={invites} onClose={() => setShowModalParticipants(false)} onSubmit={(selected, invites) => {setParticipants(selected); setInvites(invites); setShowModalParticipants(false);}}/>
              </Modal>
            )}
            <div className="form_row">
              <ParticipantsGrid items={participants}/>
            </div>
            {invites.length > 0 &&
              <div className="form_row">
                <InvitesGrid items={invites}/>
              </div>
            }
            <div className="form_row">
              <Button label="Toevoegen" onClick={() => setShowModalParticipants(true)}><Add/></Button>
            </div>
        <div className="buttons">
          <Button label="Terug" onClick={_back} type="secondary" />
          <Button label="Volgende" onClick={() => nextStep(participants, invites)} type="primary" />
        </div>
    </Container>
  );
};
