import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getProfitReport } from "api/services/admin";
import Loader from "components/UI/Loader";

const Container = styled.div`
  table{
    margin-top:2rem;
  }
`;

const ProfitReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [profitData, setProfitData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);

        const today = new Date();
        const year = today.getFullYear();
        
        const data = await getProfitReport({'year': year});
        setProfitData(data);

        setIsLoading(false);
      } catch (err) {
        updateError(err);
      }   
    }    
  }, [organizationId])

  return (
    <Container>
      {isLoading
        ? <Loader />
        : 
        <>
        {/* <p>Jaar: {year}</p> */}
          <table cellSpacing="500">
            <thead>
              <tr>            
                <th></th>
                <th>Periode</th>
                <th>Commissie</th>
                <th>Hernieuwingen</th>
                <th>Transacties</th>
                <th>Manuele</th>
                <th>Totaal</th>
              </tr>
            </thead>
            <tbody> 
              {profitData.map((data, idx) => (           
                  <tr key={idx}>   
                    <td></td>         
                    <td>{data.period}</td>  
                    <td>€ {data.commission}</td>
                    <td>€ {data.renewExpired}</td>   
                    <td>€ {data.transaction}</td>  
                    <td>€ {data.manual}</td>  
                    <td>€ {data.total}</td>      
                  </tr>          
              ))}
            </tbody>
          </table>
        </>
      }
    </Container>    
  );
};

export default ProfitReport;
