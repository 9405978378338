import React from "react";
import styled from "styled-components";
import Loader from "components/UI/Loader";

const Container = styled.div`
  margin-top:4rem;
  table{
    margin-top:2rem;
  }
`;

const BalanceLineGrid = ({ items, open }) => {

  return (
    <Container> 
     <label>Balanslijnen</label>
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Omschrijving</th>
            <th>Aantal</th>
            <th>Totaal</th>
          </tr>
        </thead>
        <tbody>
        {!items 
          ? <Loader center />
           : <> 
              {items.map((item, idx) => (          
                  <tr key={item.id + '_' + idx} onClick={() => open(item.id)} className="clickable">               
                    <td>{item.reference}</td>            
                    <td>{item.description}</td>        
                    <td>{item.quantity}</td>    
                    <td>{"€" + item.total}</td>                          
                  </tr>      
              ))}
             </>
        }
        </tbody>
      </table>
    </Container>
  );
};

export default BalanceLineGrid;
