import React, { useState, useEffect } from "react";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { delay } from "global/datahelper";
import SearchBar from "components/UI/SearchBar";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import { generateZipMonthlyInvoices, getInvoices, printInvoice } from "api/services/admin";
import InvoiceGrid from "./Components/InvoiceGrid";
import { getFilters } from "api/services/admin";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import CreateInvoiceForm from "./Components/CreateInvoiceForm";
import Add from "components/UI/Icons/Add";
import Print from "components/UI/Icons/Print";
import { useHistory } from "react-router-dom";
import Zip from "components/UI/Icons/Zip";
import Confirm from "components/UI/Icons/Confirm";

const Invoices = () => {
  const [search, setSearch] = useState("");
  const [invoices, setInvoices] = useState([]);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [organizationFilter, setOrganizationFilter] = useState(null);
  const [organizationFilters, setOrganizationFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [statusFilters, setStatusFilters] = useState(["Nieuw", "Bevestigd", "Verzonden", "Betaald"]);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const history = useHistory();

  // Init
  useEffect(async () => {
    refresh();
  }, [ search, currentPage, organizationFilter, statusFilter]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);

      // GET endpoint
      var params = {
        'page': currentPage,
        'search': search,
        'fromDate': fromDateFilter,
        'toDate': toDateFilter,
        'status': statusFilter
      }
      if (organizationFilter){
        params["organizationIds"] = parseInt(organizationFilter);
      }
      const invoicesResponse = await getInvoices(params);
      
      // Set data
      let invoicesData = invoicesResponse.data;
      setInvoices(invoicesData);
      console.log(invoicesData)

      // Pagination
      let invoicesHeader = invoicesResponse.headers; 
      
      let pagination = GetPagination(invoicesHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      // Filters
      const filtersData = await getFilters();
      setOrganizationFilters(filtersData["organizations"]);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
      await delay(1000);
      const val = e.target.value.toString();
      setSearch(val);
  };
  
  // Filters
  const onChangeOrganizationFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setOrganizationFilter(val);
  };

  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };

  const onChangeFromDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  const _print = async () => {
    try{      
      setLoading(true);

      for (let i=0; i < invoices.length; i++) {
        const invoice = invoices[i]
        const result = await printInvoice(invoice.id);
        const file = new Blob(
          [result.data], 
          {type: 'application/pdf'});

        // OPEN IN NEW TAB   
        //  const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        // DOWNLOAD FILE
        var url = URL.createObjectURL(file);
        var tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.target = "_blank";
        var name = invoice.reference ?? invoice.id + ".pdf"
        tempLink.setAttribute('download', name);
        tempLink.click();
      }
    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  }

  const _zip = async () => {
    try{      
      setLoading(true);
      const today = new Date();
      const month = today.getMonth() + 1; // have to do +1
      const year = today.getFullYear();

      let m = month != 1 ? month - 1 : 12;
      var y = month != 1 ? year : year - 1;
      await generateZipMonthlyInvoices({'year': y, 'month': m});
    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  }


  const open = (invoiceId) => {   
    history.push("/admin/invoices/" + invoiceId);
  };

  return (
    <Page>
      {showModalCreate && (
        <Modal title="Factuur aanmaken" hideActions>
          <CreateInvoiceForm onClose={async () => setShowModalCreate(false)}/>
        </Modal>
      )}  
      <div className="page__header">
        <h1>Facturen</h1>
        <PageHeaderSub show>
          <Button label="ZIP" onClick={async () => _zip()} type="secondary"><Zip/></Button>
          <Button label="Afdrukken" onClick={async () => _print()} type="secondary"><Print/></Button>
          <Button label="Nieuwe factuur" onClick={async () => setShowModalCreate(true)}><Add/></Button>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch} />
        </div>
        <HeaderDropDown titleHeader=" ">
          {!invoices || loading ? (
            <Loader center />
          ) : (
            <>
              <div className="filters">
                <div className="row">
                  <div>
                    <label>Organisaties</label>
                    <select onChange={onChangeOrganizationFilter} value={organizationFilter}>
                      <option value="">Alle</option>
                      {organizationFilters?.map((filter, idx) => (
                        <option key={idx} value={filter.id}>{filter.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Status</label>
                    <select onChange={onChangeStatusFilter} value={statusFilter}>
                      <option value="">Alle</option>
                      {statusFilters.map((filter, idx) => (
                        <option key={idx}>{filter}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Aanmaakdatum (van)</label>
                    <input type="date" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>Aanmaakdatum (tot)</label>
                    <input type="date" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                  <div onClick={refresh} ><Confirm/></div>
                </div>
              </div>
              <InvoiceGrid canEdit invoices={invoices} refresh={refresh} open={open}/>
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Invoices;
