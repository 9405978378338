import React from "react";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";

const Unauthorized = () => {

  return (
    <Page>
      <div className="page__header">
        <h1>UNAUTHORIZED</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Ongeldige toegang">
          <p>U ben niet geautoriseerd om deze pagina te bezoeken.</p>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Unauthorized;
