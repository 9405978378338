import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { UseOrganizationContext } from "contexts";
import Logo from "./UI/Logo";
import PrivateNavLink from "./PrivateNavLink";
import MenuItem from "./MenuItem";
import { FaIdCard, FaHome, FaGripHorizontal, FaCreditCard, FaBuilding, FaFileInvoiceDollar, FaShoppingCart, FaRocket} from "react-icons/fa";
import Status from "./UI/Status";
import defaultLogo from "../assets/logo.png"

// All icons
// https://react-icons.github.io/react-icons/icons?name=fa

const Container = styled.div`
  .side_menu__primary {
    // overflow-y: auto;
  }

  & .sub_menu {
    padding-left: 3.5rem;
  }
`;

export default function NavigationAdmin() {

  return (
    <Container>
        <div className="side_menu__primary">
          <ul>
            <li>
              <NavLink activeClassName="active" to="/dashboard">
                <FaHome />
                dashboard
              </NavLink>
            </li>
            <li>
              <PrivateNavLink to="/admin/organizations" roles={["Admin"]} icon={FaBuilding} label="Organisaties" />
            </li>
            <li>
              <PrivateNavLink to="/admin/vouchers" roles={["Admin"]} icon={FaCreditCard} label="Cadeaubonnen" />
            </li>
            <li>
              <PrivateNavLink to="/campaigns" roles={["Admin"]} icon={FaRocket} label="Campagnes" />
            </li>  
            <li>
              <PrivateNavLink to="/admin/invoices" roles={["Admin"]} icon={FaFileInvoiceDollar} label="Facturen" />
            </li>
            <li>
              <PrivateNavLink to="/admin/orders" roles={["Admin"]} icon={FaShoppingCart} label="Orders" />
            </li>
            <li>
              <PrivateNavLink to="/profile" roles={["Admin"]} icon={FaIdCard} label="Profiel" />
            </li>          
          </ul>
        </div>
    </Container>
  );
}
