import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateToString , FormatDateTimeToString} from "global/datahelper";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const VoucherInfo = ({ voucher }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Product</label>
            <input value={voucher.product.displayName} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Barcode</label>
            <input value={voucher.barcode} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Type</label>
            <input value={voucher.voucherType} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Verkooppunt</label>
            <input value={voucher.salesPoint} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Aangemaakt op</label>
            <input value={FormatDateTimeToString(voucher.createDate)} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Vervaldatum</label>
            <input value={FormatDateToString(voucher.expiryDate)} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Resterend bedrag (€)</label>
            <input value={voucher.remainingPrice} disabled/>
          </div>
          <div className="field">
            <label>Totaal bedrag (€)</label>
            <input value={voucher.price} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Van</label>
            <input value={voucher.from} disabled/>
          </div>
          <div className="field">
            <label>Aan</label>
            <input value={voucher.to} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Persoonlijk bericht</label>
            <input value={voucher.message} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default VoucherInfo;
