import React from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";

const EnveloppesSuccess = () => {

  return (
    <Page>
      <div className="page__header">
        <h1>Enveloppes</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Bestelling geplaatst">
          <p>U krijgt een e-mail met verdere details en levertermijn van ons!</p>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default EnveloppesSuccess;
