import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top:4rem;
  table{
    margin-top:2rem;
  }
`;

const InvoiceLineDetailGrid = ({ items, open }) => {

  return (
    <Container> 
     <label>Details</label>
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Cadeaubon</th>
            <th>Product</th>
            <th>Bedrag</th>
          </tr>
        </thead>
        <tbody>
        {
          items &&
          <> 
              {items.map((item, idx) => (           
                  <tr key={item.id + '_' + idx} onClick={() => open(item.voucher.id)} className="clickable">   
                    <td></td>              
                    <td>{item.voucher.barcode}</td>            
                    <td>{item.voucher.product.displayName}</td>            
                    <td>{"€" + item.price}</td>           
                  </tr>   
              ))}
             </>
        }
        </tbody>
      </table>
    </Container>
  );
};

export default InvoiceLineDetailGrid;
