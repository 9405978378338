import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseOrganizationContext } from "contexts";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Button from "components/UI/Buttons/Button";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { scanVoucher } from "api/services/voucher";
import { useHistory } from "react-router-dom";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  reference: Yup.string().required("Verplicht"),
});

const SearchVoucher = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const history = useHistory();

  return (
    <Page>
      <div className="page__header">
        <h1>Cadeaubonnen</h1>
        <PageHeaderSub/>
      </div>
      <div className="page__inner">

        <div className="description">
          <p>Vul de referentienummer in die boven de QR-code staat op de afdruk van de cadeaubon.</p>
        </div>
        <HeaderDropDown titleHeader="Opzoeken">
        <FormWrapper>
          <Formik
            initialValues={{
              reference: "",
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              scanVoucher(organizationId, values.reference)
                .then((value) => {
                  history.push(`/vouchers/view/${value.id}/false`);  
                })
                .catch((err) => {
                  setSubmitting(false);
                  updateError(err);
                });
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form className="form">  
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="reference" className="required">
                      Referentienummer
                    </label>
                    <Field type="text" name="reference" />
                    <ErrorMessage className="error-message" name="reference" component="div" />
                  </div>
                </div>          
                {!isSubmitting ? (
                  <div className="buttons">
                    <Button onClick={handleSubmit} label="Bevestig" disabled={isSubmitting} />
                  </div>
                ) : (
                  <Loader />
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default SearchVoucher;
