import React from "react";
import styled from "styled-components";
import defaultLogo from "../../../assets/no-thumbnail.png"

const Div = styled.div`
  svg {
    width: 100%;
  }
  .logo{
    width: 6rem;
    height: auto;
    position: initial;
    object-fit: cover;
    border-radius: 8px;
  }
  .defaultLogo{
    width: 100%;
    border-radius: 8px;
  }
`;

export default function Logo({src}) {
  if(src){
    return (
      <Div>
        <img className="logo" src={src} />
      </Div>
    )
  }
  return (
    <Div>
      <img className="defaultLogo" src={defaultLogo} />
    </Div>
  )
}