import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getOrganization } from "api/services/organization";
import PageHeaderSub from "components/PageHeaderSub";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import OrganizationInfo from "./Components/OrganizationInfo";
import Status from "components/UI/Status";
import { getOrganizationUsers } from "api/services/organization";
import OrganizationUserGrid from "./Components/OrganizationUserGrid";
import { confirmOrganization, deleteOrganization, rejectOrganization } from "api/services/admin";
import Confirm from "components/UI/Icons/Confirm";
import Delete from "components/UI/Icons/Delete";
import Reject from "components/UI/Icons/Reject";
import OrganizationProductGrid from "./Components/OrganizationProductGrid";
import { getProducts } from "api/services/product";

const Container = styled.div``;

const ViewAdminOrganization = () => {
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [showModalConfirmOrganization, setShowModalConfirmOrganization] = useState(false);
  const [showModalDeleteOrganization, setShowModalDeleteOrganization] = useState(false);
  const [showModalRejectOrganization, setShowModalRejectOrganization] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const organizationData = await getOrganization(id);
      setOrganization(organizationData);   
      const organizationUsersData = await getOrganizationUsers(id);
      setOrganizationUsers(organizationUsersData);   
      const productsData = await getProducts(id);
      setProducts(productsData.data);   
    } catch (err) {
      updateError(err);
    }
  };

  const _confirm = async () => {
    try{
      await confirmOrganization(id);
      setShowModalConfirmOrganization(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _delete = async () => {
    try{
      await deleteOrganization(id);
      setShowModalDeleteOrganization(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _reject = async () => {
    try{
      await rejectOrganization(id);
      setShowModalRejectOrganization(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      <Page>
        {!organization || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/admin/organizations"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/admin/organizations">Organisaties</a>
                </li>
                <li>
                  <span className="selected">{organization?.name}</span>
                </li>
              </ul>
            </div>          
            {showModalConfirmOrganization && (
              <Modal title={organization.name} onClose={() => setShowModalConfirmOrganization(false)} onSubmit={() => _confirm()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
                <p>Weet u zeker dat u deze organisatie wenst te bevestigen?</p>
              </Modal>
            )}           
            {showModalDeleteOrganization && (
              <Modal title={organization.name} onClose={() => setShowModalDeleteOrganization(false)} onSubmit={() => _delete()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
                <p>Weet u zeker dat u deze organisatie wenst te verwijderen?</p>
              </Modal>
            )}           
            {showModalRejectOrganization && (
              <Modal title={organization.name} onClose={() => setShowModalRejectOrganization(false)} onSubmit={() => _reject()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
                <p>Weet u zeker dat u deze organisatie wenst af te wijzen?</p>
              </Modal>
            )}   
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>   
                { organization.status == "In behandeling" &&
                  <Button label="Afwijzen" onClick={async () => setShowModalRejectOrganization(true)} type="secondary"><Reject/></Button> 
                }
                { organization.status != "Actief" &&
                  <Button label="Bevestigen" onClick={async () => setShowModalConfirmOrganization(true)}><Confirm/></Button> 
                }
                { organization.status != "In behandeling" &&
                  <Button label="Verwijderen" onClick={async () => setShowModalDeleteOrganization(true)} type="secondary"><Delete/></Button> 
                }
                <Status className={organization.status.replace(/\s/g, '')} label={organization.status}/>                
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Algemene informatie">  
              {!organization || loading ? (
                <Loader center />
              ) : (            
                <OrganizationInfo organization={organization}/>
              )
              }
              </HeaderDropDown>
              <HeaderDropDown titleHeader="Contacten">       
              {!organizationUsers || loading ? (
                <Loader center />
              ) : (       
                <OrganizationUserGrid organizationUsers={organizationUsers}/>
              )
              }
              </HeaderDropDown>
              <HeaderDropDown titleHeader="Producten">       
              {!products || loading ? (
                <Loader center />
              ) : (       
                <OrganizationProductGrid products={products}/>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewAdminOrganization;
