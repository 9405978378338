import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { VoucherForm } from "./VoucherForm";
import Modal from "components/UI/Modal";
import { deleteVoucher } from "api/services/voucher";
import { FormatDateToString, FormatDateTimeToString, getPrice } from "global/datahelper";
import Status from "components/UI/Status";
import { UseErrorContext } from "contexts";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }
`;

const VoucherGrid = ({ vouchers, refresh }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const { updateError } = UseErrorContext();
  const history = useHistory();

  const showDeleteModal = async (voucher) => {
    setSelectedVoucher(voucher);
    setShowModalDelete(true);
  };

  const open = (voucherId) => {   
    history.push("/vouchers/view/" + voucherId + "/false");
  };

  const _delete = async () => {
    try{
      await deleteVoucher(selectedVoucher.id);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Container>        
      {showModalDelete && (
        <Modal title={`Verwijder cadeaubon "${selectedVoucher.barcode}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u deze cadeaubon wil verwijderen?</div>
        </Modal>
      )}
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Barcode</th>
            <th>Product</th>
            <th>Prijs</th>
            <th>Aangemaakt op</th>
            <th>Vervaldatum</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {vouchers.map((voucher, idx) => (            
              <tr key={voucher.id + '_' + idx} onClick={() => open(voucher.id)} className="clickable">   
                <td></td>           
                <td>{voucher.barcode}</td>
                <td>{voucher.product.displayName}</td>
                <td>{getPrice(voucher)}</td>
                <td>{FormatDateTimeToString(voucher.createDate)}</td>
                <td>{FormatDateToString(voucher.expiryDate)}</td>        
                <td><Status className={voucher.status} label={voucher.status}/></td>               
              </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

VoucherGrid.defaultProps = {
  vouchers: Array.from(Array(0)),
};

export default VoucherGrid;
