import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { getCountries } from "api/services/country";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Verplicht"),
  lastName: Yup.string().required("Verplicht"),
  email: Yup.string().required("Verplicht"),
  street: Yup.string().required("Verplicht"),
  city: Yup.string().required("Verplicht"),
  postalCode: Yup.string().required("Verplicht"),
  country: Yup.string().required("Verplicht"),
});

export const CustomerForm = ({ customer, save }) => {
  const history = useHistory();
  const [countries, setCountries] = useState([]);

  useEffect(async () => {
    const ctr = await getCountries();
    setCountries(ctr);
  }, []);

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          firstName: customer?.firstName ?? "",
          lastName: customer?.lastName ?? "",
          email: customer?.email ?? "",
          company: customer?.company ?? "",
          vat: customer?.vat ?? "",
          street: customer?.street ?? "",
          city: customer?.city ?? "",
          postalCode: customer?.postalCode ?? "",
          country: customer?.country ?? "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          save(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">      
            <div className="form_row">
              <div className="field">
                <label htmlFor="firstName">
                  Voornaam
                </label>
                <Field type="text" name="firstName"/>
                <ErrorMessage className="error-message" name="firstName" component="div" />
              </div>
              <div className="field">
                <label htmlFor="lastName">
                  Achternaam
                </label>
                <Field type="text" name="lastName"/>
                <ErrorMessage className="error-message" name="lastName" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="email">
                  Email
                </label>
                <Field type="text" name="email"/>
                <ErrorMessage className="error-message" name="email" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="company">
                  Bedrijf
                </label>
                <Field type="text" name="company"/>
                <ErrorMessage className="error-message" name="company" component="div" />
              </div>
              <div className="field">
                <label htmlFor="vat">
                  BTW-nummer
                </label>
                <Field type="text" name="vat"/>
                <ErrorMessage className="error-message" name="vat" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="street">
                  Straat + nr.
                </label>
                <Field type="text" name="street"/>
                <ErrorMessage className="error-message" name="street" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="postalCode">
                  Postcode
                </label>
                <Field type="text" name="postalCode"/>
                <ErrorMessage className="error-message" name="postalCode" component="div" />
              </div>
              <div className="field">
                <label htmlFor="city">
                  Stad
                </label>
                <Field type="text" name="city"/>
                <ErrorMessage className="error-message" name="city" component="div" />
              </div>
              </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="country">Land</label>
                <Field as="select" name="country">
                  {countries?.map((country, idx) => (
                    <option value={country.code} key={idx}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="country" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button onClick={handleSubmit} label="Opslaan" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
