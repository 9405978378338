import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString } from "global/datahelper";
import { useHistory } from "react-router-dom";
import Status from "components/UI/Status";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }
`;

const OrderGrid = ({ orders }) => {
  const history = useHistory();

  const open = (orderId) => {   
    history.push("/admin/orders/" + orderId);
  };

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Nummer</th>
            <th>Aangemaakt op</th>
            <th>Klant</th>
            <th>Betalingsreferentie</th>
            <th>Totaal</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, idx) => (            
              <tr key={order.id + '_' + idx} onClick={() => open(order.id)} className="clickable">   
                <td></td>           
                <td>{order.id}</td>  
                <td>{FormatDateTimeToString(order.createDate)}</td>  
                <td>{order.customer.firstName} {order.customer.lastName}</td> 
                <td>{order.payment.paymentIntentId ?? order.payment.id}</td> 
                <td>{"€" + order.total}</td> 
                <td><Status className={order.status} label={order.status}/></td> 
             </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

OrderGrid.defaultProps = {
  orders: Array.from(Array(0)),
};

export default OrderGrid;
