import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getBalanceLine, getInvoiceLine } from "api/services/admin";
import PageHeaderSub from "components/PageHeaderSub";
import InvoiceInfo from "pages/admin/invoices/Components/InvoiceInfo";
import InvoiceLineGrid from "./Components/InvoiceLineGrid";
import InvoiceLineInfo from "./Components/BalanceLineInfo";
import InvoiceLineDetailGrid from "./Components/InvoiceLineDetailGrid";
import BalanceLineInfo from "./Components/BalanceLineInfo";

const Container = styled.div``;

const ViewBalanceLine = () => {
  const { invoiceId, id } = useParams();
  const history = useHistory();
  const [line, setLine] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const data = await getBalanceLine(id);
      setLine(data);    
    } catch (err) {
      updateError(err);
    }
  };

  const open = (id) => {   
    history.push("/admin/vouchers/" + id);
  };

  return (
    <> 
      <Page>
          <Container>
            <div className="navigation-banner">              
              <BackButton link={`/admin/invoices/${invoiceId}`}/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/admin/invoices">Facturen</a>
                </li>
                <li>
                  <a href={`/admin/invoices/${invoiceId}`}>{invoiceId}</a>
                </li>
                <li>
                  <span className="selected">{line?.reference}</span>
                </li>
              </ul>
            </div>   
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub>
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Balancelijn details">  
              {!line || loading ? (
                <Loader center />
              ) : ( 
                <>           
                  <BalanceLineInfo line={line}/>
                  <InvoiceLineDetailGrid items={line.details} open={open}/>
                </>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
      </Page>
    </>
  );
};

export default ViewBalanceLine;
