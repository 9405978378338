import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import Image from "components/UI/Images/Image";
import { getCategories } from "api/services/organization";
import { getCountries } from "api/services/country";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { Tooltip } from "components/UI/Tooltip";
import { UseErrorContext } from "contexts";
import { isValidVat } from "global/datahelper";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
    opacity: 0.8;
  }
  .fullWidth {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
  .link{
    font-style: italic;
    font-size: 1.2rem;
    font-weight: normal;
  }
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Verplicht"),
  addressLine: Yup.string().required("Verplicht"),
  city: Yup.string().required("Verplicht"),
  postalCode: Yup.string().required("Verplicht"),
  countryCode: Yup.string().required("Verplicht"),
  accountNumber: Yup.string().required("Verplicht"),
  terms: Yup.boolean().oneOf([true], 'Verplicht'),
});

export const TownForm = ({ save, profile}) => {
  const [coverFile, setCoverFile] = useState(profile?.logoUrl);
  const [cover, setCover] = useState(profile?.logoUrl);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [countries, setCountries] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    let coun = await getCountries();
    setCountries(coun);
  }, []);

  const previewImage = async (e) => {
    setUploadingCover(true);
    var selectedFile = e.target.files[0];
    setCoverFile(selectedFile);

    var reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          name: profile?.name,
          addressLine: profile?.addressLine,
          city: profile?.city,
          postalCode: profile?.postalCode,
          countryCode: profile?.countryCode,
          websiteURL: profile?.websiteURL,
          accountNumber: profile?.accountNumber,
          isTown: true,
          terms: profile ? true : false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try{
            delete values.terms;

            if (coverFile)
              values.cover = coverFile;

            // values.vat = values.vat.replaceAll(/\s/g, "");
            // values.vat = values.vat.replaceAll(".", "");

            // console.log("VAT VALID?", values.vat)

            // if (!isValidVat(values.vat))
            //   throw({"message": "Ongeldig btw-nummer formaat, gelieve zeker de landcode vooraan mee te geven"});

            // var isValid = await _checkVat(values.vat);
            // if (!isValid)
            //   throw({"message": "Ongeldig btw-nummer"});

            save(values);
          }
          catch(err){
            updateError(err);
          }
          finally{
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
          <div className="form_row">
            <div className="field">
              <label htmlFor="name" className="required">
                Naam
              </label>
              <Field type="text" name="name" className="fullWidth"/>
              <ErrorMessage className="error-message" name="name" component="div" />
            </div>
          </div>
            <label>Logo</label>
            <div className="form_row">
              <div className="field">
                <div className="logo-image">
                  <Image url={cover} onChange={previewImage} isLoading={uploadingCover} caching={false}/>
                </div>
              </div>
            </div>   
            <div className="form_row">
              <div className="field">
                <label htmlFor="addressLine" className="required">
                  Straat + nummer
                </label>
                <Field type="text" name="addressLine" className="fullWidth"/>
                <ErrorMessage className="error-message" name="addressLine" component="div" />
              </div>
            </div>  
            <div className="form_row">
              <div className="field">
                <label htmlFor="city" className="required">
                  Stad
                </label>
                <Field type="text" name="city" className="fullWidth"/>
                <ErrorMessage className="error-message" name="city" component="div" />
              </div>
              <div className="field">
                <label htmlFor="postalCode" className="required">
                  Postcode
                </label>
                <Field type="text" name="postalCode" className="fullWidth"/>
                <ErrorMessage className="error-message" name="postalCode" component="div" />
              </div>
            </div>    
            <div className="form_row">
              <div className="field">
                <label htmlFor="countryCode" className="required">
                  Land
                </label>
                <Field as="select" name="countryCode">
                  <option value=""></option>
                  {countries?.map((c, idx) => (
                    <option value={c.code} key={idx}>
                      {c.name}
                    </option>
                  ))}
                </Field> 
                <ErrorMessage className="error-message" name="countryCode" component="div" />
              </div>
            </div>  
            <div className="form_row">
              <div className="field">
                <label htmlFor="accountNumber" className="required">
                  Rekeningnummer
                </label>
                {!profile 
                 ? <Field type="text" name="accountNumber" className="fullWidth"/>
                 : <span>{profile.accountNumber}</span>
                }
                <ErrorMessage className="error-message" name="accountNumber" component="div" />
              </div>
            </div> 
            <div className="form_row">
              <div className="field">
                <label htmlFor="websiteURL">
                  Website
                </label>
                <Field type="text" name="websiteURL" className="fullWidth"/>
                <ErrorMessage className="error-message" name="websiteURL" component="div" />
              </div>
            </div> 
            {!profile &&
                  <div className="form_row">
                    <div className="field">
                      <label htmlFor="terms" className="required">
                        <Field type="checkbox" name="terms"/>
                        Ik ga akkoord met de <a href="https://stallesbon.blob.core.windows.net/public/TERMSANDCONDITIONS.pdf" target="_blank" className="link">Algemene Voorwaarde</a> en <a href="https://stallesbon.blob.core.windows.net/public/PRIVACYPOLICY.pdf" target="_blank" className="link">Privacy policy</a>
                      </label>
                      <ErrorMessage className="error-message" name="terms" component="div" />
                    </div>
                  </div>
            }
            {!isSubmitting ? (
              <div className="buttons">
                <Button onClick={handleSubmit} label="Opslaan" disabled={isSubmitting}/>
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
