import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {  FormatDateTimeToString} from "global/datahelper";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const OrderInfo = ({ order }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Aangemaakt op</label>
            <input value={FormatDateTimeToString(order.createDate)} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Klant</label>
            <a href={`/admin/orders/${order.id}/customer/${order.customer.id}`}>{order.customer.name}</a>
            {/* <input value={`${order.customer.firstName} ${order.customer.lastName}`} disabled/> */}
          </div>
          <div className="field">
            <label>Bedrijf</label>
            <a href={`/admin/orders/${order.id}/customer/${order.customer.id}`}>{order.customer.company}</a>
            {/* <input value={`${order.customer.firstName} ${order.customer.lastName}`} disabled/> */}
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Betalingsreferentie</label>
            <input value={order.payment.paymentIntentId ?? order.payment.id} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Totaal</label>
            <input value={"€" + order.total} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default OrderInfo;
