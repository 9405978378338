import React, { useEffect } from "react";
import Page from "../components/UI/Elements/Page";

import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

import styled from "styled-components";

const Body = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Login = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const isAuthenticated = useIsAuthenticated();

  const LoginHandler = () => {
    // console.log("Trying to login via popup")
    try {
      const loginResponse = instance.loginRedirect().then((response) => {
        console.log("Login Response: " + response.json());
      });

      console.log(loginResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    LoginHandler();
  }, []);

  if (true) {
    return <Body></Body>;
  }

  return (
    <Page>
      <div className="page__header">
        <h1>Login</h1>
      </div>
      <h2>Sign in to enter the world of AllesBon.</h2>
      <br />
      <button onClick={isAuthenticated ? LogoutHandler : LoginHandler}>
        {isAuthenticated ? "Logout" : "Login"}
      </button>
    </Page>
  );
};

export default Login;
