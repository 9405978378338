import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { Tooltip } from "components/UI/Tooltip";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Verplicht"),
  price: Yup.number().positive().required("Verplicht (gebruik '.' ipv ',')"),
  quantity: Yup.number().integer().positive().required("Verplicht"),
});

export const InfoForm = ({ campaign, nextStep, backStep }) => {

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          name: campaign?.name ?? "",
          price: campaign?.price ?? 0,
          quantity: campaign?.quantity ?? 0,
          hidePrice: campaign?.hidePrice ?? false,
          message: campaign?.message ?? "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          nextStep(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="name" className="required">
                  Naam (<i>max. 50 karakters)</i>
                </label>
                <Field type="text" name="name" maxLength="50"/>
                <ErrorMessage className="error-message" name="name" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="price" className="required">
                  Bedrag (€)
                </label>
                <Field type="number" name="price"/>
                <ErrorMessage className="error-message" name="price" component="div" />
              </div>
            </div> 
            <div className="form_row">
                  <div className="field">
                    <label htmlFor="hidePrice">
                      Bedrag verbergen
                      <Tooltip text="De prijs is niet zichtbaar voor de ontvanger van de cadeaubon, hij/zij ziet enkel de omschrijving."/>               
                    </label>
                    <SwitchButtonOn
                      on={campaign?.hidePrice}
                      onSwitchOn={e => { setFieldValue("hidePrice", true);}}
                      onSwitchOff={e => { setFieldValue("hidePrice", false);}}
                    />
                    <ErrorMessage className="error-message" name="hidePrice" component="div" />
                  </div>
            </div> 
            <div className="form_row">
              <div className="field">
                <label htmlFor="quantity" className="required">
                  Aantal cadeaubonnen
                </label>
                <Field type="number" name="quantity"/>
                <ErrorMessage className="error-message" name="quantity" component="div" />
              </div>
            </div> 
            <div className="form_row">
              <div className="field">
                <label htmlFor="message">
                  Bericht (vermeld op cadeaubon)
                </label>
                <Field type="textarea" name="message"/>
                <ErrorMessage className="error-message" name="message" component="div" />
              </div>
            </div> 
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Terug" onClick={() => backStep()} type="secondary" />
                <Button onClick={handleSubmit} label="Volgende" type="submit" />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
