import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateToString , FormatDateTimeToString} from "global/datahelper";
import Image from "components/UI/Images/Image";
import { editOrganization } from "api/services/organization";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { editAdminOrganization } from "api/services/admin";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const OrganizationInfo = ({ organization }) => {
  const [loading, setLoading] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(organization.monthlyPrice ?? 0);

  const _editOrganization = async () => {
    setLoading(true);
    const newObject = { ...organization, monthlyPrice };
    console.log(newObject)
    try{
      await editAdminOrganization(organization.id, newObject);  
    } catch (err) {
      updateError(err);
    }  
    finally{
      setLoading(false);
    }
  };

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <div className="logo-image">
              <Image url={organization.logoUrl} disabled/>
            </div>
          </div>
        </div>  
        <div className="form_row">
          <div className="field">
            <label>Naam</label>
            <input value={organization.name} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Aangemaakt op</label>
            <input value={FormatDateTimeToString(organization.createDate)} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Straat + nummer</label>
            <input value={organization.addressLine} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Stad</label>
            <input value={organization.city} disabled/>
          </div>
          <div className="field">
            <label>Postcode</label>
            <input value={organization.postalCode} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Land</label>
            <input value={organization.countryCode} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>BTW</label>
            <input value={organization.vat} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Rekeningnummer</label>
            <input value={organization.accountNumber} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Website</label>
            <input value={organization.websiteURL} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Categorie</label>
            <input value={organization.categoryName} disabled/>
          </div>
        </div>
        <div className="form_row">
          <div className="field">
            <label>Slug</label>
            <input value={organization.slug} disabled/>
          </div>
        </div>
        <div className="form_row">
          <div className="field">
            <label>Maandelijkse kost</label>
            <input onChange={(e) => setMonthlyPrice(e.target.value.toString())} value={monthlyPrice}/> 
          </div>
        </div>
        <div className="modal__footer">
              {loading ? (
                <Loader center />
              ) : (
                  <Button label="Opslaan" onClick={() => _editOrganization()} />
              )}
            </div>
      </div>
    </Container>
  );
};

export default OrganizationInfo;
