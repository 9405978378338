import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { UseOrganizationContext } from "contexts";
import Logo from "../components/UI/Logo";
import PrivateNavLink from "./PrivateNavLink";
import MenuItem from "./MenuItem";
import { FaIdCard, FaHome, FaGripHorizontal, FaCreditCard, FaCalendar, FaBarcode, FaPlus, FaSearch, FaRocket, FaFileInvoiceDollar, FaUsersCog, FaQuestion } from "react-icons/fa";
import Status from "./UI/Status";
import defaultLogo from "../assets/logo.png"

// All icons
// https://react-icons.github.io/react-icons/icons?name=fa

const Container = styled.div`
  .side_menu__primary {
    // overflow-y: auto;
  }

  .sub_menu {
    padding-left: 3.5rem;
  }

  .reviewed_message {
    display: flex;
    justify-content: center;
  }

`;

export default function Navigation() {
  const { status } = UseOrganizationContext();

  return (
    <Container>
      <div className="side_menu__primary">
        {status == "In behandeling" &&
          <div className="reviewed_message">
            <Status className="Vervallen" label="In behandeling" />
          </div>
        }
        <ul>
          <li>
            <NavLink activeClassName="active" to="/dashboard">
              <FaHome />
              dashboard
            </NavLink>
          </li>
          <li>
          </li>
          <li className="multi-level">
            <MenuItem icon={FaCreditCard} label="Cadeaubonnen" roles={["Vouchers.Read"]} showCollapse>
              <ul className="sub_menu">
                <PrivateNavLink to="/vouchers/scan" roles={["Vouchers.Read"]} icon={FaBarcode} label="Scannen" />
                <PrivateNavLink to="/vouchers/add" roles={["Vouchers.Create"]} icon={FaPlus} label="Aanmaken" />
                <PrivateNavLink to="/vouchers/view" roles={["Vouchers.Read"]} icon={FaSearch} label="Overzicht" />
              </ul>
            </MenuItem>
          </li>
          <li>
            <PrivateNavLink to="/campaigns" roles={["Campaigns.Read"]} icon={FaRocket} label="Campagnes" />
          </li>
          <li>
            <PrivateNavLink to="/products" roles={["Products.Read"]} icon={FaGripHorizontal} label="Producten" />
          </li>
          <li>
            <PrivateNavLink to="/invoices" roles={["Invoices.Read"]} icon={FaFileInvoiceDollar} label="Facturen" />
          </li>
          <li>
            <PrivateNavLink to="/profile" roles={["Organization.Read"]} icon={FaIdCard} label="Profiel" />
          </li>
          <li>
            <PrivateNavLink to="/personnel" roles={["Organization.Read"]} icon={FaUsersCog} label="Personeel" />
          </li>
          <li>
            <a href="https://stallesbon.blob.core.windows.net/public/Gebruikershandleiding.pdf" target="_blank"><FaQuestion />Handleiding</a>
          </li>
        </ul>
      </div>
    </Container>
  );
}
