import React, { useState, useEffect } from "react";
import Loader from "components/UI/Loader";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { UseErrorContext } from "contexts";
import { PersonnelForm } from "./Components/PersonnelForm";

const ViewPersonnel = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { updateError } = UseErrorContext();

  return (
      <Page>
        <div className="page__header">
          <h1>Personeel</h1>
              <PageHeaderSub>   
              </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="description">
            <p>Personeelsmodus geeft u een beperkte weergave en laat enkel toe om cadeaubonnen aan te maken, beheren of in te wisselen. Voor een uitgebreidere administrator configuratie dient u deze modus uit te schakelen.</p>
          </div>
          <HeaderDropDown titleHeader="Personeelsmodus" extraOption={isSaving && "Opslaan..."}>
            { isSaving
              ? <Loader/>
              : <PersonnelForm/> 
            }                   
          </HeaderDropDown>
        </div>
      </Page>    
  );
};

export default ViewPersonnel;
