import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import { mailInvoice } from "api/services/admin";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  toAddress: Yup.string().required("Verplicht"),
});

const MailInvoiceForm = ({ invoice, onClose }) => {
  const { updateError } = UseErrorContext();
console.log(invoice)
  return (
    <FormWrapper>
      <Formik
        initialValues={{
          toAddress: invoice?.email ?? "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          mailInvoice(invoice.id, values)
            .then((value) => {
              onClose();  
            })
            .catch((err) => {
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="toAddress" className="required">
                  E-mailadres
                </label>
                <Field type="email" name="toAddress" />
                <ErrorMessage className="error-message" name="toAddress" component="div" />
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => onClose()} type="secondary" />
                <Button onClick={handleSubmit} label="Verstuur" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default MailInvoiceForm;
