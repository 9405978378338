import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateToString , FormatDateTimeToString} from "global/datahelper";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const InvoiceInfo = ({ invoice }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Nummer</label>
            <input value={invoice.reference} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Periode</label>
            <input value={`${invoice.month}/${invoice.year}`} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Aangemaakt op</label>
            <input value={FormatDateTimeToString(invoice.createDate)} disabled/>
          </div>
          <div className="field">
            <label>Vervaldag</label>
            <input value={FormatDateToString(invoice.dueDate)} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Subtotaal</label>
            <input value={"€" + invoice.subtotal} disabled/>
          </div>
          <div className="field">
            <label>Btw</label>
            <input value={"€" + invoice.vat} disabled/>
          </div> <div className="field">
            <label>Totaal</label>
            <input value={"€" + invoice.total} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Balanstotaal</label>
            <input value={"€" + invoice.balanceTotal} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default InvoiceInfo;
