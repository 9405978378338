import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import Image from "components/UI/Images/Image";
import { Tooltip } from "components/UI/Tooltip";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  // price: Yup.number().required("Verplicht (gebruik '.' ipv ',')").min(1),
});

export const ProductForm = ({ product, save }) => {
  const history = useHistory();
  const [coverFile, setCoverFile] = useState(product?.cover);
  const [cover, setCover] = useState(product?.cover);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [displayName, setDisplayName] = useState(product?.displayName ?? "Cadeaubon €__ (_______)");
  const [subtitle, setSubtitle] = useState(product?.subtitle ?? "");
  const [price, setPrice] = useState(product?.price ?? "");
  const [variablePrice, setVariablePrice] = useState(product?.variablePrice ?? false);

  const previewImage = async (e) => {
    setUploadingCover(true);
    var selectedFile = e.target.files[0];
    setCoverFile(selectedFile);

    var reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  const _setDisplayName1 = (val) => {
    setDisplayName("Cadeaubon €" + val + " (" + subtitle + ")");
  }

  const _setDisplayName2 = (val) => {
    setDisplayName("Cadeaubon €" + price + " (" + val + ")");
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          name: product?.name ?? "",
          description: product?.description ?? "",
          price: product?.price ?? 0,
          expiryDateRequired: true,
          expiryDatePeriodInMonths: 12,
          isMultipleUsage: true,
          hidePrice: product?.hidePrice ?? false,
          variablePrice: product?.variablePrice ?? false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.cover = coverFile;
          if (values.variablePrice) {
            values.price = 0;
          }
          console.log("Product save:", values)
          save(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">
            <div className="form_row">
              <div className="field ">
                <label>Weergave naam</label>
                <span>{displayName}</span>
              </div>
            </div>
            {!variablePrice &&
              <div className="form_row">
                <div className="field">
                  <label htmlFor="price" className="required">
                    Bedrag (€)
                    <Tooltip text="Gebruik een . voor kommagetallen ipv ," />
                  </label>
                  <Field type="number" name="price" onChange={e => { setFieldValue("price", e.target.value); setPrice(e.target.value.toString()); _setDisplayName1(e.target.value.toString()) }} />
                  <ErrorMessage className="error-message" name="price" component="div" />
                </div>
              </div>
            }
            <div className="form_row">
              <div className="field ">
                <label>Afbeelding</label>
                <div className="cover-image">
                  <Image url={cover} onChange={previewImage} isLoading={uploadingCover} caching={false} />
                </div>
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="name">
                  Subtitel (<i>max. 50 karakters)</i>
                </label>
                <Field type="text" name="name" onChange={e => { setFieldValue("name", e.target.value); setSubtitle(e.target.value.toString()); _setDisplayName2(e.target.value.toString()) }} maxLength="50" />
                <ErrorMessage className="error-message" name="name" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="description">
                  Beschrijving (<i>max. 500 karakters)</i>
                </label>
                <Field as="textarea" name="description" maxLength="500" />
                <ErrorMessage className="error-message" name="description" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="variablePrice">
                  Variabele prijs
                  <Tooltip text="De prijs is variabel en dient op de cadeaubon zelf ingesteld te worden (enkel mogelijk voor verkoop in winkel)." />
                </label>
                <SwitchButtonOn
                  on={product?.variablePrice}
                  onSwitchOn={e => { setFieldValue("variablePrice", true); setVariablePrice(true); }}
                  onSwitchOff={e => { setFieldValue("variablePrice", false); setVariablePrice(false); }}
                />
                <ErrorMessage className="error-message" name="variablePrice" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="hidePrice">
                  Prijs verbergen
                  <Tooltip text="De prijs is niet zichtbaar voor de ontvanger van de cadeaubon, hij/zij ziet enkel de omschrijving." />
                </label>
                <SwitchButtonOn
                  on={product?.hidePrice}
                  onSwitchOn={e => { setFieldValue("hidePrice", true); }}
                  onSwitchOff={e => { setFieldValue("hidePrice", false); }}
                />
                <ErrorMessage className="error-message" name="hidePrice" component="div" />
              </div>
            </div>
            {/* <div className="form_row">
              <div className="field">
                <label htmlFor="expiryDateRequired">
                  Vervaldatum
                </label>              
                <Field as="select" name="expiryDateRequired">
                  <option value='true'>Verplicht</option>
                  <option value='false'>Niet verplicht</option>
                </Field>
                <ErrorMessage className="error-message" name="expiryDateRequired" component="div" />
              </div>
              <div className="field">
                <label htmlFor="expiryDatePeriodInMonths">
                  Periode (in maanden)
                </label>
                <Field type="number" name="expiryDatePeriodInMonths" />
                <ErrorMessage className="error-message" name="expiryDatePeriodInMonths" component="div" />
              </div>
            </div>  
            <div className="form_row">
              <div className="field">
                <label htmlFor="isMultipleUsage">
                  Kan in meerdere keren gebruikt worden
                </label>           
                <Field as="select" name="isMultipleUsage">
                  <option value='true'>Ja</option>
                  <option value='false'>Nee</option>
                </Field>
                <ErrorMessage className="error-message" name="isMultipleUsage" component="div" />
              </div>
            </div>           */}
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => history.push("/products")} type="secondary" />
                <Button onClick={handleSubmit} label="Opslaan" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
