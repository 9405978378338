import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useHistory } from "react-router-dom";
import { completeRegistration, confirmInvite, getOrganizationUser } from "api/services/user";
import { UseErrorContext } from "contexts";

const InviteRegistration = () => {
  const { id } = useParams();
  const history = useHistory();
  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const [errorMessage, setErrorMessage] = useState(null);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    if (account){  
      let u = await getOrganizationUser();  
      try {
        const resp = await confirmInvite(id, u.id);
        window.location.replace(resp);  
      } catch (err) {
        updateError(err);
      }   
    }   
  }, []);

  return (
    <Page>
      <div className="page__header">
        <h1>Registratie</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        {errorMessage && 
          <div style={{"margin-top": "20px"}}>
            Registratie mislukt: {errorMessage}
          </div>
        }
      </div>
    </Page>
  );
};

export default InviteRegistration;
