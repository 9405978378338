import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getVoucher, redeemVoucher, updateVoucher, printVoucher } from "api/services/voucher";
import PageHeaderSub from "components/PageHeaderSub";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import RedeemVoucherForm from "./Components/RedeemVoucherForm";
import MailVoucherForm from "./Components/MailVoucherForm";
import VoucherHistory from "./Components/VoucherHistory";
import Modal from "components/UI/Modal";
import VoucherInfo from "./Components/VoucherInfo";
import Status from "components/UI/Status";
import Redo from "components/UI/Icons/Redo";
import Edit from "components/UI/Icons/Edit";
import Send from "components/UI/Icons/Send";
import Print from "components/UI/Icons/Print";
import Confirm from "components/UI/Icons/Confirm";
import { AddMonths } from "global/datahelper";
import ExportVouchers from "./Components/ExportVouchers";
import { UseOrganizationContext } from "contexts";
import { TempleBuddhist } from "@mui/icons-material";

const Container = styled.div``;

const ViewVoucher = () => {
  const { id, openRedeem } = useParams();
  const [voucher, setVoucher] = useState(null);
  const [showModalScanVoucher, setShowModalScanVoucher] = useState(false);
  const [showModalMailVoucher, setShowModalMailVoucher] = useState(false);
  const [showModalExpired, setShowModalExpired] = useState(false);
  const [showModalReactivate, setShowModalReactivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const organizationId = UseOrganizationContext()?.id;
  const { isCompany, isTown } = UseOrganizationContext();

  useEffect(async () => {
    try{
      if (!organizationId) return;

      const voucherData = await getVoucher(organizationId, id);
      setVoucher(voucherData);  

      if (openRedeem === "true"){
        if (voucherData.status == "Actief")
          await _redeemVoucher(voucherData);
        if (voucherData.status == "Vervallen")
          _openExpiredModal(voucherData);
      }
    } catch (err) {
      updateError(err);
    }
  }, [organizationId]);

  const refresh = async () => {
    try{
      if (!organizationId) return;
      const voucherData = await getVoucher(organizationId, id);
      setVoucher(voucherData);  
    } catch (err) {
      updateError(err);
    }
  };

  const _redeemVoucher = async (v) => {
    if(v.product.isMultipleUsage) {
      setShowModalScanVoucher(true)
    }
    else{
      let values = {'redeemedPrice': v.remainingPrice};
      await redeemVoucher(organizationId, id, values)
      await refresh();
    }
  }  

  const _reactivate = async () => {
    try{
      await updateVoucher({...voucher, "status": "Actief"});
      setShowModalReactivate(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _printVoucher = async () => {
    try{      
      setLoading(true);
      const result = await printVoucher(voucher.id);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});

      // OPEN IN NEW TAB   
      //  const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);

      // DOWNLOAD FILE
      var url = URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.target = "_blank";
      tempLink.setAttribute('download', 'voucher.pdf');
      tempLink.click();

    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };

  const _openExpiredModal = (v) => {
    if (v.status == "Finaal vervallen")
      setShowModalExpired(true);
    if (v.status == "Vervallen")
      setShowModalReactivate(true);
  }
console.log(voucher)
  return (
    <>
      <Page>
        {!voucher || loading || !organizationId ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              {/* <BackButton link="/vouchers/view"/> */}
              <p> </p>
              <ul className="breadcrumbs">
                <li>
                  <a href="/vouchers/view">Cadeaubonnen</a>
                </li>
                <li>
                  <span className="selected">{voucher?.barcode}</span>
                </li>
              </ul>
            </div>      
            {showModalScanVoucher && (
              <Modal title="Inwisselen" hideActions>
                <RedeemVoucherForm voucher={voucher} onClose={async () => {setShowModalScanVoucher(false); await refresh();}}/>
              </Modal>
            )}         
            {showModalMailVoucher && (
              <Modal title="Verstuur via e-mail" hideActions>
                <MailVoucherForm voucher={voucher} onClose={async () => {setShowModalMailVoucher(false); await refresh();}}/>
              </Modal>
            )}   
            {showModalExpired && (
              <Modal title={`Cadeaubon "${voucher?.barcode}" is vervallen`} onClose={() => setShowModalExpired(false)} onSubmit={() => setShowModalExpired(false)} cancelLabel={"Annuleer"} submitLabel={"Sluiten"}>
                <p>Cadeaubon is meer dan 6 maanden vervallen en kan niet meer verlengd worden.</p>
              </Modal>
            )}  
            {showModalReactivate && (
              <Modal title={`Cadeaubon "${voucher?.barcode}" is vervallen`} onClose={() => setShowModalReactivate(false)} onSubmit={() => _reactivate()} cancelLabel={"Annuleer"} submitLabel={"Verlengen"}>
                <p>Wenst u de cadeaubon te verlengen?</p>
                <p><b>LET OP</b> cadeaubon wordt 6 maanden verlengd, reken hiervoor €5 af met de klant.</p>
              </Modal>
            )}     
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>   
                { (voucher.status == "Vervallen" && voucher?.product?.organization?.id == organizationId && !isTown && !isCompany) && 
                  <Button label="Verlengen" onClick={() => _openExpiredModal(voucher)} type="secondary"><Redo/></Button> 
                }
                { voucher.status == "Actief" && 
                  <>
                  {voucher?.product?.organization?.id == organizationId &&
                    <>
                      <Link to={`/vouchers/edit/${voucher.id}`}>
                        <Button label="Wijzigen" roles={["Vouchers.Create"]} type="secondary"><Edit/></Button>
                      </Link> 
                      <Button label="Afdrukken" onClick={async () => _printVoucher()} type="secondary"><Print/></Button> 
                      { !voucher.isSent
                        ? <Button label="Verstuur" onClick={async () => setShowModalMailVoucher(true)}><Send/></Button> 
                        : <Button label="Verstuur opnieuw" onClick={async () => setShowModalMailVoucher(true)} type="secondary"><Send/></Button>
                      }
                    </>
                  }
                  { !isTown && !isCompany && <Button label="Inwisselen" onClick={async () => _redeemVoucher(voucher)} ><Confirm/></Button>}
                  </>
                }
                <Status className={voucher.status} label={voucher.status}/> 
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Cadeaubon">              
                <VoucherInfo voucher={voucher}/>
                <VoucherHistory items={voucher.voucherHistoryItems} refresh={refresh}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewVoucher;
