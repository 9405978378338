import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { getActiveProducts } from "api/services/product";
import { UseErrorContext, UseOrganizationContext } from "contexts";

const FormWrapper = styled.div`  
`;

const ValidationSchema = Yup.object().shape({
  productId: Yup.string().required("Verplicht"),
}) 

export const VoucherForm = ( {voucher, save, close, isEditing, productId} ) => {  
  const organizationId = UseOrganizationContext()?.id;  
  const [ products, setProducts ] = useState([]); 
  const [ showPrice, setShowPrice ] = useState(false);
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    if (organizationId){
      try{
        var prods = await getActiveProducts(organizationId);
        setProducts(prods);

        if (productId){
          const product = prods.filter(x => x.id == productId)[0];
          const variablePrice = product.variablePrice;
          setShowPrice(variablePrice);
        }

      } catch (err) {
        updateError(err);
      }
      finally{
        setIsLoading(false);
      }
    }
  }, [organizationId]);


  const updateProduct = (productId) => {
    const product = products.filter(x => x.id == productId)[0];
    const variablePrice = product.variablePrice;
    setShowPrice(variablePrice);
  }

  return (
    isLoading 
    ? <Loader/>
    : <FormWrapper>
      <Formik
        initialValues={{ 
          productId: voucher?.productId ?? productId,
          from: voucher?.from ?? "",
          to: voucher?.to ?? "",
          message: voucher?.message ?? "",
          remainingPrice: voucher?.remainingPrice,
          price: voucher?.price ?? 0,
        }} 
          validationSchema={ValidationSchema}          
          onSubmit={async (values, { setSubmitting }) => {    
            try{
              if (showPrice){
                if (values.price < 10){
                  throw({"message": "Gelieve een minimum prijs van €10 in te stellen"});
                }
              }
              else{
                const product = products.filter(x => x.id == values.productId)[0];
                values.price = product.price;
              }
              console.log("SAVE VOUCHER", values)
              save(values);            
            } catch (err) {
              updateError(err);
            }    
            finally{
              setSubmitting(false);  
            } 
          }}
          >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">
            { !isEditing && 
            <>
              {products.length === 0 &&
                <div className="description">
                  <p>Maak eerst een product aan voordat u cadeaubonnen kan verkopen. <a href={`/products`}>Ga naar producten.</a></p>
                </div>
              }
              <div className="form_row">
                <div className="field">
                  <label htmlFor="productId" className="required">Product</label> 
                  <Field as="select" name="productId" onChange={e => { setFieldValue("productId", e.target.value); updateProduct(e.target.value.toString());}}>
                    <option value=""></option>
                    {products?.map((p, idx) => (
                      <option value={p.id} key={idx}>
                        {p.displayName}
                      </option>
                    ))}
                  </Field>  
                  <ErrorMessage className="error-message" name="productId" component="div" />
                </div>
              </div> 
              { showPrice &&
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="price">Bedrag (€)</label>                 
                    <Field type="number" name="price"/>
                  </div>
                </div> 
              }
            </>
            }  
            {/* { isEditing && 
            <div className="form_row">
              <div className="field">
                <label htmlFor="remainingPrice" className="required">Bedrag (in euro)</label>
                <Field type="number" name="remainingPrice"/>
                <ErrorMessage className="error-message" name="remainingPrice" component="div" />
              </div>
            </div>
            }                     */}
            <div className="form_row">
              <div className="field">
                <label htmlFor="from">Van</label>                 
                <Field type="text" name="from"/>
              </div>
              <div className="field">
                <label htmlFor="to">Aan</label>                 
                <Field type="text" name="to"/>
              </div>
            </div>        
            <div className="form_row">
              <div className="field">
                <label htmlFor="message">Bericht</label>                 
                <Field type="textbox" name="message"/>
              </div>
            </div>
            {!isSubmitting ? (
                <div className="buttons">
                  <Button label="Annuleer" onClick={close} type="secondary" />
                  <Button onClick={()=>{handleSubmit()}} label="Opslaan" disabled={isSubmitting} />         
                </div>
              ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
