import React from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { SupportForm } from "./Components/SupportForm";
import { useHistory } from "react-router-dom";
import Button from "components/UI/Buttons/Button";

const Support = () => {
  const history = useHistory();

  return (
    <Page>
      <div className="page__header">
        <h1>SUPPORT</h1>
        <PageHeaderSub/>    
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Contact">
          <SupportForm/>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Support;
