import { getAxiosClient } from "../axios";

export const getOrganizations = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations`, { params: {...params}})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addOrganization = async (data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editOrganization = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const uploadLogo = async (id, file) => {
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", file);

  return await axiosClient
    .put(`/organizations/${id}/logo`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getCategories = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const getOrganizationUsers = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/organizationusers`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const checkVat = async (vat) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/vat/${vat}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};