import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString } from "global/datahelper";
import Status from "components/UI/Status";
import Loader from "components/UI/Loader";
import { printVoucher, updateContactVoucher } from "api/services/voucher";
import Button from "components/UI/Buttons/Button";
import Print from "components/UI/Icons/Print";
import { useHistory } from "react-router-dom";
import { getContacts } from "api/services/contact";
import { UseOrganizationContext } from "contexts";

const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
  table {
    margin-top: 2rem;
  }
`;

const VouchersGrid = ({ items, refresh }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;  
  const [contacts, setContacts] = useState([]);

  useEffect(async () => {
    if (!organizationId) return;
    let contactsResponse = await getContacts(organizationId);
    setContacts(contactsResponse.data);
  }, [organizationId]);

  const _print = async (e, voucher) => {
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    try{      
      setLoading(true);
      const result = await printVoucher(voucher.id);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});

      // OPEN IN NEW TAB   
      //  const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);

      // DOWNLOAD FILE
      var url = URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'voucher.pdf');
      tempLink.click();

    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };

  const open = (id) => {   
    history.push("/vouchers/view/" + id + "/false");
  };

  const onChangeContact = async (e, voucher) => {
    const val = e.target.value.toString();
    await updateContactVoucher(voucher.id, val);
    await refresh();
  };

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Barcode</th>
            <th>Openstaand bedrag</th>
            <th>Aan</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {!items || loading
          ? <Loader center />
           : <> 
              {items.map((voucher, idx) => (            
                  <tr key={voucher.id + '_' + idx} onClick={() => voucher.status != "Nieuw" && open(voucher.id)} className={voucher.status != "Nieuw" && "clickable"}>   
                    <td></td>           
                    <td>{voucher.barcode}</td>
                    <td>{voucher.remainingPrice} / {voucher.price} EUR</td>
                    <td>{voucher.status == "Nieuw" && 
                      <select onChange={(e) => onChangeContact(e, voucher)} value={voucher.contact?.id}>    
                        <option value="0"></option>           
                        {contacts?.map((contact, idx) => (
                          <option key={idx} value={contact.id}>{contact.fullName}</option>
                        ))}
                      </select>
                    }</td>
                    <td><Status className={voucher.status} label={voucher.status}/></td> 
                    <td>{voucher.status != "Nieuw" && <Button label="Afdrukken" onClick={(e) => _print(e, voucher)} type="secondary"><Print/></Button>}</td>
                </tr>          
              ))}
            </>
        }
        </tbody>
      </table>
    </Container>
  );
};

VouchersGrid.defaultProps = {
  items: Array.from(Array(0)),
};

export default VouchersGrid;
