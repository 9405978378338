import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { deleteOrder, getInvoiceByOrderId, getOrder, sendPaymentRequest } from "api/services/admin";
import PageHeaderSub from "components/PageHeaderSub";
import OrderInfo from "./Components/OrderInfo";
import OrderLineGrid from "./Components/OrderLineGrid";
import AdminVoucherGrid from "../vouchers/Components/AdminVoucherGrid.js"
import Invoice from "components/UI/Icons/Invoice";
import Button from "components/UI/Buttons/Button";
import Status from "components/UI/Status";
import Delete from "components/UI/Icons/Delete";
import Modal from "components/UI/Modal";
import Confirm from "components/UI/Icons/Confirm";
import Send from "components/UI/Icons/Send";

const Container = styled.div``;

const ViewAdminOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const history = useHistory();
  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const orderData = await getOrder(id);
      setOrder(orderData);    
    } catch (err) {
      updateError(err);
    }
  };

  const openInvoice = async () => {
    try{
      const invoice = await getInvoiceByOrderId(id);
      history.push("/admin/invoices/" + invoice.id);
    } catch (err) {
      updateError(err);
    }
  }

  const _sendPaymentRequest = async () => {
    try{
      setLoading(true);
      await sendPaymentRequest(order.id);
      refresh();
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  }  

  const _delete = async () => {
    try{
      setLoading(true);
      await deleteOrder(id);
      setShowModalDelete(false);
      history.push("/admin/orders")
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <>
      {showModalDelete && (
        <Modal title={`Verwijder "${order.id}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u dit order wilt verwijderen?</div>
        </Modal>
      )}    
      <Page>
        {!order || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/admin/orders"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/admin/orders">Orders</a>
                </li>
                <li>
                  <span className="selected">{order?.id}</span>
                </li>
              </ul>
            </div>   
            <div className="page__header">  
              <h1></h1>  
              <PageHeaderSub show>
                { order.status == "Nieuw" &&
                  <>
                    <Button label="Verwijderen" onClick={() => setShowModalDelete(true)} type="secondary"><Delete/></Button>
                    <Button label="Verstuur betaling" onClick={_sendPaymentRequest} disabled={order.reminderMailSent}><Send/></Button>
                  </>
                }
                <Button label="Factuur" type="secondary" onClick={() => openInvoice()}><Invoice/></Button>   
                <Status className={order.status} label={order.status}/>       
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Cadeaubon">  
              {!order || loading ? (
                <Loader center />
              ) : (           
                <> 
                  <OrderInfo order={order}/>
                  <OrderLineGrid items={order.orderLines}/>
                  <label style={{margin: '2rem 0'}}>Cadeaubonnen</label>
                  <AdminVoucherGrid vouchers={order.vouchers}/>
                </>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewAdminOrder;
