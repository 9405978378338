import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const BalanceLineInfo = ({ line }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Referentie</label>
            <input value={line.reference} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Omschrijving</label>
            <input value={line.description} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Aantal</label>
            <input value={line.quantity} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Totaal</label>
            <input value={"€" + line.total} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default BalanceLineInfo;
