import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const ContactInfo = ({ contact }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Voornaam</label>
            <input value={contact.firstName} disabled/>
          </div>
          <div className="field">
            <label>Achternaam</label>
            <input value={contact.lastName} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>E-mail</label>
            <input value={contact.email} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default ContactInfo;
