import React, { useState } from "react";
import { addCampaign } from "api/services/campaign";
import { UseOrganizationContext } from "contexts";
import { useHistory } from "react-router-dom";
import { UseErrorContext } from "contexts";
import Loader from "components/UI/Loader";
import Stepper from "components/UI/Stepper/Stepper";
import ReviewSubmit from "./ReviewSubmit";
import { ParticipantsForm } from "./ParticipantsForm";
import { InfoForm } from "./InfoForm";

const steps = ['Informatie *', 'Deelnemers', 'Overzicht'];

const CampaignWizard = ({ category }) => {
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [campaignData, setCampaignData] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [invites, setInvites] = useState([]);

  const nextStep = () => {
    if (activeStep != steps.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const backStep = () => {
    if (activeStep == 1) {
      history.push("/campaigns")
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  };

  const saveInfoForm = (data) => {
    setCampaignData(data);
    nextStep();
  };

  const savePatricipantsForm = (pars, invs) => {
    setParticipants(pars);
    setInvites(invs);
    nextStep();
  };

  const save = async () => {
    setIsLoading(true);
    try {
      var values = campaignData;
      values["participantIds"] = participants.map(x => x.id);
      values["invites"] = invites;
      const rep = await addCampaign(organizationId, values); 
      history.push("/campaigns/view/" + rep.id);
      return rep;
    } catch (err) {
      updateError(err);
    }
    finally {
      setIsLoading(false);
    }
  };

  const goToStep = async (step) => {
    setActiveStep(step);
  };

  if (isLoading) {
    return (
      <Loader />
    )
  }
  else {
    switch (activeStep) {
      case 1:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <InfoForm campaign={campaignData} backStep={backStep} nextStep={saveInfoForm} />
          </Stepper>
        );
      case 2:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <ParticipantsForm participantsData={participants} invitesData={invites} backStep={backStep} nextStep={savePatricipantsForm} />
          </Stepper>
        );
      case 3:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <ReviewSubmit campaign={campaignData} participants={participants} invites={invites} backStep={backStep} save={save} />
          </Stepper>
        );
      default:
        return (
          <></>
        );
    }
  }

};

export default CampaignWizard;
