import React, { useState, useEffect } from "react";
import { UseOrganizationContext } from "contexts";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { delay } from "global/datahelper";
import SearchBar from "../../components/UI/SearchBar";
import Button from "components/UI/Buttons/Button";
import { getVouchers, getFilters } from "api/services/voucher";
import { Link } from "react-router-dom";
import VoucherGrid from "./Components/VoucherGrid";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import ExportVouchers from "./Components/ExportVouchers";
import Add from "components/UI/Icons/Add";
import Search from "components/UI/Icons/Search";
import Confirm from "components/UI/Icons/Confirm";

const Vouchers = () => {
  const organizationId = UseOrganizationContext()?.id;
  const [search, setSearch] = useState("");
  const [vouchers, setVouchers] = useState(null);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [productFilter, setProductFilter] = useState(null);
  const [productFilters, setProductFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);

  // Init filters
  useEffect(async () => {
    if (!organizationId) return;
      // Filters
      const filtersData = await getFilters(organizationId);
      setProductFilters(filtersData["products"]);
      setStatusFilters(filtersData["status"]);
  }, [organizationId]);


  // Init
  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search, currentPage, productFilter, statusFilter, pageSize]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);
console.log(pageSize)
      // GET endpoint
      var params = {
        'page': currentPage,
        'search': search,
        'fromDate': fromDateFilter,
        'toDate': toDateFilter,
        'status': statusFilter,
        'pageSize': pageSize,
      }
      console.log(params)
      if (productFilter){
        params["productIds"] = parseInt(productFilter);
      }

      const vouchersResponse = await getVouchers(organizationId, params);
      
      // Set data
      let vouchersData = vouchersResponse.data;
      setVouchers(vouchersData);

      // Pagination
      let vouchersHeader = vouchersResponse.headers; 
      
      let pagination = GetPagination(vouchersHeader);
      let totalCount = pagination.totalCount;
      let sizePerPage = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / sizePerPage);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
      await delay(1000);
      const val = e.target.value.toString();
      setSearch(val);
  };
  
  // Filters
  const onChangeProductFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setProductFilter(val);
  };

  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };
  
  const onChangeFromDateFilter = async (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = async(e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  const onChangePageSize = async(e) => {
    const val = e.target.value.toString();
    let intValue = parseInt(val);
    setPageSize(intValue);
  };

  return (
    <Page>
      <div className="page__header">
        <h1>Cadeaubonnen</h1>
        <PageHeaderSub show>
          {/* <Link to="/vouchers/view">
            <Button label="Opzoeken" roles={["Vouchers.Create"]}><Search/></Button>
          </Link> */}
          {productFilters.length > 0 &&
            <Link to="/vouchers/add">
              <Button label="Nieuwe cadeaubon" roles={["Vouchers.Create"]} ><Add/></Button>
            </Link>
          }
          <ExportVouchers items={vouchers}/>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="description">
          {productFilters.length > 0 
            ? <p>Hier kan u een overzicht vinden van al uw verkochte cadeaubonnen.</p>
            : <p>Maak eerst een product aan voordat u cadeaubonnen kan beheren. <a href={`/products`}>Ga naar producten.</a></p>
          }
        </div>
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch} placeholder={"Zoek op barcode"}/>
        </div>
        <HeaderDropDown titleHeader="Alle cadeaubonnen">
          {!vouchers || loading ? (
            <Loader center />
          ) : (
            <>
              <div className="filters">
                <div className="row">
                  <div>
                    <label>Aantal per pagina</label>
                    <select onChange={onChangePageSize} value={pageSize}>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="100000000">∞</option>
                    </select>
                  </div>
                  <div>
                    <label>Product</label>
                    <select onChange={onChangeProductFilter} value={productFilter}>
                      <option value="">Alle</option>
                      {productFilters?.map((p, idx) => (
                        <option key={idx} value={p.id}>{p.shortDisplayName}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Status</label>
                    <select onChange={onChangeStatusFilter} value={statusFilter}>
                      <option value="">Alle</option>
                      {statusFilters.map((filter, idx) => (
                        <option key={idx}>{filter}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Aanmaakdatum (van)</label>
                    <input type="date" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>Aanmaakdatum (tot)</label>
                    <input type="date" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                  <div onClick={refresh} ><Confirm/></div>
                </div>
              </div>
              <VoucherGrid canEdit vouchers={vouchers} refresh={refresh} />
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Vouchers;
