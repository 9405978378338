import React from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "components/UI/Buttons/Button";
import { UseOrganizationContext } from "contexts";
import * as Yup from 'yup'
import { useHistory } from "react-router-dom";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { AddOrderEnveloppes } from "api/services/enveloppes";
import enveloppes from "assets/enveloppe.png";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  quantity: Yup.number().required("Verplicht"),
  color: Yup.string().required('Verplicht'),
}) 

export const EnveloppesForm = () => {
  const history = useHistory();
  const { updateError } = UseErrorContext();

  const callBack = async (values) => {
    try{
      const resp = await AddOrderEnveloppes(values);
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  return (
      <FormWrapper>
          <Formik
            initialValues={{ 
              quantity: 1,
              color: "black",
              description: ""               
              }}
            validationSchema={ValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              callBack(values)
                .then((value) => {
                  history.push("/enveloppes/success")
                })
                .catch((err) => {
                  setSubmitting(false);
                  updateError(err);
                });
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form className="form">
              <div className="form_row">
                <div className="field">
                  <label htmlFor="quantity" className="required">
                    Aantal (1 = 30 stuks)
                  </label>
                  <Field type="number" name="quantity" />
                  <ErrorMessage className="error-message" name="quantity" component="div" />
                </div>
              </div>  
              <div className="form_row">
                <div className="field">
                  <label htmlFor="color" className="required">
                    Kleur
                  </label>           
                  <Field as="select" name="color">
                    <option value='black'>Zwart</option>
                    <option value='green'>Groen</option>
                    <option value='Brown'>Bruin</option>
                    <option value='mix'>Mix</option>
                  </Field>
                  <ErrorMessage className="error-message" name="color" component="div" />
                </div>
              </div> 
              <div className="form_row">
                <img className="image" src={enveloppes} alt="" width="500"/>
              </div>
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="description">Opmerking</label>
                    <Field as="textarea" name="description" />
                    <ErrorMessage className="error-message" name="description" component="div" />
                  </div>
                </div>            
                {!isSubmitting ? (
                  <div className="buttons">
                    <Button onClick={()=>{handleSubmit()}} label="Bestel" disabled={isSubmitting} />
                  </div>
                ) : (
                  <Loader />
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
  );
};
