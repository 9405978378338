import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loader from "components/UI/Loader";
import { UseOrganizationContext } from "contexts";
import { getCategories } from "api/services/organization";
import SearchBar from "components/UI/SearchBar";
import { Pagination } from "@mui/material";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import { delay } from "global/datahelper";
import Button from "components/UI/Buttons/Button";
import Invite from "components/UI/Icons/Invite";
import Modal from "components/UI/Modal";
import InviteParticipantsForm from "./InviteParticipantsForm";
import Status from "components/UI/Status";
import Trash from "components/UI/Icons/Trash";
import { getCampaignOrganizations } from "api/services/campaign";

const Container = styled.div`
  table{
    margin-top:2rem;
    td{
      padding: 1rem;
    }
  }
  .footer {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    button {
      margin-left: 2rem;
    }
  }
  .invite{
    margin-top: 2rem;
  }
`;

const AddParticipantsGrid = ({ items, inv, onClose, onSubmit }) => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizations, setSelectedetOrganizations] = useState(items ?? []);
  const { updateError } = UseErrorContext();
  const { city, isTown, isCompany } = UseOrganizationContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [search, setSearch] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [showModalInvite, setShowModalInvite] = useState(false);  
  const [invites, setInvites] = useState(inv ?? []);
 
  useEffect(async () => {
    refresh();
  }, [ search, currentPage, categoryFilter]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);

      // GET endpoint
      var params = {
        'page': currentPage,
        'search': search,
      }
      if (isTown){
        params["location"] = city;
      }
      if (categoryFilter){
        params["categoryId"] = parseInt(categoryFilter);
      }
      const resp = await getCampaignOrganizations(params);
      
      // Set data
      let data = resp.data;
      setOrganizations(data);

      // Pagination
      let header = resp.headers; 
      
      let pagination = GetPagination(header);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      // Filters
      const categories = await getCategories();
      setCategoryFilters(categories);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeCategoryFilter = (e) => {
  setCurrentPage(1);
  const val = e.target.value.toString();
  setCategoryFilter(val);
  };

  const select = (item) => {   
    // add
    if (!selectedOrganizations.some(x => x.id == item.id)){
      setSelectedetOrganizations([...selectedOrganizations, item]);
    }
    // remove
    else{
      setSelectedetOrganizations(selectedOrganizations.filter(x => x.id !== item.id))
    }
  };

  const _updateInvites = (items) => {  
    setInvites([...invites, ...items]);
  };


  const _deleteInvite = (item) => {  
    setInvites(invites.filter(x => x != item))
  };

  return (
    <Container> 
      {showModalInvite && (
          <Modal title={`Uitnodigen`} hideActions>
             <InviteParticipantsForm onClose={() => setShowModalInvite(false)} callback={_updateInvites}/>
          </Modal>
      )}
      <div className="searchBarContainer">
        <SearchBar onChange={onSearch}/>
      </div>

      <div className="filters">
                  <div className="row">
                    <div>
                      <label>Categorie</label>
                      <select onChange={onChangeCategoryFilter} value={categoryFilter}>
                        <option value="">Alle</option>
                        {categoryFilters.map((filter, idx) => (
                          <option key={idx} value={filter.id}>{filter.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
      </div>


     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Naam</th>
            <th>Stad</th>
          </tr>
        </thead>
        <tbody>
        {!organizations 
          ? <Loader center />
           : <> 
              {organizations.map((item, idx) => (          
                  <tr key={item.id + '_' + idx} onClick={() => select(item)} className={`${selectedOrganizations.some(e => e.id === item.id) ? "clickable select" : "clickable"}`}> 
                    <td></td>              
                    <td>{item.name}</td>    
                    <td>{item.city}</td>           
                  </tr>      
              ))}
             </>
        }
        </tbody>
      </table>
      <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} /> 

      <div className="invite">
        <p>Deelnemer niet gevonden?</p>
        <Button label="Nodig uit" onClick={() => setShowModalInvite(true)}><Invite/></Button>
      </div>

      {invites.length > 0 &&
      <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Naam</th>
            <th>E-mail</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
              {invites.map((item, idx) => (          
                  <tr key={idx}> 
                    <td></td>              
                    <td>{item.name}</td>    
                    <td>{item.email}</td>  
                    <td style={{"padding": '0'}}>{item.status && <td><Status className={item.status.replace(/\s/g, '')} label={item.status}/></td> } </td>     
                    <td>
                      { item.status == "Te versturen" &&
                        <div onClick={() => _deleteInvite(item)} className="icon-button">
                          <Trash/>
                        </div>
                      } 
                    </td>      
                  </tr>      
              ))}
        </tbody>
       </table>
      }

      <div className="footer">
        <Button type="secondary" label="Sluiten" onClick={() => onClose()} />
        <Button label="Bevestig" onClick={() => onSubmit(selectedOrganizations, invites)} /> 
      </div> 
    </Container>
  );
};

export default AddParticipantsGrid;
