import { getAxiosClient } from "../axios";

export const getAdminOrganizations = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/organizations`, { params: {"status": "Actief", ...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const confirmOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/organizations/${id}/confirm`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/organizations/${id}/delete`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const rejectOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/organizations/${id}/reject`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getAdminVouchers = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/vouchers`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getInvoices = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/invoices`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getInvoice = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/invoices/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getBalanceLine = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/balancelines/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const generateInvoice = async (data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/invoices/generate`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteInvoice = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/admin/invoices/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
}; 

export const updateInvoice = async (invoice) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/invoices/${invoice.id}`, invoice)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const printInvoice = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/invoices/${id}/print`, {
       responseType: 'blob', 
       headers: {
        Accept: 'application/pdf',
      }, 
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const mailInvoice = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/invoices/${id}/mail`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const generateZipMonthlyInvoices = async (data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/invoices/zip`, data )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFilters = async () => {
  const organizations = await getAdminOrganizations();
  return {"organizations": organizations.data};
};

export const getDayReport = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/reports/day`, { params: {...params}} )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getMonthReport = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/reports/month`, { params: {...params}} )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getYearReport = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/reports/year`, { params: {...params}} )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const getOrganizationsReport = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/reports/organizations`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getProfitReport = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/reports/profit`, { params: {...params}} )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrders = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/orders`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrder = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/orders/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const sendPaymentRequest = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/orders/${id}/mail`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteOrder = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/admin/orders/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
}; 

export const getInvoiceByOrderId = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/orders/${id}/invoice`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getCustomer = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/customers/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateCustomer = async (id, customer) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/customers/${id}`, customer)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const editAdminOrganization = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/admin/organizations/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addInvoiceLine = async (id, invoiceLine) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/admin/invoices/${id}/lines`, invoiceLine)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};