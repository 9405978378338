import React from "react";  
import { utils, writeFile } from 'xlsx';
import Button from "components/UI/Buttons/Button";
import moment from "moment";
import Export from "components/UI/Icons/Export";

const dataSet2 = [
  {
      name: "Johnson",
      total: 25,
      remainig: 16
  },
  {
      name: "Josef",
      total: 25,
      remainig: 7
  }
];

const ExportVouchers = ({ items }) => {

  const headings = [[
    'Id',
    'Barcode',
    'Aangemaakt op',
    'Vervaldatum',
    'Prijs',
    'Resterende prijs',
    'Status',
  ]];

  const formatExcelData = (items) => {
    return items.map((x) => {
      return {
        'id': x.id,
        'barcode': x.barcode,
        'createDate': moment(x.createDate).format("DD-MM-YYYY"),
        'expiryDate': moment(x.expiryDate).format("DD-MM-YYYY"),
        'price': x.price,
        'remainingPrice': x.remainingPrice,
        'status': x.status,
      }
    });
  }

  const handleExport = () => {     
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, formatExcelData(items), { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'data');
    let filename = "Export_Cadeaubonnen_" + moment(Date.now()).format("YYYYMMDD_HHmm") + ".xlsx";
    writeFile(wb, filename);
}

  return (
    <Button onClick={handleExport} label="Exporteer" type="secondary"><Export/></Button>
  );
};

export default ExportVouchers;
