import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString, FormatDateToString } from "global/datahelper";
import Status from "components/UI/Status";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }

  .overdue{
    background-color: rgba(255,0,0,0.2);
  }
`;

const InvoiceGrid = ({ invoices, open }) => {

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Nummer</th>
            <th>Organisatie</th>
            <th>Order/Campagne</th>
            <th>Periode</th>
            <th>Aangemaakt op</th>
            <th>Vervaldag</th>
            <th>Totaal</th>
            <th>Balans</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, idx) => (            
              <tr key={invoice.id + '_' + idx} onClick={() => open(invoice.id)} className={`clickable ${invoice.paymentOverdue ? "overdue" : ""}`}>   
                <td></td>           
                <td>{invoice.reference}</td>
                <td>{invoice.organization?.name}</td>
                <td>{invoice.orderId > 0 ? invoice.orderId : invoice.campaignId > 0 ? invoice.campaignId : ""}</td>
                <td>{invoice.month}/{invoice.year}</td>
                <td>{FormatDateTimeToString(invoice.createDate)}</td> 
                <td>{FormatDateToString(invoice.dueDate)}</td>   
                <td>€{invoice.total}</td>
                <td>€{invoice.balanceTotal}</td>
                <td><Status className={invoice.status} label={invoice.status}/></td> 
             </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

InvoiceGrid.defaultProps = {
  invoices: Array.from(Array(0)),
};

export default InvoiceGrid;
