import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { ContactForm } from "pages/contacts/Components/ContactForm";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getContact, updateContact } from "api/services/contact";
import { ValidateEmail } from "global/datahelper";

const Container = styled.div``;

const EditContact = () => {
  const { id } = useParams();
  const history = useHistory();
  const [contact, setContact] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try{
      const contactData = await getContact(id);      
      setContact(contactData);    
    } catch (err) {
      updateError(err);
    }
  }, []);

  const _updateContact = async (contactData) => {
    try{
      setIsSaving(true);
      const newObject = { ...contact, ...contactData };
      const resp = await updateContact(newObject);
      setIsSaving(false);
      history.push("/contacts");    
    } catch (err) {
      updateError(err);
    }
    finally{
      setIsSaving(false);
    }
  };

  return (
    <>
      <Page>
        {!contact ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/contacts"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/contacts">Contacten</a>
                </li>
                <li>
                  <span className="selected">{contact?.fullName}</span>
                </li>
              </ul>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Campagne wijzigen" extraOption={isSaving && "Opslaan..."}>
                {isSaving 
                  ? <Loader />
                  : <ContactForm contact={contact} save={_updateContact} />
                }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default EditContact;
