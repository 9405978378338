import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ValidateAccess } from 'global/authorization';
import { NavLink } from 'react-router-dom';
import { UseUserContext } from "contexts";

const Container = styled.div`
  .disabled {
    color: var(--color-medium-grey);
    & svg{
      path{
        fill: var(--color-medium-grey);
      }
    }
  }
`;

 const PrivateCard = ({ to, roles, ...rest }) => {
  const [ hasAccess, setHasAccess ] = useState([]);
  const { permissions } = UseUserContext();

  useEffect(async () => {    
    const access = await ValidateAccess(roles, permissions);
    setHasAccess(access);
  }, [permissions]);

  const handleClick = (e) => {
    if (!hasAccess)
      e.preventDefault()
  }

    return (
      <NavLink to={to} {...rest} onClick={handleClick}/> //style={{ cursor: hasAccess ? "pointer" : "default" }} **NOT WORKING BECCAUSE .card_image__container has pointer set    
    );
 };

 export default PrivateCard;