import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import ContactGrid from "pages/contacts/Components/ContactGrid";
import Button from "components/UI/Buttons/Button";
import SearchBar from "../../components/UI/SearchBar";
import { UseOrganizationContext } from "contexts";
import { delay } from "global/datahelper";
import { UseErrorContext } from "contexts";
import { getContacts } from "api/services/contact";
import Loader from "components/UI/Loader";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import Add from "components/UI/Icons/Add";

const Contacts = () => {
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState(null);
  const organizationId = UseOrganizationContext()?.id;  
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search, currentPage]);

  const refresh = async () => {   
    try{      
      setLoading(true);

      let contactsResponse = await getContacts(organizationId, {
          'page': currentPage,
          'search': search,
      });
    
      setContacts(contactsResponse.data); 

      // Pagination
      let contactsHeader = contactsResponse.headers; 
      
      let pagination = GetPagination(contactsHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <h1>CONTACTS</h1>
          <PageHeaderSub show>
            <Link to="contacts/add">
              <Button label="Nieuw contact" roles={["Contacts.Create"]}><Add/></Button>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="Alle contacten">
            {loading ? (
              <Loader center />
            ) : (
              <>
                <ContactGrid items={contacts}/>
                <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />                
                {contacts && contacts?.length === 0 && <i>Geen contacten gevonden</i>}
              </>
            )}
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default Contacts;
