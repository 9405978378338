import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Verplicht"),
  lastName: Yup.string().required("Verplicht"),
  email: Yup.string().email().required("Verplicht"),
});

export const ContactForm = ({ contact, save }) => {
  const history = useHistory();
  const { updateError } = UseErrorContext(); 

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          firstName: contact?.firstName ?? "",
          lastName: contact?.lastName ?? "",
          email: contact?.email ?? "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          save(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="firstName" className="required">
                  Voornaam 
                </label>
                <Field type="text" name="firstName" maxLength="50"/>
                <ErrorMessage className="error-message" name="firstName" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="lastName" className="required">
                  Achternaam
                </label>
                <Field type="text" name="lastName"/>
                <ErrorMessage className="error-message" name="lastName" component="div" />
              </div>
            </div> 
            <div className="form_row">
              <div className="field">
                <label htmlFor="email" className="required">
                  Email
                </label>
                <Field type="text" name="email"/>
                <ErrorMessage className="error-message" name="email" component="div" />
              </div>
            </div> 
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => history.push("/contacts")} type="secondary" />
                <Button onClick={handleSubmit} label="Opslaan" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
