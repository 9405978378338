import React, { useState } from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { ProductForm } from "pages/products/Components/ProductForm";
import { useHistory } from "react-router-dom";
import { UseErrorContext } from "contexts";
import Loader from "components/UI/Loader";
import { addProduct, uploadCover } from "api/services/product";
import { UseOrganizationContext } from "contexts";

const AddProduct = () => {
  const history = useHistory();
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const organizationId = UseOrganizationContext()?.id;

  const onSave = async (productData) => {
    try{
      setIsLoading(true);
      const rep = await addProduct(organizationId, productData);       
      if (productData.cover){
        await uploadCover(rep.id, productData.cover);
      } 
      setIsLoading(false);         
      history.push("/products");
      return rep;          
    } catch (err) {
      updateError(err);
    }    
    setIsLoading(false);        
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <PageHeaderSub>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Nieuw product">
          {isLoading 
            ? <Loader />
            : <ProductForm save={onSave}/>
          }
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default AddProduct;
