import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ValidateAccess } from 'global/authorization';
import { NavLink } from 'react-router-dom';
import { UseUserContext } from "contexts";

const Container = styled.div`
  .disabled {
    color: var(--color-medium-grey);
    & svg{
      path{
        fill: var(--color-medium-grey);
      }
    }
  }
`;

 const PrivateNavLink = ({ to, roles, icon: Icon, label }) => {
  const [hasAccess, setHasAccess] = useState([]);
  const { permissions } = UseUserContext();

  useEffect(async () => {   
    const access = await ValidateAccess(roles, permissions);
    setHasAccess(access);
  }, [permissions]);

    return (
        <Container>
            { hasAccess &&
              // <NavLink to={hasAccess ? to : "/unauthorized"}> 
              <NavLink to={to}> 
                {Icon && <Icon/>}
                {label}
              </NavLink>  
            }
        </Container>
    );
 };

 export default PrivateNavLink;