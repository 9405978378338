import React from "react";
import styled, {css} from "styled-components";
import ProductCard from "./ProductCard";
import Loader from "../../../components/UI/Loader";
import {device} from 'components/styles/GlobalStyle'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item {
    width: calc(100% / 3);
    padding: 1rem;
    ${({ direction }) =>
      direction == "column" &&
      css`
        width: calc(100% / 1);
        @media ${device.laptopL} {
          width: calc(100% / 2);
        }
      `}
  }
`;

const ProductGrid = ({ canEdit, products, refresh, direction}) => {
  return (
    <Container direction={direction}>
      {!products ? (
        <Loader center />
      ) : (
        products.map((item, idx) => (
          <div className="item" key={idx}>
            <ProductCard {...item}/>
          </div>
        ))
      )}
    </Container>
  );
};

ProductGrid.defaultProps = {
  items: Array.from(Array(3)),
  direction: 'row'
};

export default ProductGrid;
