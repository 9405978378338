import React from "react";
import { FaCreditCard } from "react-icons/fa";

export default function Voucher(props) {

  return (
    <>
      <div className="icon-button">
        <FaCreditCard/>
      </div>
    </>
  );
}
