import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import ProductGrid from "pages/products/Components/ProductGrid";
import Button from "components/UI/Buttons/Button";
import SearchBar from "../../components/UI/SearchBar";
import { UseOrganizationContext } from "contexts";
import { delay } from "global/datahelper";
import { UseErrorContext } from "contexts";
import { getProducts } from "api/services/product";
import Loader from "components/UI/Loader";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import Add from "components/UI/Icons/Add";

const Products = () => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);
  const organizationId = UseOrganizationContext()?.id;  
  const { updateError } = UseErrorContext();
  const [statusFilter, setStatusFilter] = useState("Actief");
  const [statusFilters, setStatusFilters] = useState(["Actief", "Inactief"]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search, currentPage, statusFilter]);

  const refresh = async () => {   
    try{      
      setLoading(true);

      const productsResponse = await getProducts(organizationId, {
        'page': currentPage,
        'search': search,
        'status': statusFilter
      });

      setProducts(productsResponse.data); 

      // Pagination
      let productsHeader = productsResponse.headers; 
      
      let pagination = GetPagination(productsHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };
  
  // Filters
  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };


  return (
    <>
      <Page>
        <div className="page__header">
          <h1>PRODUCTEN</h1>
          <PageHeaderSub show>
            <Link to="products/add">
              {products?.length >= 10 && <p><i>Limiet van 10 producten bereikt</i></p>}
              <Button label="Nieuw product" roles={["Products.Create"]} disabled={products?.length >= 10}><Add/></Button>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="description">
            <p>Producten worden getoond op de webshop onder uw persoonlijke pagina. Configureer producten met verschillende bedragen, zodat je later eenvoudig hiervan cadeaubonnen kan verkopen.</p>
          </div>
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="Alle producten">
            {loading ? (
              <Loader center />
            ) : (
              <>
                <div className="filters">
                  <div className="row">
                    <div>
                      <label>Status</label>
                      <select onChange={onChangeStatusFilter} value={statusFilter}>
                        {statusFilters.map((filter, idx) => (
                          <option key={idx}>{filter}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <ProductGrid canEdit products={products} refresh={refresh}/>
                <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />                
                {products && products?.length === 0 && <i>Geen producten gevonden</i>}
              </>
            )}
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default Products;
