import moment from "moment";

// moment(item.date).format("YYYY-MM-DD HH:mm")
export const FormatDate = (date) => {
    return date?.includes("T") ? date.split('T')[0] : date;
  };

export const FormatDateToString = (date) => {
    return moment(FormatDate(date)).format("DD-MM-YYYY");
  };

export const SubstractMonths = (date, numberOfMonths) => {
    return moment(date).subtract(numberOfMonths, 'M')
  };

export const AddMonths = (date, numberOfMonths) => {
    return moment(date).add(numberOfMonths, 'M')
  };

export const FormatDateTime = (date) => {
   return moment(date).format("YYYY-MM-DDTHH:mm");
  };

  export const FormatDateTimeToString = (date) => {
    return moment(date).format("DD-MM-YYYY HH:mm");
   };

export const FormatDateTimeOffset = (date, offset) => {
    return moment(date).utcOffset(offset).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
  };

export const GetPagination = (headers) => {   
  if (headers["x-pagination"] === undefined) 
    throw new Error("x-pagination not found in header")
  let pagination = JSON.parse(headers["x-pagination"]);
  return pagination;
  };

 // First group by (result is dictionary) then convert into array
 export const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

 // First group by (result is dictionary) then convert into array
 export const groupByToList = function(xs, key) {
    let groupByDict = groupBy(xs, key);

    let result = [];
    Object.keys(groupByDict).map(dictKey => {
      let obj = {key: dictKey, values: groupByDict[dictKey]};
      result.push(obj);
    });

    return result;
  };

  export const formatDescription = function(value, length=250) {
    return value.length > length ? value.substring(0,length-3) + "..." : value;
  };

  export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  export const ValidateEmail = function(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  export const Round = (number, decimals) => {
    return number?.toFixed(decimals) ?? 0;
  };

  export const isValidVat = function(vat) {
    const vatNumberRegex = /^(AT|BE|BG|HR|CY|CZ|DK|EE|FI|FR|DE|GR|HU|IE|IT|LV|LT|LU|MT|NL|PL|PT|RO|SK|SI|ES|SE)[0-9A-Za-z]{2,12}$/;
    return vatNumberRegex.test(vat);
  };

  export const getPrice = (voucher) => {
    return voucher.price == voucher.remainingPrice ? "€" + voucher.price.toString() : "€" + voucher.remainingPrice.toString() + " / " + "€" + voucher.price.toString();
  }
  export const generateRandomRGBAColors = (count, opacity) => {
    const randomColors = [];
  
    for (let i = 0; i < count; i++) {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const a = opacity;
  
      const rgbaColor = `rgba(${r},${g},${b},${a})`;
      randomColors.push(rgbaColor);
    }
  
    return randomColors;
  }