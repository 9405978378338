import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateTimeToString } from "global/datahelper";
import Status from "components/UI/Status";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  overflow: auto;
  table {
    td{
      max-width: 20rem;
    }
  }
`;

const OrganizationUserGrid = ({ organizationUsers }) => {
  const history = useHistory();

  return (
    <Container> 
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Voornaam</th>
            <th>Achternaam</th>
            <th>Email</th>
            <th>Telefoon</th>
          </tr>
        </thead>
        <tbody>
          {organizationUsers.map((organizationUser, idx) => (            
              <tr key={organizationUser.id + '_' + idx}>   
                <td></td>           
                <td>{organizationUser.firstName}</td>
                <td>{organizationUser.lastName}</td>
                <td>{organizationUser.email}</td>    
                <td>{organizationUser.phone}</td>             
              </tr>          
          ))}
        </tbody>
      </table>
    </Container>
  );
};

OrganizationUserGrid.defaultProps = {
  organizationUsers: Array.from(Array(0)),
};

export default OrganizationUserGrid;
