import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { deleteInvoice, getInvoice, printInvoice, updateInvoice } from "api/services/admin";
import PageHeaderSub from "components/PageHeaderSub";
import InvoiceInfo from "pages/admin/invoices/Components/InvoiceInfo";
import Status from "components/UI/Status";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import InvoiceLineGrid from "./Components/InvoiceLineGrid";
import MailInvoiceForm from "./Components/MailInvoiceForm";
import Print from "components/UI/Icons/Print";
import Delete from "components/UI/Icons/Delete";
import Confirm from "components/UI/Icons/Confirm";
import Send from "components/UI/Icons/Send";
import Pay from "components/UI/Icons/Pay";
import Shop from "components/UI/Icons/Shop";
import { Link } from "react-router-dom";
import BalanceLineGrid from "./Components/BalanceLineGrid";
import Add from "components/UI/Icons/Add";
import AddInvoiceLineForm from "./Components/AddInvoiceLineForm";

const Container = styled.div``;

const ViewInvoice = () => {
  const { id } = useParams();
  const history = useHistory();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalMail, setShowModalMail] = useState(false);
  const [showModalAddLine, setShowModalAddLine] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const invoiceData = await getInvoice(id);
      console.log(invoiceData)
      setInvoice(invoiceData);    
    } catch (err) {
      updateError(err);
    }
  };

  const _deleteInvoice = async () => {
    try{
      await deleteInvoice(id);
      setShowModalDelete(false);
      history.push("/admin/invoices")
    } catch (err) {
      updateError(err);
    }
  };

  const _confirmInvoice = async () => {
    await updateInvoice({...invoice, "status": "Bevestigd"});
    refresh();
  }  

  const _payInvoice = async () => {
    await updateInvoice({...invoice, "status": "Betaald"});
    refresh();
}  

  const _printInvoice = async () => {
    try{      
      setLoading(true);
      const result = await printInvoice(id);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };

  const open = (detailId) => {   
    history.push("/admin/invoices/" + id + "/" + detailId);
  };
  return (
    <>
      {showModalDelete && (
        <Modal title={`Verwijder factuur`} onClose={() => setShowModalDelete(false)} onSubmit={() => _deleteInvoice()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u deze factuur wil verwijderen?</div>
        </Modal>
      )}       
      {showModalMail && (
        <Modal title="Verstuur via e-mail" hideActions>
          <MailInvoiceForm invoice={invoice} onClose={async () => {setShowModalMail(false); await refresh();}}/>
        </Modal>
      )}  
      {showModalAddLine && (
        <Modal title="Factuurlijn toevoegen" hideActions>
          <AddInvoiceLineForm id={id} onClose={async () => {setShowModalAddLine(false); await refresh();}}/>
        </Modal>
      )}   
      <Page>
        {!invoice || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/admin/invoices"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/admin/invoices">Facturen</a>
                </li>
                <li>
                  <span className="selected">{invoice?.reference}</span>
                </li>
              </ul>
            </div>   
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>
                { invoice.orderId &&
                    <Link to={`/admin/orders/${invoice.order.id}`}>
                       <Button label="Order" type="secondary"><Shop/></Button>
                   </Link>
                }
                <Button label="Afdrukken" onClick={async () => _printInvoice()} type="secondary"><Print/></Button> 
                { invoice.status == "Nieuw" &&
                  <>
                    <Button label="Verwijderen" onClick={() => setShowModalDelete(true)} type="secondary"><Delete/></Button>
                    <Button label="Lijn toevoegen" onClick={() => setShowModalAddLine(true)} type="secondary"><Add/></Button>
                    <Button label="Bevestig" onClick={_confirmInvoice} ><Confirm/></Button>
                  </>
                }
                { invoice.status == "Bevestigd" &&
                    <Button label="Verstuur" onClick={async () => setShowModalMail(true)} ><Send/></Button>
                }
                { invoice.status == "Verzonden" &&
                  <>
                    <Button label="Verstuur opnieuw" onClick={async () => setShowModalMail(true)} type="secondary"><Send/></Button>
                    <Button label="Betaald" onClick={_payInvoice} ><Pay/></Button>
                  </>
                }
                { invoice.status == "Betaald" &&
                  <>
                    <Button label="Verstuur opnieuw" onClick={async () => setShowModalMail(true)} type="secondary"><Send/></Button>
                  </>
                }
                 <Status className={invoice.status} label={invoice.status}/>
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Factuurgegevens">  
              {!invoice || loading ? (
                <Loader center />
              ) : ( 
                <>           
                  <InvoiceInfo invoice={invoice}/>
                  <InvoiceLineGrid invoiceId={invoice.id} items={invoice.invoiceLines}/>
                  <BalanceLineGrid items={invoice.balanceLines} open={open}/>
                </>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewInvoice;
