import { getAxiosClient } from "../axios";


export const getInvoices = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/invoices`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getInvoice = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/invoices/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getBalanceLine = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/invoices/balancelines/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const printInvoice = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/invoices/${id}/print`, {
       responseType: 'blob', 
       headers: {
        Accept: 'application/pdf',
      }, 
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

