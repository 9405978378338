
import React from "react";
import styled from "styled-components";
import Loader from "components/UI/Loader";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  table{
    margin-top:2rem;
  }
`;

const ParticipantsGrid = ({ items }) => {
  return (
    <Container> 
     <label>Deelnemers</label>
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Naam</th>
            <th>Stad</th>
            <th>Categorie</th>
          </tr>
        </thead>
        <tbody>
              {items?.map((item, idx) => (          
                  <tr key={item.id + '_' + idx}> 
                    <td></td>              
                    <td>{item.name}</td>   
                    <td>{item.city}</td>   
                    <td>{item.categoryName}</td>           
                  </tr>      
              ))}
        </tbody>
      </table>
    </Container>
  );
};

export default ParticipantsGrid;
