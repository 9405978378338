import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "components/styles/GlobalStyle";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import MonthReport from "./Components/MonthReport";
import DayReport from "./Components/DayReport";
import YearReport from "./Components/YearReport";
import AdminDayReport from "pages/admin/dashboard/Components/DayReport";
import AdminMonthReport from "pages/admin/dashboard/Components/MonthReport";
import AdminYearReport from "pages/admin/dashboard/Components/YearReport";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import AdminOrganizationReport from "pages/admin/dashboard/Components/OrganizationReport";
import CampaignReport from "pages/dashboard/Components/CampaignReport";
import AdminProfitReport from "pages/admin/dashboard/Components/ProfitReport";
import ProfitReport from "./Components/ProfitReport";
import ProductReport from "./Components/ProductReport";
import { getProducts } from "api/services/product";
import enveloppes from "assets/enveloppe.png";

// TODO move styling?
const Container = styled.div`
  padding-bottom: 2rem;
  .dashboard_row {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 2rem;
    @media ${device.laptop} {
      display: flex;
      gap: 2rem;
    }
  }
  /* .page__header {
    position: relative;
    padding-top: 16%;
    padding-bottom: 2rem;  
    z-index: -2;
    background-image: linear-gradient(var(--color-accent-orange), var(--color-accent-purple));
    h1 {
      position: relative;
      z-index: 1;
      margin-left: 2rem;
      color: var(--color-text-light)
    }
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 0.4;
    }
  } */
`;

const Dashboard = () => {
  const { id, name, status, isTown, isCompany } = UseOrganizationContext();
  const { updateError } = UseErrorContext();
  const [period, setPeriod] = useState("month");
  const [products, setProducts] = useState([]);

  useEffect(async () => {
    await refresh();
  }, [id]);

  const refresh = async () => {
    try {
      if (id) {
        const productsData = await getProducts(id);
        setProducts(productsData.data);
        console.log(productsData)
      }
    } catch (err) {
      updateError(err);
    }
  };

  const onChangePeriod = async (e) => {
    const val = e.target.value.toString();
    setPeriod(val);
  };

  return (
    <Page fullWidth={true}>
      <div className="page__inner">
        <Container>
          <div className="page__header">
            {/* <h1>DASHBOARD</h1> */}
            <PageHeaderSub />
            {/* <div className="background-image" style={{ backgroundImage: "url('bg.jpg')" }}></div> */}
          </div>
          {/* <div className="dashboard_row">
            <HeaderDropDown titleHeader="Producten" link="/products">
              <ProductGrid canEdit={false} products={products} refresh={loadProducts} direction={"column"} />
            </HeaderDropDown>
          </div>  */}
          {
            name != "AllesBon"
              ?
              <>
                {products?.length === 0 &&
                  <div className="dashboard_row">
                    <HeaderDropDown titleHeader="Vervolledig je profiel">
                      <a href={`/products`}>
                        <p>Voeg nog producten toe aan je profiel! Zodra deze zijn toegevoegd wordt je persoonlijke pagina ook op de webshop gepubliceerd.</p>
                        <br></br>
                        <p>Ga naar producten</p>
                      </a>
                    </HeaderDropDown>
                  </div>
                }
                {status == "In behandeling" &&
                  <div className="dashboard_row">
                    <HeaderDropDown titleHeader="In behandeling">
                      <div>
                        <p>Uw profiel is momenteel in behandeling, wij nemen zo snel mogelijk contact met u op om dit te valideren en bevestigen.</p><br />
                        <p>In tussentijd kan u wel al het platform leren kennen en producten aanmaken.</p><br />
                        <p>Zodra uw profiel bevestigd is zullen deze producten op de webshop verschijnen en kan u starten met verkopen en inwisselen van cadeaubonnen.</p><br />
                        <p>Mocht u tussentijds nog vragen hebben aarzel dan niet om ons te <a style={{ fontSize: '1.3rem' }} href="/support">contacteren</a>!</p><br />
                        <p>Groeten,</p><br />
                        <p>Team AllesBon</p>
                      </div>
                    </HeaderDropDown>
                  </div>
                }
                {(!isTown && !isCompany) ?
                  <>
                    <div className="dashboard_row">
                      <HeaderDropDown titleHeader="Hartelijk welkom bij AllesBon!">
                        <p>We zijn verheugd u te verwelkomen op ons online portaal voor het aankopen, inwisselen en beheren van cadeaubonnen. Of u nu een trouwe bezoeker bent of voor de eerste keer langskomt, we hopen dat u hier een inspirerende en informatieve ervaring zult hebben.</p><br></br>
                        <p>Ons platform is bedoeld om u te voorzien van administratieve ondersteuning, waardevolle campagnes, real-time rapportering en een directe link met deelnemende ondernemers.</p><br></br>
                        <p>Als u vragen heeft, suggesties wilt delen of gewoon hallo wilt zeggen, aarzel dan niet om contact met ons op te nemen. We staan altijd open voor feedback en streven ernaar om u ervaring op ons platform continue te verbeteren.</p><br></br>
                        <p>Met vriendelijke groeten,</p><br></br>
                        <p>Team AllesBon</p>
                      </HeaderDropDown>
                      <HeaderDropDown titleHeader="⭐️Nieuw: Bestel vanaf nu je enveloppes hier⭐️">
                        <p>Gebruik onze hoogwaardige enveloppes met satijnen lint om je cadeaubonnen te verpakken en net dat tikkeltje meer te geven. Beschikbaar in verschillende kleuren en onmiddelijk leverbaar!</p>
                        <br></br>
                        <a style={{ fontSize: '1.3rem' }} href="/enveloppes"><p><b><u>Bestel hier</u></b></p></a>
                        <br></br>
                        <img className="image" src={enveloppes} alt="" width="500"/>
                      </HeaderDropDown>
                    </div>
                    <div className="dashboard_row">
                      <HeaderDropDown titleHeader="Vandaag">
                        <DayReport />
                      </HeaderDropDown>
                      <HeaderDropDown titleHeader="Omzet">
                        <ProfitReport />
                      </HeaderDropDown>
                    </div>
                    <div className="dashboard_row">
                      <HeaderDropDown titleHeader="Winkel vs webshop">
                        <select onChange={onChangePeriod} value={period}>
                          <option value="month">Maandoverzicht</option>
                          <option value="year">Jaaroverzicht</option>
                        </select>
                        {period == "month" && <MonthReport />}
                        {period == "year" && <YearReport />}
                      </HeaderDropDown>
                      <HeaderDropDown titleHeader="Meest verkochte producten">
                        <ProductReport />
                      </HeaderDropDown>
                    </div>
                  </>
                  :
                  <>
                    <div className="dashboard_row">
                      <HeaderDropDown titleHeader="Hartelijk welkom bij AllesBon!">
                        <p>We zijn verheugd u te verwelkomen op ons online portaal voor het aankopen, inwisselen en beheren van cadeaubonnen. Of u nu een trouwe bezoeker bent of voor de eerste keer langskomt, we hopen dat u hier een inspirerende en informatieve ervaring zult hebben.</p><br></br>
                        <p>Ons platform is bedoeld om u te voorzien van administratieve ondersteuning, waardevolle campagnes, real-time rapportering en een directe link met deelnemende ondernemers.</p><br></br>
                        <p>Als u vragen heeft, suggesties wilt delen of gewoon hallo wilt zeggen, aarzel dan niet om contact met ons op te nemen. We staan altijd open voor feedback en streven ernaar om u ervaring op ons platform continue te verbeteren.</p><br></br>
                        <p>Met vriendelijke groeten,</p><br></br>
                        <p>Team AllesBon</p>
                      </HeaderDropDown>
                      <HeaderDropDown titleHeader="Laaste campagne">
                        <CampaignReport />
                      </HeaderDropDown>
                    </div>
                  </>
                }
              </>
              :
              <>
                <div className="dashboard_row">
                  <HeaderDropDown titleHeader="Omzet">
                    <AdminProfitReport />
                  </HeaderDropDown>
                  <HeaderDropDown titleHeader="Vandaag">
                    <AdminDayReport />
                  </HeaderDropDown>
                </div>
                <div className="dashboard_row">
                  <HeaderDropDown titleHeader="Deze maand">
                    <AdminMonthReport />
                  </HeaderDropDown>
                  <HeaderDropDown titleHeader="Dit jaar">
                    <AdminYearReport />
                  </HeaderDropDown>
                </div>
                <div className="dashboard_row">
                  <HeaderDropDown titleHeader="Organisaties">
                    <AdminOrganizationReport />
                  </HeaderDropDown>
                </div>
              </>
          }
        </Container>
      </div>
    </Page>
  );
};

export default Dashboard;
