import React from "react";
import { FaCheck } from "react-icons/fa";

export default function Confirm(props) {

  return (
    <>
      <div className="icon-button">
        <FaCheck/>
      </div>
    </>
  );
}
