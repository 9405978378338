import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import Add from "components/UI/Icons/Add";
import Trash from "components/UI/Icons/Trash";
import InviteParticipantsBox from "./InviteParticipantsBox";
import Loader from "components/UI/Loader";
import { ValidateEmail } from "global/datahelper";

const Container = styled.div`
  .footer {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    button {
      margin-left: 2rem;
    }
  }
`;

const InviteParticipantsForm = ({ onClose, callback }) => {
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [invites, setInvites] = useState([{'id': 1, 'name': "", 'email': "", 'status': "Te versturen"}]);
 
  const _send = async () => {  
    var err = false; 
    try{        
      setLoading(true);
      invites.map((inv, idx) => { 
        if (!inv.name && !inv.email){
          updateError({"message": "Gelieve een naam en e-mailadres in te vullen."});
          err = true;
        }
        else if (!inv.name){
          updateError({"message": "Gelieve een naam in te vullen."});
          err = true;
        }
        else if (!inv.email){
          updateError({"message": "Gelieve een e-mailadres in te vullen."});
          err = true;
        }   
        else if (ValidateEmail(inv.email)){
          updateError({"message": "Ongeldig e-mailadres."});
          err = true;
        }
      })
      if (!err){
        callback(invites);  
        onClose();
      }
    } catch (err) {
      updateError(err);
    }
    finally {
      setLoading(false);
    }
  };

  const _add = async () => {  
    const id = invites[invites.length-1].id + 1;
    const newObj = {'id': id, 'name': "", 'email': "", 'status': "Te versturen"};
    setInvites([...invites, newObj]);
  };

  const _delete = async (id) => {       
    setInvites(invites.filter(x => x.id !== id))        
  };

  const _update = async (obj) => {
    if (!obj?.id) return;
    let inv = invites;
    if (inv.filter(x => x.id === obj.id)){
      let upd_obj = inv.findIndex((x => x.id == obj.id));
      inv[upd_obj] = {...obj};
      setInvites(inv);
    }
  };

  return (
    <Container>
      {loading 
        ? <Loader/>
        :
        <>
          <div className="form">
              <div className="form_row">
                <Button label="Toevoegen" onClick={() => _add()}><Add/></Button>
              </div>
              {invites.map((invite, idx) => (
                <div className="form_row" key={idx}>
                    <InviteParticipantsBox {...invite} callback={_update}/>
                    {invites.length > 1 && invites.length == idx+1 &&
                      <div onClick={() => _delete(invite.id)} className="icon-button">
                        <Trash />
                      </div>
                    }
                </div> 
              ))}
            </div>
          <div className="footer">
            <Button type="secondary" label="Sluiten" onClick={() => onClose()} />
            <Button label="Bevestig" onClick={() => _send()} /> 
          </div> 
        </>
      }
    </Container>
  );
};

export default InviteParticipantsForm;
