import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { BaDoughnutr, Doughnut } from 'react-chartjs-2';
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getDayReport } from "api/services/admin";
import { FormatDateTime, Round } from "global/datahelper";
import Loader from "components/UI/Loader";

ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  table{
    margin: 2rem 0;
  }
`;

const AdminDayReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [chartData, setChartData] = useState({datasets: [],});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);
        let date = Date.now();
        const reportData = await getDayReport({
          date: FormatDateTime(date)
        });  
        setData(reportData); 
      
        setChartData({
          labels: [
            'Verkocht winkel (€)', 
            'Verkocht online (€)', 
            'Bedrijfsbonnen (€)', 
            'Gemeentebonnen (€)', 
            'Ingewisselde cadeaubonnen (€)',
            'Ingewisselde bedrijfsbonnen (€)',
            'Ingewisselde gemeentebonnen (€)',
            'Vervallen (€)'],
          datasets: [
            {
              label: '',
              data: [
                reportData.storeCreatedVouchersTotalPrice, 
                reportData.onlineCreatedVouchersTotalPrice, 
                reportData.createdVouchersCompanyTotalPrice, 
                reportData.createdVouchersTownTotalPrice, 
                reportData.redeemedVouchersDefaultTotalPrice, 
                reportData.redeemedVouchersCompanyTotalPrice, 
                reportData.redeemedVouchersTownTotalPrice, 
                reportData.expiredVouchersTotalPrice],
              backgroundColor: [
                'rgba(75, 150, 100, 0.2)',
                'rgba(150, 250, 130, 0.2)',
                'rgba(75, 230, 120, 0.2)',
                'rgba(200, 235, 75, 0.2)',
                'rgba(75, 220, 235, 0.2)',
                'rgba(75, 175, 235, 0.2)',
                'rgba(75, 120, 235, 0.2)',
                'rgba(233, 15, 15, 0.2)'
              ],
              borderColor: [
                'rgba(75, 150, 100, 1)',
                'rgba(150, 250, 130, 1)',
                'rgba(75, 230, 120, 1)',
                'rgba(200, 235, 75, 1)',
                'rgba(75, 220, 235, 1)',
                'rgba(75, 175, 235, 1)',
                'rgba(75, 120, 235, 1)',
                'rgba(233, 15, 15, 1)'
              ],
              borderWidth: 1,
              }
            ]
          }); 

          setIsLoading(false); 
      } catch (err) {
        updateError(err);
      }  
    }      
  }, [organizationId])

  return (
    <>
      {isLoading 
        ? <Loader />
        : <Container> 
        <table cellSpacing="500">
          <thead>
            <tr>            
              <th>Verkocht: </th>
              <th>Winkel</th>
              <th>Webshop</th>
              <th>Bedrijfsbon</th>
              <th>Gemeentebon</th>
            </tr>
          </thead>
          <tbody>
            <tr>   
              <td></td>    
              <td>€ {Round(data.storeCreatedVouchersTotalPrice, 2)}</td>  
              <td>€ {Round(data.onlineCreatedVouchersTotalPrice, 2)}</td>  
              <td>€ {Round(data.createdVouchersCompanyTotalPrice, 2)}</td>  
              <td>€ {Round(data.createdVouchersTownTotalPrice, 2)}</td>              
            </tr>   
          </tbody>
        </table>
        <table cellSpacing="500">
          <thead>
            <tr>            
              <th>Ingewisseld: </th>
              <th>Cadeaubon</th>
              <th>Bedrijfsbon</th>
              <th>Gemeentebon</th>
              <th>Vervallen</th>
            </tr>
          </thead>
          <tbody>
            <tr>   
              <td></td>        
              <td>€ {Round(data.redeemedVouchersDefaultTotalPrice, 2)}</td>  
              <td>€ {Round(data.redeemedVouchersCompanyTotalPrice, 2)}</td>  
              <td>€ {Round(data.redeemedVouchersTownTotalPrice, 2)}</td>  
              <td>€ {Round(data.expiredVouchersTotalPrice, 2)}</td>            
            </tr>   
          </tbody>
        </table>
        <Doughnut data={chartData} />
      </Container>
      }
    </>    
  );
};

export default AdminDayReport;
