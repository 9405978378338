import React from "react";
import { FaFileInvoiceDollar } from "react-icons/fa";

export default function Invoice(props) {

  return (
    <>
      <div className="icon-button">
        <FaFileInvoiceDollar/>
      </div>
    </>
  );
}
