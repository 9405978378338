import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { BaDoughnutr, Doughnut } from 'react-chartjs-2';
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import Loader from "components/UI/Loader";
import { getCampaignReport } from "api/services/report";

ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  table{
    margin: 2rem 0;
  }
`;

const CampaignReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [chartData, setChartData] = useState({datasets: [],});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);
        const reportData = await getCampaignReport(organizationId); 
        console.log(reportData) 
        setData(reportData); 
      
        setChartData({
          labels: [
            '# Gebruikt', 
            '# Niet gebruikt'],
          datasets: [
            {
              label: '',
              data: [
                reportData.used, 
                reportData.notUsed],
              backgroundColor: [
                'rgba(75, 150, 100, 0.2)',
                'rgba(233, 15, 15, 0.2)'
              ],
              borderColor: [
                'rgba(75, 150, 100, 1)',
                'rgba(233, 15, 15, 1)'
              ],
              borderWidth: 1,
              }
            ]
          }); 

          setIsLoading(false); 
      } catch (err) {
        updateError(err);
      }  
    }      
  }, [organizationId])

  return (
    <>
      {isLoading 
        ? <Loader />
        : <Container> 
        <p><a href={"/https://app.allesbon.be/campaigns/view/" + data.campaignId}>{data.campaignName}</a></p>
        <Doughnut data={chartData}/>
      </Container>
      }
    </>    
  );
};

export default CampaignReport;
