import React, { useState, useEffect } from "react";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { delay } from "global/datahelper";
import SearchBar from "components/UI/SearchBar";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import { getOrders } from "api/services/admin";
import OrderGrid from "./Components/OrderGrid";
import Confirm from "components/UI/Icons/Confirm";

const AdminOrders = () => {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState([]);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);

  // Init
  useEffect(async () => {
    refresh();
  }, [ search, currentPage]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);

      // GET endpoint
      var params = {
        'page': currentPage,
        'search': search,
        'fromDate': fromDateFilter,
        'toDate': toDateFilter
      }
      const ordersResponse = await getOrders(params);
      
      // Set data
      let ordersData = ordersResponse.data;
      setOrders(ordersData);

      // Pagination
      let ordersHeader = ordersResponse.headers; 
      
      let pagination = GetPagination(ordersHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  const onChangeFromDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  return (
    <Page>
      <div className="page__header">
        <h1>Orders</h1>
        <PageHeaderSub>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch} />
        </div>
        <HeaderDropDown titleHeader=" ">
          {!orders || loading ? (
            <Loader center />
          ) : (
            <>
              <div className="filters">
                <div className="row">
                  <div>
                    <label>Aanmaakdatum (van)</label>
                    <input type="date" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>Aanmaakdatum (tot)</label>
                    <input type="date" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                  <div onClick={refresh} ><Confirm/></div>
                </div>
              </div>
              <OrderGrid canEdit orders={orders} refresh={refresh} />
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default AdminOrders;
