import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
// KERST
import logo from '../assets/logov4.png';
//import logo from '../assets/logoKerst.png';
import { UseOrganizationContext, UseUserContext } from "../contexts";
import { getOrganizationUser, getUser, addUser } from "api/services/user";
import Navigation from "./Navigation";
import LoaderContainer from "./UI/Loader";
import NavigationAdmin from "./NavigationAdmin";
import AddProfile from "pages/profile/add-profile";
import { UseErrorContext } from "contexts";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionIcon from '@mui/icons-material/Mail';
import PowerOffIcon from '@mui/icons-material/Logout';
import ManualIcon from '@mui/icons-material/QuestionMark';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Link, useHistory } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import ListItemText from '@mui/material/ListItemText';
import Navigation2 from "./Navigation2";
import NavigationPersonnel from "./NavigationPersonnel";

const Container = styled.div`
  .appBar {
    //background-color: var(--color-accent-purple);
    background-image: linear-gradient(var(--color-accent-purple), var(--color-accent-orange));
    background-color: transparent;
    padding-bottom: .4rem;
    padding-top: .4rem;
    //box-shadow: 5px 6px 12px -5px var(--color-accent-purple);
    z-index: 9999;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    & svg{
      font-size: 20px;
    }
    & > .MuiToolbar-root{
      justify-content: space-between;
    }
  }

  .logo{
    //padding: 4px;
  }

  .menuLeft{
    display: flex;
  }
`;

const LoadOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-prim);
`;

const drawerWidth = 260;

const Layout = ({ children }) => {
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const account = useAccount(accounts?.[0] || {});
  const { name, isCompany, isTown } = UseOrganizationContext();
  const { updateOrganizationData } = UseOrganizationContext();
  const { updateUserData, updateUserName } = UseUserContext();
  const [organizationUser, setOrganizationUser] = useState();  
  const [loading, setLoading] = useState(true);
  const { updateError } = UseErrorContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [personnelMode, setPersonnelMode] = useState(false);

  // init
  useEffect(async () => {
    const personnel = JSON.parse(localStorage.getItem('personnel'));
    setPersonnelMode(personnel);
  }, []);

  useEffect(async () => {
    document.title = "AllesBon | " + (name || "Loading");
  }, [name]);

  useEffect(async () => { 
    try{   
      setLoading(true);
      await getOrCreateUser();
      if (account) {
        var orgUser = await getOrganizationUser();
        setOrganizationUser(orgUser);
        if (orgUser)
        {
          await updateOrganizationData(orgUser.organizationId);
          await updateUserData(orgUser.permissions);   
          await updateUserName(orgUser.email);
        } 
      }    
      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  }, [accounts]);

  
  const getOrCreateUser = async () => {
    const user = await getUser();
    if (!user){
      const us = await addUser();
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const LogoutHandler = () => {
    try {
      instance.logout();
    } catch (err) {
      console.log(err);
    }
  };

  const drawer = (
      <div className="nav">
        <Toolbar />
        { organizationUser && 
          <>
              {
                 name == "AllesBon"
                    ? <NavigationAdmin/>
                    : isCompany || isTown
                    ? <Navigation2 /> 
                    : personnelMode
                    ? <NavigationPersonnel/>
                    : <Navigation/>
               }  
          </> 
        } 
      </div>
  );

  return (
    <Container>
      {loading && (
        <LoadOverlay>
          <LoaderContainer />
        </LoadOverlay>
      )}
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          className="appBar"
          sx={{
            //width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}>
          <Toolbar>
            <div className="menuLeft">
              <IconButton
                color="inherit"
                aria-label="open menu"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}>
                <MenuIcon />
              </IconButton>
              <Typography component={Link} onClick={() => {history.push("/"); window.location.reload();}} variant="h6" className="title" color="inherit">
                <img src={logo} alt="AllesBon" height="50px" className="logo" />
              </Typography>
            </div>
            <div className="menuRight">
              <IconButton component={Link} to="/user" aria-label="Settings" color="inherit" size="large">
                <SettingsIcon />
              </IconButton>  
              <IconButton component={Link} to={{ pathname: "https://stallesbon.blob.core.windows.net/public/Gebruikershandleiding.pdf" }} target="_blank" aria-label="Manual" color="inherit" size="large">
                <ManualIcon />
              </IconButton>  
              <IconButton component={Link} to="/support" aria-label="Support" color="inherit" size="large">
                <QuestionIcon/>
              </IconButton>  
              <IconButton component={Link} onClick={() => LogoutHandler()} aria-label="Logout" color="inherit" size="large">
                <PowerOffIcon/>
              </IconButton>  
            </div>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="navigation">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{keepMounted: true, // Better open performance on mobile.
            }}
            sx={{display: { xs: 'block', sm: 'none' },'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth},}}>
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },}}
            open>
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar />
          {/* Main content */}
          { organizationUser 
            ? <> {children} </>      
            : <AddProfile/>
          }  
        </Box>
      </Box>
    </Container>
  );
}

export default Layout;
