import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import { UseErrorContext } from "contexts";
import { generateCampaignPayment, getCampaignPayments,  } from "api/services/campaign";
import Loader from "components/UI/Loader";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import Add from "components/UI/Icons/Add";
import CampaignPaymentGrid from "./Components/CampaignPaymentGrid";
import { BackButton } from "components/UI/Buttons/BackButton";
import { useParams } from "react-router-dom";
import { Refresh } from "@mui/icons-material";

const CampaignPayments = () => {
  const { id } = useParams();
  const [payments, setPayments] = useState([]);
  const [newPayments, setNewPayments] = useState([]);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    refresh();
  }, []);

  const refresh = async () => {   
    try{      
      setLoading(true);

      let resp = await getCampaignPayments(id, {
          'page': currentPage
      });
      console.log(resp)

      setPayments(resp.data); 

      // Pagination
      let header = resp.headers; 
      
      let pagination = GetPagination(header);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  const _generate = async () => {

    try{      
      setLoading(true);
      let newPayments = await generateCampaignPayment(id);
      setNewPayments(newPayments);
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <>
      <Page>
        <div className="navigation-banner">              
              <BackButton link={`/campaigns/view/${id}`}/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/campaigns">Campagnes</a>
                </li>
                <li>
                  <a href={`/campaigns/view/${id}`}>{id}</a>
                </li>
                <li>
                  <span className="selected">Betalingen</span>
                </li>
              </ul>
            </div>   
        <div className="page__header">
          <h1></h1>
          <PageHeaderSub show>
              <Button onClick={async () => _generate()} label="Genereer betalingen" roles={["Campaigns.Create"]}><Refresh/></Button>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          {newPayments?.length > 0 &&
            <HeaderDropDown titleHeader="Nieuwe betalingen">
              {loading ? (
                <Loader center />
                ) : (
                <>
                  <CampaignPaymentGrid items={newPayments}/>
                  <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />      
                </>
              )}
            </HeaderDropDown>
          }
          {payments?.length > 0 &&
            <HeaderDropDown titleHeader="Alle betalingen">
              {loading ? (
                <Loader center />
                ) : (
                <>
                  <CampaignPaymentGrid items={payments}/>
                  <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />    
                </>
              )}
            </HeaderDropDown>
          }
        </div>
      </Page>
    </>
  );
};

export default CampaignPayments;
