import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "components/UI/Images/Image";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const ProductInfo = ({ product }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Weergave naam</label>
            <input value={product.displayName} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Afbeelding</label>
            <div className="cover-image">
              <Image url={product.cover} disabled/> 
            </div>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Subtitel</label>
            <input value={product.name} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Beschrijving</label>
            <textarea value={product.description} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label htmlFor="hidePrice">
              Variabele prijs
            </label>
            <SwitchButtonOn
              on={product.variablePrice}
              disabled
            />
         </div>
      </div> 
        {!product.variablePrice &&
          <div className="form_row">
            <div className="field">
              <label>Prijs in (€)</label>
              <input value={product.price} disabled/>
            </div>
          </div> 
        }
        <div className="form_row">
          <div className="field">
            <label htmlFor="hidePrice">
              Prijs verbergen
            </label>
            <SwitchButtonOn
              on={product.hidePrice}
              disabled
            />
         </div>
      </div> 
      </div>
    </Container>
  );
};

export default ProductInfo;
