import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

export const PersonnelForm = ({ }) => {
  const [personnelMode, setPersonnelMode] = useState(false);

  // init
  useEffect(async () => {
    const personnel = JSON.parse(localStorage.getItem('personnel'));
    setPersonnelMode(personnel);
  }, []);

  const updateMode = (val) => {
    setPersonnelMode(val);
    localStorage.setItem('personnel', JSON.stringify(val));
    window.location.reload(); 
  };

  return (
        <div className="form">
          <div className="form_row">
            <div className="field">
              <label htmlFor="active">
                Activeren
              </label>
              <SwitchButtonOn
                  on={personnelMode}
                  onSwitchOn={e => { updateMode(true)}}
                  onSwitchOff={e => { updateMode(false)}}
              />
            </div>
          </div>
        </div>
  );
};
