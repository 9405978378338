import React from "react";
import styled from "styled-components";
import Button from "../Buttons/Button";
import Loader from "../Loader";

const ModalContainer = styled.div`
  overflow-y: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  z-index: 10;
  .modal__content {
    border-radius: 0.8rem;
    width: 65rem;
    padding: 2rem;
    background: var(--color-background-prim);
    max-height: 80%;
    overflow: auto;
    position: absolute;
    margin: auto;
    margin-left: 300px;
    margin-top: 80px;
    .modal__body {
      margin: 2rem 0;
    }
  }
  .modal__content.wide {
    width: 130rem;
  }
  .modal__footer {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    button {
      margin-left: 2rem;
    }
  }
`;

export default function Modal({ show, title, children, onClose, onSubmit, cancelLabel, submitLabel, loading, hideActions, showClose, wide }) {
  if (!show) {
    return null;
  }
  return (
    <ModalContainer>
      <div className={"modal__content" + (wide ? " wide" : "")}>
        <div className="modal__header">
          <h2>{title}</h2>
        </div>
        <div className="modal__body">{children}</div>
        {!hideActions &&  
            <div className="modal__footer">
              {loading ? (
                <Loader center />
              ) : (
                <>
                  <Button type="secondary" label={cancelLabel} onClick={() => onClose()} />
                  {onSubmit && <Button label={submitLabel} onClick={() => onSubmit()} />}
                </>
              )}
            </div>
        }
        {hideActions && showClose &&
          <div className="modal__footer">
            <Button label="Sluit" onClick={() => onClose()} />
          </div>
        }
      </div>
    </ModalContainer>
  );
}

Modal.defaultProps = {
  show: true,
  title: "Modal title",
  onClose: () => console.log("close"),
  onSubmit: () => console.log("submit"),
  cancelLabel: "Annuleer",
  submitLabel: "Bevestig",
  loading: false,
};
