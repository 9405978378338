import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  input {
    border-radius: 0.8rem;
    background-color: var(--color-light-grey);
    width: 100%;
    padding: 0.8rem;
    color: var(--color-text-sec);
  }
`;

export default function SearchBar({ value, placeholder, onChange }) {
  return (
    <Container>
      <input onChange={onChange} value={value} placeholder={placeholder} />
    </Container>
  );
}

SearchBar.defaultProps = {
  value: null,
  placeholder: "Zoeken...",
  onChange: (e) => console.log("searchbar onchange", e.target.value)
};
