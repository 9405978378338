import React from "react";
import {  FaRedo } from "react-icons/fa";

export default function Redo(props) {

  return (
    <>
      <div className="icon-button">
        <FaRedo/>
      </div>
    </>
  );
}
