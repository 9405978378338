import { createGlobalStyle } from "styled-components";
import reset from "reset-css";

// Font awesome icons at:
// https://react-icons.github.io/react-icons/icons?name=fa

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1200px",
  laptopL: "1440px",
  desktop: "2560px",
};
   
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default createGlobalStyle`
  ${reset}

  
html {
    --color-background-prim: #f5f5f5;
    --color-background-white: #ffffff;
    --color-background-grey: #f5f5f5;
    --color-accent-prim: #D094D8; // purple
    --color-accent-sec: #F0A98B; // orange
    --color-accent-purple: #D094D8;
    --color-accent-orange: #F0A98B;
    --color-accent-pink: #FCF5F2;
    --color-accent-orange-light: #fac9b4;
    --color-text: #000000;
    --color-text-light: #ffffff;
    --color-text-medium: #C5C2C1;
    --color-black: #000000;
    --color-light-grey: #f2f2f2;
    --color-medium-grey: #e5e5e5;
    --color-dark-grey: #a7a7a7;
    --color-status-new: #3A3A3A;
    --color-status-good: #1bb775;
    --color-status-info: #2986cc;
    --color-status-bad: #d62809;
    --color-status-warning: #d98a02;
    font-size: 62.5%;
    }

:root {
    position: relative;
    min-height: 100vh;
    width:100vw;
    margin:auto;
    font-size: 62.5%;
    color: var(--color-text);
    font-family: 'Nunito', sans-serif;}

html, *{box-sizing: border-box;}
*,*:before,*:after {box-sizing: inherit;}
::-webkit-scrollbar {
    /*display: none;*/
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: lightgray;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

body{  
  background-color: var(--color-background-prim);
  color: var(--color-text);
  font-family: 'Nunito', sans-serif;
  text-align: left;
  font-size: 1.4rem;
  overflow-x: hidden;
}

/*-------main_style-------*/
p{
  line-height: 2rem;
}

a{
  text-decoration: none;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  color: var(--color-text);
    :hover{
    color: var(--color-accent-prim);
    opacity: .7;
    }
}

b{
  font-weight: 800;
}

i{
  font-style: italic;
}

button{
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  border-radius:100px;
  font-size: 2.4rem;
  border:var(--color-accent-prim) solid 1px;
  background-color: var(--color-background-sec);
  color: var(--color-accent-prim);
  -webkit-box-shadow: var(--color-accent-prim);
  box-shadow: var(--color-accent-prim);
  cursor: pointer;
  outline: none;
}

table {
  width:100%;
  color: var(--color-text);
  font-family: 'Nunito', sans-serif;
  th{
    border-bottom: 1px solid var(--color-dark-grey);
    opacity: 1;
    position: relative;
    padding-bottom: 1rem;
  }
  tr {
    vertical-align: middle;
    &:last-of-type{
      td{
        border-bottom: none;
      }
    }
  }
  td {
    opacity: .8;
    padding: 1rem 1rem 1rem 0;
    vertical-align: middle;
    &:not(:first-of-type){
      border-bottom: 1px solid var(--color-light-grey);
    }
  }
  &.table{
    td{
      border-bottom: 1px solid var(--color-light-grey);
    }
  }
}

input[type="file"] {
  cursor: pointer;
}

svg{
  cursor: pointer;
}

/*-------Headers-------*/
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

h2 {
  font-weight: 500;
  font-size: 1.8rem;
  font-family: 'Montserrat', sans-serif;
  a {    
    font-size: 1.8rem;
  }
}

h3 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h4 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h5 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h6 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

/*-------Form_elements-------*/
input, textarea{
  border:none;
    background-image:none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    &:focus {
      outline: none;
    }  
}

input{
  padding: .4rem;
  color: var(--color-text);
  border-bottom: 1px solid var(--color-dark-grey);
  font-size: 1.8rem;
}

input:focus {
    outline: none !important;
    border-bottom: 1px solid var(--color-accent-prim);
    box-shadow: 0 5px 10px -7px var(--color-accent-prim);
  }

  input[type='checkbox'] {
    accent-color: var(--color-accent-prim);
}

textarea{
  border-radius: .4rem;
  background-color: rgba(0, 0, 0, 0.05);
  padding: .8rem;
}

textarea:focus {
    outline: none !important;
    border: 1px solid var(--color-accent-prim);
    box-shadow: 0 5px 10px -7px var(--color-accent-prim);
  }

select{
  width: 100%;
  padding: .4rem;
  border-radius: .4rem;
  background-color: rgba(0, 0, 0, 0.05);
  padding: .8rem;
  font-size: 1.6rem;  
  font-family: 'Montserrat', sans-serif;
}
option {
  font-size: 1.6rem;
  background-color: #ffffff;
}
.required:after {
    content:" *";
    color: var(--color-accent-prim);
  }

/*-------Nav_style-------*/

/*-------Other-------*/
.label {
  color: var(--color-light-grey);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

label {
  display: block;
  margin-bottom: 0.4rem;
  font-weight: bold;
}

.card{
  border-radius: 0.8rem;
  background-color: white;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  &:hover{
    opacity: .7;
  }
  a:hover{
    opacity: 1;
    color: black;
  }
    &__title{
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.8rem;
    }
    &__subtitle {
      color: var(--color-dark-grey);
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.2rem;
      display: flex;
      svg{
        margin-left: .2rem;
      }
      path{
        fill: var(--color-dark-grey);
      }
    }
  }
  .card_image__container {
    position: relative;
    width: 100%;
    padding-top: 52.6%;
    overflow: hidden;
    border-radius: 0.8rem 0.8rem 0 0;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: auto;
      object-fit: cover;
    }
  }
  
  .edit-button {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    cursor: pointer;
  }
  .delete-button {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer;
    z-index: 1;
  }

  .card__content {
    padding: 1.2rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid rgba(0,0,0,0.3);
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__body {
      margin: 1.2rem 0;
      font-weight: 300;
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.4rem;
      font-family: "Montserrat";
    }
  }

  .switch-container{
    display: flex;
  }
  .switch-item {
    margin: 1.4rem;
    &-column{
      flex-direction: column;
    }
  }
  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
 
  .cover-image {   
    height: 20rem;
    width: 37rem;
    position: relative;
    border-radius: 0.8rem;  
  }
  
  .logo-image {   
    height: 20rem;
    width: 20rem;
    position: relative;
    border-radius: 0.8rem;  
  }

  .page__header__sub {
    display: flex;
    > * {
      margin-left: 1rem;
    }
  }

  .grid-add-btn {
    display: flex;
    align-items: center;
    margin: .8rem 0;
  }
  
  .thumbnail{
    padding: 6rem;
  }
  
  .react-selectcomponent{
    color: var(--color-black);
  }

  .form{
    input {
      width: 100%;
    }
    textarea {
      width: 100%;
      height: 10rem;
    }
    .form_row {
      margin-bottom: 2rem;
      display: flex;
      width: 100%;
      .field {
        width: 100%;
        &:not(:first-of-type) {
          margin-left: 2.4rem;
        }
      }
    }
    .error-message {
      opacity: 0.6;
      color: red;
      padding: 1rem 0;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-right: 1rem;
      }
    }
  }

  .icon-button{
    cursor: pointer;  
    display: flex;
    align-items: center;
    color: var(--color-accent-prim);
    font-size: 1.8rem;
    label{
      cursor: pointer;  
      margin-left: .4rem;
      color: var(--color-text);
    }
    :hover{
      & label{
        color: var(--color-accent-prim);
      }
      & svg{
        path{
          opacity: .7;
        }
      }
    }
  }

  .navigation-banner{
    display: grid;
    grid-template-columns: auto auto;
    align-items: baseline;
  }

  .back{
    :hover{
      opacity: .7;
      color: var(--color-accent-prim);
      & svg{
        path{
          fill: var(--color-accent-prim);
        }
      }
    }
  }

  .breadcrumbs{
    margin-bottom: 2rem;
    display: flex;
    justify-content: end;
    li{       
      display: inline-block;
      :not(:last-child):after {
        content: " >";
      }
      margin-right: .4rem;
    }
    .selected{
      opacity: .7;
      font-style: italic;      
    }
  }

  .searchBarContainer {
    margin: 2rem 0;
    overflow: auto;
    table {
      width:100%;
      min-width: 100rem;
    }
  }

  .switch-group {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1.2rem;
  }
  
  .switch-item {
    p {
      padding-bottom: 0.4rem;
      font-weight: normal;
      font-size: 1.4rem;
      min-height: 3.3rem;
    }
  }

  .switch-group-full-page {
    display: flex;
    margin-bottom: 1.2rem;
  }
  
  .switch-item-full-page {
    width: 100%;
    p {
      padding-bottom: 0.4rem;
      font-weight: normal;
      font-size: 1.4rem;
      min-height: 3.3rem;
    }
  }

  .description {
    padding: 1rem 0;
    //width: 70%;
    a{
      font-size: inherit;
      font-style: italic;
      color: var(--color-accent-purple)
    }
  }

  .filters .row {
    margin: 1rem 1rem 2rem 0rem;
    align-items: center;

    & > div {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  .clickable{    
    cursor: pointer;        
      :hover{
        background-color: rgba(0, 0, 0, 0.05);
        color: var(--color-accent-prim);    
      }
  }

  .select{   
      background-color: rgba(0, 0, 0, 0.1);
     // color: var(--color-accent-prim);    
      font-style: italic;
  }

  .nav{
    background-color: var(--color-accent-pink);
    height: 100%;
    box-shadow: 5px 6px 12px -5px black;
    padding-top: 4rem;
    & ul{
      display:flex;
      flex-direction:row;
      justify-content: space-between;
      flex-direction:column;
      text-align:left;
    }
    & a{ 
      font-size: 1.6rem;
      text-decoration: none;
      color: var(--color-text);
      width: 100%;
      padding-left: 4rem;
      margin-top: 2.4rem;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      & svg{
        margin-right: .8rem;
        width: 2rem;
        height: 2rem;
      }
      :hover{
        color: var(--color-accent-prim);
        opacity: .7;
        & svg{
            path{
              fill: var(--color-accent-prim);
            }
          }
      }
      &.active{
        color: var(--color-accent-prim);
        & svg{
          path{
            fill: var(--color-accent-prim);
          }
        }
      }
    }
  }

  .buttons {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      button {
        margin-right: 1rem;
      }
    }
`;
