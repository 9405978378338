import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import ContactInfo from "./Components/ContactInfo";
import { getContact, deleteContact } from "api/services/contact";
import Modal from "components/UI/Modal";
import { UseOrganizationContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import Delete from "components/UI/Icons/Delete";
import Edit from "components/UI/Icons/Edit";

const Container = styled.div`

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
  }
`;

const ViewContact = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(null);
  const { updateError } = UseErrorContext();
  const [showModalDelete, setShowModalDelete] = useState(false);   
  const organizationId = UseOrganizationContext()?.id;

  useEffect(async () => {
    if (!organizationId) return;
    await refresh();
  }, [organizationId]);

  const refresh = async () => {
    try{
      setLoading(true);
      const contactData = await getContact(id);
      setContact(contactData); 
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };
  
  const _deleteContact = async () => {
    try{
      setLoading(true);
      await deleteContact(id);
      setShowModalDelete(false);
      history.push("/contacts");
    } catch (err) {
      updateError(err);
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <>
      {showModalDelete && (
        <Modal title={`Verwijder "${contact.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _deleteContact()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u dit contact wil verwijderen?</div>
        </Modal>
      )}
      <Page>
        {!contact || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/contacts"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/contacts">Contacten</a>
                </li>
                <li>
                  <span className="selected">{contact?.fullName}</span>
                </li>
              </ul>
            </div>     
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>   
                <Link to={`/contacts/edit/${contact.id}`}>
                  <Button label="Wijzigen" roles={["Contacts.Create"]} type="secondary"><Edit/></Button>
                </Link>  
                <Button label="Verwijderen" onClick={() => {setShowModalDelete(true)}} type="secondary"><Delete/></Button>
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Campagne">              
                <ContactInfo contact={contact}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewContact;
