export const AccessRoles = {
  admin: "Admin",
};

export const ValidateAccess = async (roles, permissions) => {
  var permissionNames = permissions?.map((x) => x.name);

  if(!roles) return true
  if(!permissions) return true
  
  return roles.some((r) => permissionNames.includes(r)) || permissionNames.includes(AccessRoles.admin);
};
