import React from "react";
import styled from "styled-components";

const Container = styled.div`  
`;

const UserInfo = ({ user }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Voornaam</label>
            <input value={user.firstName} disabled/>
          </div>
          <div className="field">
            <label>Achternaam</label>
            <input value={user.lastName} disabled/>
          </div>
        </div>
        <div className="form_row">
          <div className="field">
            <label>E-mailadres</label>
            <input value={user.email} disabled/>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UserInfo;
