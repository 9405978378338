import { getAxiosClient } from "../axios";

export const getCampaigns = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/campaigns`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addCampaign = async (organizationId, campaign) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .post(`/organizations/${organizationId}/campaigns`, campaign)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getCampaign = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/campaigns/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getCampaignForParticipant = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/me/campaigns/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const updateCampaign = async (campaign) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/campaigns/${campaign.id}`, campaign)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const deleteCampaign = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .delete(`/campaigns/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getCampaignsForParticipant = async (id, params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/campaigns/participant/${id}`, { params: {...params}} )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  }; 
  
  export const getCampaignsForParticipantVouchers = async (id, organizationId) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/campaigns/${id}/participants/${organizationId}/vouchers`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const confirmCampaign = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/campaigns/${id}/confirm`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const sendInvites = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/campaigns/${id}/sendinvites`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };


  export const printVouchers = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/campaigns/${id}/print`, {
         responseType: 'blob', 
         headers: {
          Accept: 'application/pdf',
        }, 
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getCampaignPayments = async (id, params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/campaigns/${id}/payment`, { params: {...params}} )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const generateCampaignPayment = async (id) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/campaigns/${id}/payment`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const getCampaignOrganizations = async (params) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/campaigns/organizations`, { params: {...params}})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };