import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { addVoucher } from "api/services/voucher";
import { VoucherForm } from "./Components/VoucherForm";
import PageHeaderSub from "components/PageHeaderSub";
import Loader from "components/UI/Loader";

const AddVoucher = () => {
  const { productId } = useParams();
  const history = useHistory(); 
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (voucherData) => {
    try{
      setIsLoading(true);
      const rep = await addVoucher(voucherData);  
      setIsLoading(false);    
      history.push("/vouchers/view/" + rep.id + "/false");  
      return rep;          
    } catch (err) {
      updateError(err);
    }
    finally{
      setIsLoading(false);
    }
  };

  const onClose = async () => {
    history.push("/vouchers/view");   
  };

  return (
    <>
      <Page>      
      <div className="page__header">
        <h1>Cadeaubonnen</h1>
        <PageHeaderSub/>
       </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Nieuwe cadeaubon">
          {isLoading 
            ? <Loader />
           : <VoucherForm save={onSave} close={onClose} productId={productId}/>
          }
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default AddVoucher;
