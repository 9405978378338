import React, { useState, useEffect } from "react";
import { ProfileForm } from "./Components/ProfileForm";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { addOrganization, uploadLogo } from "api/services/organization";
import { UseErrorContext } from "contexts";
import { addOrganizationUser, updatePermissions } from "api/services/user";
import OrganizationTypeForm from "./Components/OrganizationTypeForm";
import Loader from "components/UI/Loader";
import { TownForm } from "./Components/TownForm";
import { CompanyForm } from "./Components/CompanyForm";

const AddProfile = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { updateError } = UseErrorContext();
  const [type, setType] = useState(null);

  const _addOrganization = async (data) => {
      setIsSaving(true);
      try{
        const org = await addOrganization(data);  
        if (data.cover) {
          await uploadLogo(org.id, data.cover);          
        }  
        history.go(0)
      } catch (err) {
        updateError(err);
      }    
      setIsSaving(false);  
  };

  const _addTownOrganization = async (data) => {
    setIsSaving(true);
    try{
      const org = await addOrganization(data);  
      if (data.cover) {
        await uploadLogo(org.id, data.cover);          
      }  
      history.go(0)
    } catch (err) {
      updateError(err);
    }    
    setIsSaving(false);  
};

const _addCompanyOrganization = async (data) => {
  setIsSaving(true);
  try{
    const org = await addOrganization(data);  
    if (data.cover) {
      await uploadLogo(org.id, data.cover);          
    }  
    history.go(0)
  } catch (err) {
    updateError(err);
  }    
  setIsSaving(false);  
};

  const _updateType = async (data) => {
    setType(data);
};

  return (
      <Page>
        <div className="page__header">
          <h1>Profiel</h1>
            <PageHeaderSub/>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Profiel aanmaken" extraOption={isSaving && "Opslaan..."}>
            {isSaving 
            ? <Loader/>
            : !type
              ? <OrganizationTypeForm setType={_updateType}/>
              : type==="company" 
                  ? <CompanyForm save={_addCompanyOrganization}/> 
                  : type==="town"
                    ? <TownForm save={_addTownOrganization}/> 
                    : <ProfileForm save={_addOrganization}/> 
            }       
          </HeaderDropDown>
        </div>
      </Page>    
  );
};

export default AddProfile;
