import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import { addInvoiceLine, mailInvoice } from "api/services/admin";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required("Verplicht"),
  quantity: Yup.number().required("Verplicht"),
  price: Yup.number().required("Verplicht"),
});

const AddInvoiceLineForm = ({ id, onClose }) => {
  const { updateError } = UseErrorContext();

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          description: "",
          quantity: 0,
          price: 0
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          addInvoiceLine(id, values)
            .then((value) => {
              onClose();  
            })
            .catch((err) => {
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="description" className="required">
                  Omschrijving
                </label>
                <Field type="text" name="description" />
                <ErrorMessage className="error-message" name="description" component="div" />
              </div>
            </div>  
            <div className="form_row">
              <div className="field">
                <label htmlFor="quantity" className="required">
                  Aantal
                </label>
                <Field type="number" name="quantity" />
                <ErrorMessage className="error-message" name="quantity" component="div" />
              </div>
            </div>  
            <div className="form_row">
              <div className="field">
                <label htmlFor="price" className="required">
                  Prijs
                </label>
                <Field type="number" name="price" />
                <ErrorMessage className="error-message" name="price" component="div" />
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => onClose()} type="secondary" />
                <Button onClick={handleSubmit} label="Aanmaken" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default AddInvoiceLineForm;
