import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderArrow from "./UI/Icons/HeaderArrow";
import Arrow from "./UI/Icons/Arrow";

const Container = styled.div`
  .nav-link {
    cursor: pointer;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  grid-template-columns: auto auto;
  align-items: end;
  padding-right: 1rem;

  :hover {
    & a {
      color: var(--color-accent-prim);
      opacity: 0.7;
    }
    & svg {
      path {
        fill: var(--color-accent-prim);
        fill-opacity: 0.7;
      }
    }
  }

  .arrow {
    svg {
      width: 2.8rem;
      height: auto;
    }
  }
`;

const MenuBody = styled.div``;

const MenuItem = ({ icon: Icon, label, children, showCollapse }) => {
  const [collapse, setCollapse] = useState(true);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Container>
      <MenuHeader onClick={() => showCollapse && toggleCollapse()}>
        <a href={false} className="nav-link">
          <Icon />
          {label}
        </a>
        {showCollapse && (
          <div className="arrow">
            <Arrow transform={collapse} />
          </div>
        )}
      </MenuHeader>
      {!collapse && <MenuBody>{children}</MenuBody>}
    </Container>
  );
};

export default MenuItem;
