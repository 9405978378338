import React, { useEffect, useState } from "react";
import Page from "components/UI/Elements/Page";
import { UseUserContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Button from "components/UI/Buttons/Button";
import { resetDemoData } from "api/services/demodata";
import { UseErrorContext } from "contexts";
import { getOrganizationUser } from "api/services/user";
import Loader from "components/UI/Loader";
import UserInfo from "./Components/UserInfo";

const User = () => {
  const { permissions, userName } = UseUserContext();
  const [showResetData, setShowResetData] = useState({});
  const [resetDataLoading, setResetDataLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { updateError } = UseErrorContext();
  const [documents, setDocuments] = useState([
    {'name': "Algemene voorwaarde", 'url': "https://stallesbon.blob.core.windows.net/public/TERMSANDCONDITIONS_DASHBOARD.pdf"},
    {'name': "Privacy policy", 'url': "https://stallesbon.blob.core.windows.net/public/PRIVACYPOLICY_DASHBOARD.pdf"}
  ]);

  useEffect(() => {
    setShowResetData(userName === "demo@allesbon.be");
  }, [userName])  
  
  useEffect(async () => {
    try{ 
      let u = await getOrganizationUser();
      setUser(u);
    } catch (err) {
      updateError(err);
    }
  }, [])

  const resetData = async function () {
    //reset data
    setResetDataLoading(true);
    try{
      await resetDemoData();      
    } catch (err) {
      updateError(err);
    }
    setResetDataLoading(false);
  }

  return (
    <Page>
      <div className="page__header">
        <h1>Instellingen</h1>   
        <PageHeaderSub show={showResetData}>
          <Button label="Reset Data" onClick={resetData} disabled={resetDataLoading} />
        </PageHeaderSub>
      </div>
      <div className="page__inner">  
        <HeaderDropDown titleHeader="Persoonlijke gegevens">
          {!user 
              ? <Loader center />
              : <UserInfo user={user}/>
          }
        </HeaderDropDown>
        <HeaderDropDown titleHeader="Documenten">
          <table cellSpacing="500">
              <thead>
                <tr>            
                  <th></th>
                  <th>Naam</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document, idx) => (            
                    <tr key={idx}>   
                      <td></td>           
                      <td><a href={document.url} target="_blank">{document.name}</a></td> 
                    </tr>          
                ))}
              </tbody>
            </table>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default User;
