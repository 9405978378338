import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import ProductInfo from "./Components/ProductInfo";
import Status from "components/UI/Status";
import { getProduct, deleteProduct, deactivateProduct, activateProduct } from "api/services/product";
import Trash from "components/UI/Icons/Trash";
import Modal from "components/UI/Modal";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import Edit from "components/UI/Icons/Edit";
import Delete from "components/UI/Icons/Delete";
import Add from "components/UI/Icons/Add";
import Voucher from "components/UI/Icons/Voucher";

const Container = styled.div`

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
  }
`;

const ViewProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { updateError } = UseErrorContext();
  const [showModalDeactivate, setShowModalDeactivate] = useState(false);  
  const [showModalActivate, setShowModalActivate] = useState(false);  

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const productData = await getProduct(id);
      setProduct(productData);    
    } catch (err) {
      updateError(err);
    }
  };
  
  const _deactivateProduct = async () => {
    try{
      await deactivateProduct(id);
      setShowModalDeactivate(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _activateProduct = async () => {
    try{
      await activateProduct(id);
      setShowModalActivate(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      {showModalDeactivate && (
        <Modal title={`Deactiveer "${product.displayName}"`} onClose={() => setShowModalDeactivate(false)} onSubmit={() => _deactivateProduct()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u dit product wil deactiveren?</div>
        </Modal>
      )}
      {showModalActivate && (
        <Modal title={`Activeer "${product.displayName}"`} onClose={() => setShowModalActivate(false)} onSubmit={() => _activateProduct()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <div>Bent u zeker dat u dit product wil activeren?</div>
        </Modal>
      )}
      <Page>
        {!product ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/products"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/products">Producten</a>
                </li>
                <li>
                  <span className="selected">{product?.displayName}</span>
                </li>
              </ul>
            </div>     
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>         
                {product.status == "Actief" && 
                  <>
                    <Button onClick={() => setShowModalDeactivate(true)} label="Deactiveren" type="tertiary" roles={["Products.Create"]} type="secondary"><Delete/></Button>
                    <Link to={`/products/edit/${product.id}`}>
                      <Button label="Wijzigen" roles={["Products.Create"]} type="secondary"><Edit/></Button>
                    </Link> 
                    <Link to={`/vouchers/add/${product.id}`}>
                      <Button label="Cadeaubon maken" roles={["Vouchers.Create"]}><Voucher/></Button>
                    </Link> 
                  </>
                }  
                {product.status == "Inactief" && 
                  <>
                    <Button onClick={() => setShowModalActivate(true)} label="Activeren" type="tertiary" roles={["Products.Create"]} type="secondary"><Add/></Button>
                  </>
                } 
                <Status className={product.status} label={product.status}/>          
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Product">              
                <ProductInfo product={product}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewProduct;
