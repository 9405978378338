import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  type: Yup.string().required("Verplicht"),
});

const OrganizationTypeForm = ({ setType }) => {
  const { updateError } = UseErrorContext();

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          type: "default",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setType(values.type)
            .then((value) => {
              
            })
            .catch((err) => {
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="type" className="required">
                  Soort profiel
                </label>
                <Field as="select" name="type">
                  <option value="default">Handelaar: ik wens cadeaubonnen te verkopen</option>
                  <option value="company">Bedrijf: ik wens cadeaubonnen te kopen voor mijn personeel/klantenrelaties</option>
                  <option value="town">Gemeente: ik wens cadeaubonnen te kopen voor mijn inwoners</option>
                </Field>  
                <ErrorMessage className="error-message" name="type" component="div" />
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button onClick={handleSubmit} label="Verder" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default OrganizationTypeForm;
