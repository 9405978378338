import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDateToString , FormatDateTimeToString} from "global/datahelper";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const InvoiceInfo = ({ invoice }) => {
console.log(invoice)
  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Nummer</label>
            <input value={invoice.reference} disabled/>
          </div>
        </div> 
        { invoice.organizationId
        ? 
        <div className="form_row">
          <div className="field">
            <label>Organisatie</label>
            <input value={invoice.organization.name} disabled/>
          </div>
        </div> 
        : 
        <>
          <div className="form_row">
            {invoice.orderId > 0 &&
              <div className="field">
                <label>Order</label>
                <input value={invoice.orderId} disabled/>
              </div>
            }
            {invoice.campaignId > 0 &&
              <div className="field">
                <label>Campagne</label>
                <input value={invoice.campaignId} disabled/>
              </div>
            }
          </div> 
          <div className="form_row">
            <div className="field">
              <label>Klant</label>
              <a href={`/admin/orders/${invoice.orderId}/customer/${invoice.customer.id}`}>{invoice.customer.name}</a>
            </div>
            <div className="field">
              <label>Bedrijf</label>
              <a href={`/admin/orders/${invoice.orderId}/customer/${invoice.customer.id}`}>{invoice.customer.company}</a>
            </div>
          </div> 
         </>
      }
        <div className="form_row">
          <div className="field">
            <label>Periode</label>
            <input value={`${invoice.month}/${invoice.year}`} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Aangemaakt op</label>
            <input value={FormatDateTimeToString(invoice.createDate)} disabled/>
          </div>
          <div className="field">
            <label>Vervaldag</label>
            <input value={FormatDateToString(invoice.dueDate)} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Intracomm.</label>
            <SwitchButtonOn on={invoice.isIntraComm} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Subtotaal</label>
            <input value={"€" + invoice.subtotal} disabled/>
          </div>
          <div className="field">
            <label>Btw</label>
            <input value={"€" + invoice.vat} disabled/>
          </div> <div className="field">
            <label>Totaal</label>
            <input value={"€" + invoice.total} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Balanstotaal</label>
            <input value={"€" + invoice.balanceTotal} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default InvoiceInfo;
