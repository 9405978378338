import React from "react";
import styled from "styled-components";
import Button from "../Buttons/Button";

const Container = styled.span`
    .status{        
        background-color: transparent;
        &:hover {
            opacity: 1;
            transition: none;
        }
    }
    .Invited{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);
        cursor: default;
    }
    .Created{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .Deleted{
        border: var(--color-status-bad) solid 1px;
        color: var(--color-status-bad);
        cursor: default;
    }
    .Hidden{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);        
        opacity: 0.7;
        transition: all 0.25s ease-in;
    }
    .Finished{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .Invisible{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);        
        opacity: 0.7;
        transition: all 0.25s ease-in;
    }
    .Visible{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
    }
    .Actief{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .Inactief{
        border: var(--color-status-bad) solid 1px;
        color: var(--color-status-bad);
        cursor: default;
    }
    .Ingewisseld{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);
        cursor: default;
    }
    .Vervallen{
        border: var(--color-status-warning) solid 1px;
        color: var(--color-status-warning);
        cursor: default;
    }
    .Finaalvervallen{
        border: var(--color-status-bad) solid 1px;
        color: var(--color-status-bad);
        cursor: default;
    }
    .Afgewezen{
        border: var(--color-status-bad) solid 1px;
        color: var(--color-status-bad);
        cursor: default;
    }
    .Verwijderd{
        border: var(--color-status-bad) solid 1px;
        color: var(--color-status-bad);
        cursor: default;
    }
    .notAllowed{
        cursor: not-allowed;
    }
    .true{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .false{
        border: var(--color-status-warning) solid 1px;
        color: var(--color-status-warning);
        cursor: default;
    }
    .Nieuw{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);
        cursor: default;
    }
    .Teversturen{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);
        cursor: default;
    }
    .Bevestigd{
        border: var(--color-status-warning) solid 1px;
        color: var(--color-status-warning);
        cursor: default;
    }
    .Gelanceerd{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .Verzonden{
        border: var(--color-status-info) solid 1px;
        color: var(--color-status-info);
        cursor: default;
    }
    .Betaald{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .Inbehandeling{
        border: var(--color-status-warning) solid 1px;
        color: var(--color-status-warning);
        cursor: default;
    }
    .Toegevoegd{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
`;

export default function Status({ className, label, onClick, notAllowed }) {
    const advancedOnClick = () => {
        if (notAllowed) {
            return false;
        }

        onClick();
    }

    return (
        <Container>
            <Button type={`status ` + className?.replace(/\s/g, "") ?? label + (notAllowed ? ' notAllowed' : '')} label={label} onClick={advancedOnClick} />
        </Container>
    );
}

Status.defaultProps = {
    label: "",
};
