import React from "react";
import styled from "styled-components";
import Loader from "components/UI/Loader";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  margin-top:4rem;
  table{
    margin-top:2rem;
  }
`;

const OrderLineGrid = ({ items }) => {
  const history = useHistory();
 
  const open = (id) => {   
    //history.push("/admin/products/" + id);
  };

  return (
    <Container> 
     <label>Lijnen</label>
     <table cellSpacing="500">
        <thead>
          <tr>            
            <th></th>
            <th>Organisatie</th>
            <th>Product</th>
            <th>Aantal</th>
            <th>Bedrag</th>
            <th>Totaal</th>
          </tr>
        </thead>
        <tbody>
        {!items 
          ? <Loader center />
           : <> 
              {items.map((item, idx) => (          
                  <tr key={item.id + '_' + idx} onClick={() => open(item.id)} className="clickable"> 
                    <td></td>              
                    <td>{item.product.organization.name}</td> 
                    <td>{item.product.displayName}</td>            
                    <td>{item.quantity}</td>            
                    <td>{"€" + item.price}</td>           
                    <td>{"€" + item.totalPrice}</td>           
                  </tr>      
              ))}
             </>
        }
        </tbody>
      </table>
    </Container>
  );
};

export default OrderLineGrid;
