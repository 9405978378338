import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import { undoRedeemVoucher } from "api/services/voucher";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import { UseOrganizationContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  user: Yup.string().required("Verplicht"),
  reason: Yup.string().required("Verplicht"),
});

const UndoVoucherLineForm = ({ id, onClose }) => {
  const { updateError } = UseErrorContext();
  const organizationId = UseOrganizationContext()?.id;

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          user: "",
          reason: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          undoRedeemVoucher(organizationId, id, values)
            .then((value) => {
              onClose();  
            })
            .catch((err) => {
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="user" className="required">
                  Gebruiker
                </label>
                <Field type="text" name="user" />
                <ErrorMessage className="error-message" name="user" component="div" />
              </div>
            </div>      
            <div className="form_row">
              <div className="field">
                <label htmlFor="reason" className="required">
                  Reden
                </label>
                <Field as="textarea" name="reason" maxLength="500"/>
                <ErrorMessage className="error-message" name="reason" component="div" />
              </div>
            </div>       
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Annuleer" onClick={() => onClose()} type="secondary" />
                <Button onClick={handleSubmit} label="Bevestig" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default UndoVoucherLineForm;
