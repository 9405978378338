import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "components/UI/Images/Image";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const Container = styled.div`
  .section {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  textarea {
    width: 100%;
    height: 15rem;
    &.short_description {
      height: 7.5rem;
    }
  }
`;

const CampaignInfo = ({ campaign }) => {

  return (
    <Container>     
      <div className="form">
        <div className="form_row">
          <div className="field">
            <label>Naam</label>
            <input value={campaign.name} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label>Bedrag in (€)</label>
            <input value={campaign.price} disabled/>
          </div>
        </div> 
        <div className="form_row">
          <div className="field">
            <label htmlFor="hidePrice">
              Bedrag verbergen
            </label>
            <SwitchButtonOn
              on={campaign.hidePrice}
              disabled
            />
         </div>
      </div> 
      <div className="form_row">
          <div className="field">
            <label>Aantal cadeaubonnen</label>
            <input value={campaign.quantity} disabled/>
          </div>
        </div> 
      </div>
    </Container>
  );
};

export default CampaignInfo;
