import React from "react";
import styled from "styled-components";
import Loader from "../Loader";
import imageThumb from "../../../assets/no-thumbnail.png";

const Div = styled.div`
  cursor: pointer;
  .image {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .image_thumb {
    position: absolute;
    height: 100%;
  }
  input {
    opacity: 0;
  }  
  .loader{
    position: absolute;
    top: calc(50% - 4rem);
    left: calc(50% - 4rem);  
  }
  :hover{
    opacity: .7;
  }
`;
export default function Image( {url, onChange, isLoading, disabled, caching=true} ) {

  console.log("URL", url)

  return (
    <Div>   
    { url 
      ? <img className="image" src={caching ? url + "?" + (new Date()).getTime() : url} alt="" style={{ opacity: isLoading ? .5 : 1 }}/>
      : <img className="image_thumb" src={imageThumb} alt="" style={{ opacity: isLoading ? .5 : 1 }}/>
    }
    { !disabled &&
      <>
        { !isLoading && <input title="Upload image" className="image" type="file" accept="image/png, image/jpeg, image/jpg" onChange={onChange} /> }
        { isLoading && <div className="loader"><Loader /> </div>}
      </>
    }
    </Div>
  );
}