import React, { useState, useEffect } from "react";
import { UseOrganizationContext } from "contexts";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { delay } from "global/datahelper";
import SearchBar from "components/UI/SearchBar";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import { getAdminOrganizations } from "api/services/admin";
import OrganizationGrid from "./Components/OrganizationGrid";

const AdminOrganizations = () => {
  const [search, setSearch] = useState("");
  const [organizationsToConfirm, setOrganizationsToConfirm] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsDeleted, setOrganizationsDeleted] = useState([]);
  const [organizationsRejected, setOrganizationsRejected] = useState([]);
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  // Init
  useEffect(async () => {
    refresh();
  }, [ search, currentPage]);

  // Refresh
  const refresh = async () => {   
    try{        
      setLoading(true);

      // GET endpoint
      const organizationsToConfirmResponse = await getAdminOrganizations({
        'page': currentPage,
        'search': search,
        'status': "In behandeling"
      });
      setOrganizationsToConfirm(organizationsToConfirmResponse.data);

      const organizationsDeletedResponse = await getAdminOrganizations({
        'page': currentPage,
        'search': search,
        'status': "Verwijderd"
      });
      setOrganizationsDeleted(organizationsDeletedResponse.data);

      const organizationsRejectedResponse = await getAdminOrganizations({
        'page': currentPage,
        'search': search,
        'status': "Afgewezen"
      });
      setOrganizationsRejected(organizationsRejectedResponse.data);

      const organizationsResponse = await getAdminOrganizations({
        'page': currentPage,
        'search': search
      });
      
      // Set data
      let organizationsData = organizationsResponse.data;
      setOrganizations(organizationsData);

      // Pagination
      let organizationsHeader = organizationsResponse.headers; 
      
      let pagination = GetPagination(organizationsHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
      await delay(1000);
      const val = e.target.value.toString();
      setSearch(val);
  };
  
  // Filters
  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };

  return (
    <Page>
      <div className="page__header">
        <h1>Organisaties</h1>
        <PageHeaderSub>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch} />
        </div>
        <HeaderDropDown titleHeader="Te bevestigen">
          {!organizationsToConfirm || loading ? (
            <Loader center />
          ) : (
            <OrganizationGrid canEdit organizations={organizationsToConfirm} refresh={refresh} />
          )}
        </HeaderDropDown>
        <HeaderDropDown titleHeader="Bevestigd">
          {!organizations || loading ? (
            <Loader center />
          ) : (
            <>
              <OrganizationGrid canEdit organizations={organizations} refresh={refresh} />
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
        <HeaderDropDown titleHeader="Afgewezen">
          {!organizationsDeleted || loading ? (
            <Loader center />
          ) : (
            <>
              <OrganizationGrid canEdit organizations={organizationsRejected} refresh={refresh} />
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
        <HeaderDropDown titleHeader="Verwijderd">
          {!organizationsDeleted || loading ? (
            <Loader center />
          ) : (
            <>
              <OrganizationGrid canEdit organizations={organizationsDeleted} refresh={refresh} />
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default AdminOrganizations;
