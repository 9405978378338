import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "./Buttons/Button";
import { UseErrorContext } from "contexts";

const Div = styled.div`  
  z-index: 9999;
  position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	justify-items: center;
	align-items: center;
  background: rgba(0, 0, 0, 0.7);
  top: 0;

  .error_modal{    
    width: 600px;

    &__header {
      background-color: var(--color-dark-grey);
      padding: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: var(--color-text-light);
    } 
    &__body{
      background-color: var(--color-light-grey);
      padding: 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: auto;
      box-shadow: 5px 6px 12px -5px black;
      color: var(--color-text);
    } 
  }    
`;

const Error = () => {
  const { errorMessage, updateError } = UseErrorContext();
  
  const onClose = () => {
    updateError("");
  }

  return (
    <>
      { errorMessage &&
      <Div>   
          <div className="error_modal">
            <div className="error_modal__header">
              <p>{errorMessage?.response?.data?.status} ERROR</p>
            </div>
            <div className="error_modal__body">
              <p>{errorMessage?.response?.data?.title ?? errorMessage?.message ?? ""}</p>         
              <div className="modal-buttons">
                <Button label="close" onClick={() => onClose()} type="primary" />
              </div>
            </div>   
          </div>        
      </Div>
      }
    </>
  );
};

export default Error;
