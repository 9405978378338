import styled from "styled-components";
import React, { useState, useEffect } from "react";

const B = styled.div`

.toggle-switch{
  background: linear-gradient(#ccc, #eee);
  height: 2rem;
  width: 5rem;
  border-radius: 1rem;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'}};
}
.knob{
  position: relative;
  width: 1.9rem;
  height: 1.9rem;
  background: var(--color-dark-grey);
  border: 1px solid #ddd;
  border-radius: 50%;
  transition: left 0.3s ease-out;
  left: 0rem;
  &.active{
    left: 3rem;
    background: var(--color-accent-prim);
  }
  }
`;

export default function SwitchButtonOn({ on, onSwitchOn, onSwitchOff, disabled }) {
  const [isToggleOn, setIsToggleOn] = useState(on);

  useEffect(async () => {
    setIsToggleOn(on);
  }, [on]);

  const onSwitch = (e) => { 
    if (disabled) 
      return;  
    if(isToggleOn){
      onSwitchOff()
    }else{
      onSwitchOn()
    }
    setIsToggleOn(!isToggleOn);
  };

  return (
    <B disabled={disabled}>
      <div
        onClick={onSwitch}
        className="toggle-switch">
        <div className={isToggleOn ? "knob active disabled" : "knob"} />
      </div>
    </B>
  );
}

SwitchButtonOn.defaultProps = {
  on: false,
  onSwitchOn: () => {
    console.log("switch on");
  },
  onSwitchOff: () => {
    console.log("switch off");
  },
};
