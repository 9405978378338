import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { BaDoughnutr, Doughnut } from 'react-chartjs-2';
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { getDayReport } from "api/services/report";
import { FormatDateTime, Round } from "global/datahelper";
import Loader from "components/UI/Loader";

ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  table{
    margin: 2rem 0;
  }
`;

const DayReport = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [chartData, setChartData] = useState({datasets: [],});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (organizationId){
      try{
        setIsLoading(true);
        let date = Date.now();
        const reportData = await getDayReport(organizationId, {
          date: FormatDateTime(date)
        });  
        console.log(reportData)
        setData(reportData); 
      
        setChartData({
          labels: [
            'Verkocht winkel (€)', 
            'Verkocht online (€)', 
            'Ingewisselde cadeaubonnen (€)',
            'Ingewisselde bedrijfsbonnen (€)',
            'Ingewisselde gemeentebonnen (€)',
          ],
          datasets: [
            {
              label: '',
              data: [
                reportData.storeCreatedVouchersTotalPrice, 
                reportData.onlineCreatedVouchersTotalPrice, 
                reportData.redeemedVouchersDefaultTotalPrice,
                reportData.redeemedVouchersCompanyTotalPrice,
                reportData.redeemedVouchersTownTotalPrice,
              ],
              backgroundColor: [
                'rgba(153, 102, 255, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(240, 150, 150, 0.2)',
                'rgba(240, 240, 150, 0.2)',
              ],
              borderColor: [
                'rgba(153, 102, 255, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(240, 150, 150, 1)',
                'rgba(240, 240, 150, 1)',
              ],
              borderWidth: 1,
              }
            ]
          }); 

          setIsLoading(false); 
      } catch (err) {
        updateError(err);
      }  
    }      
  }, [organizationId])

  return (
    <>
      {isLoading 
        ? <Loader />
        : <Container> 
        <table cellSpacing="500">
          <thead>
            <tr>    
              <th>Verkocht: </th>    
              <th>Winkel</th>
              <th>Webshop</th>
            </tr>
          </thead>
          <tbody>
            <tr>   
              <td></td>           
              <td>€ {Round(data.storeCreatedVouchersTotalPrice, 2)}</td>
              <td>€ {Round(data.onlineCreatedVouchersTotalPrice,2)}</td>         
            </tr>   
          </tbody>
        </table>

        <table cellSpacing="500">
          <thead>
            <tr>         
              <th>Ingewisseld: </th>
              <th>Cadeaubon</th>
              <th>Bedrijfsbon</th>
              <th>Gemeentebon</th>
            </tr>
          </thead>
          <tbody>
            <tr>   
              <td></td>           
              <td>€ {Round(data.redeemedVouchersDefaultTotalPrice, 2)}</td>   
              <td>€ {Round(data.redeemedVouchersCompanyTotalPrice, 2)}</td>   
              <td>€ {Round(data.redeemedVouchersTownTotalPrice, 2)}</td>             
            </tr>   
          </tbody>
        </table>
        <Doughnut data={chartData} />
      </Container>
      }
    </>    
  );
};

export default DayReport;
