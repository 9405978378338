import React from "react";
import { FaArchive } from "react-icons/fa";

export default function Zip(props) {

  return (
    <>
      <div className="icon-button">
        <FaArchive/>
      </div>
    </>
  );
}
