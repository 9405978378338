import React, { useState } from "react";
import styled from "styled-components";
import thumbnail from "../../../assets/no-thumbnail.png";
import PrivateCard from "components/PrivateCard";

const Div = styled.div`
  position: relative;
  .card {
    &__content {
      color: var(--color-text);
      &__footer {
        font-size: 1.2rem;
      }
    }
  }
  .card_image__container {
    img {
      cursor: pointer;
    }
  }
`;

const ProductCard = ({ id, cover, name, price, variablePrice }) => {

  return (
    <>
      <Div className="card">
        <PrivateCard to={`/products/view/${id}`} roles={["Products.Read"]}>
          <div className="card_image__container">
            <img src={cover ? cover + "?" + (new Date()).getTime() : thumbnail} alt="" />
          </div>
          <div className="card__content">
            <div className="card__content__header">
              <div className="card__title">Cadeaubon</div>
              <div className="card__subtitle">{variablePrice ? "Variabel" : "€" + price}</div>
            </div>
            <div className="card__content__body">
              <p>{name}</p>
            </div>
          </div>
        </PrivateCard>
      </Div>
    </>
  );
};

ProductCard.defaultProps = {
  cover: thumbnail,
  name: "Naam",
  price: 0
};

export default ProductCard;
