import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import CampaignGrid from "pages/campaigns/Components/CampaignGrid";
import Button from "components/UI/Buttons/Button";
import SearchBar from "../../components/UI/SearchBar";
import { UseOrganizationContext } from "contexts";
import { delay } from "global/datahelper";
import { UseErrorContext } from "contexts";
import { getCampaigns, getCampaignsForParticipant } from "api/services/campaign";
import Loader from "components/UI/Loader";
import { GetPagination } from "global/datahelper";
import Pagination from "components/Pagination";
import Add from "components/UI/Icons/Add";

const Campaigns = () => {
  const [search, setSearch] = useState("");
  const [campaigns, setCampaigns] = useState(null);
  const organizationId = UseOrganizationContext()?.id;  
  const { isCompany, isTown, name } = UseOrganizationContext();
  const { updateError } = UseErrorContext();
  const [statusFilter, setStatusFilter] = useState();
  const [statusFilters, setStatusFilters] = useState(["Nieuw", "Gelanceerd", "Verwijderd"]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search, currentPage, statusFilter]);

  const refresh = async () => {   
    try{      
      setLoading(true);

      let campaignsResponse;
      if (isCompany || isTown || name == "AllesBon"){
        campaignsResponse = await getCampaigns(organizationId, {
          'page': currentPage,
          'search': search,
          'status': statusFilter
        });
      }
      else{
        campaignsResponse = await getCampaignsForParticipant(organizationId, {
          'page': currentPage,
          'search': search,
          'status': statusFilter
        });
      }

      setCampaigns(campaignsResponse.data); 

      // Pagination
      let campaignsHeader = campaignsResponse.headers; 
      
      let pagination = GetPagination(campaignsHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
            
      setNumberOfPages(nbrOfPages);

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };
  
  // Filters
  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <h1>CAMPAGNES</h1>
          <PageHeaderSub show>
            <Link to="campaigns/add">
              <Button label="Nieuwe campagne" roles={["Campaigns.Create"]}><Add/></Button>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="description">
            {isTown || isCompany
              ? <p>Hier kan u een overzicht vinden van alle aangemaakte campagnes.</p>
              : <p>Hier kan u een overzicht vinden van alle campagnes waaraan u deelneemt of heeft deelgenomen. Om deel te kunnen nemen dient u onder uw profiel 'deelnemen aan bedrijfsbonnen' en/of 'deelnemen aan gemeentebonnen' aan te vinken.</p>
            }
            </div>
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="Alle campagnes">
            {loading ? (
              <Loader center />
            ) : (
              <>
                <div className="filters">
                  <div className="row">
                    <div>
                      <label>Status</label>
                      <select onChange={onChangeStatusFilter} value={statusFilter}>
                        <option value="">Alle</option>
                        {statusFilters.map((filter, idx) => (
                          <option key={idx}>{filter}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <CampaignGrid items={campaigns}/>
                <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />                
                {campaigns && campaigns?.length === 0 && <i>Geen campagnes gevonden</i>}
              </>
            )}
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default Campaigns;
