import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { getCustomer, updateCustomer } from "api/services/admin";
import PageHeaderSub from "components/PageHeaderSub";
import Invoice from "components/UI/Icons/Invoice";
import Button from "components/UI/Buttons/Button";
import { CustomerForm } from "./Components/CustomerForm";

const Container = styled.div``;

const ViewAdminCustomer = () => {
  const { orderId, id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const history = useHistory();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const orderData = await getCustomer(id);
      setCustomer(orderData);    
    } catch (err) {
      updateError(err);
    }
  };

  const onSave = async (customerData) => {
    try{
      setLoading(true);
      const rep = await updateCustomer(id, customerData);    
      await refresh();         
      setLoading(false);      
    } catch (err) {
      updateError(err);
    }    
    setLoading(false);        
  };

  return (
    <>
      <Page>
        {!customer || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link={`/admin/orders/${orderId}`}/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/admin/orders">Orders</a>
                </li>
                <li>
                  <a href={`/admin/orders/${orderId}`}>{orderId}</a>
                </li>
                <li>
                  <span className="selected">{customer?.firstName} {customer?.lastName}</span>
                </li>
              </ul>
            </div>   
            <div className="page__header">  
              <h1></h1>  
              <PageHeaderSub>        
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Klant">  
              {!customer || loading ? (
                <Loader center />
              ) : (           
                <> 
                  <CustomerForm customer={customer} save={onSave}/>
                </>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewAdminCustomer;
