import React, { useState, useEffect } from "react";
import { ValidateAccess } from "global/authorization";
import { UseUserContext } from "contexts";
import { FaTrash } from "react-icons/fa";

export default function Trash(props) {
  const [hide, setHide] = useState(true);
  const { permissions } = UseUserContext();

  useEffect(async () => {
    const access = await ValidateAccess(props.roles, permissions);
    setHide(!access);
  }, [permissions]);

  return (
    <>
    { !hide &&
      <div className="icon-button">
        <FaTrash/>
      </div>
    }  
    </>
  );
}
