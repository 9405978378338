import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Page from "components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { deleteVoucher, getVoucher, printVoucher } from "api/services/voucher";
import PageHeaderSub from "components/PageHeaderSub";
import VoucherInfo from "pages/vouchers/Components/VoucherInfo";
import Status from "components/UI/Status";
import VoucherHistory from "pages/vouchers/Components/VoucherHistory";
import Button from "components/UI/Buttons/Button";
import Print from "components/UI/Icons/Print";
import { UseOrganizationContext } from "contexts";
import Delete from "components/UI/Icons/Delete";
import Modal from "components/UI/Modal";
import { useHistory } from "react-router-dom";
import Send from "components/UI/Icons/Send";
import MailVoucherForm from "pages/vouchers/Components/MailVoucherForm";

const Container = styled.div``;

const ViewAdminVoucher = () => {
  const { id } = useParams();
  const history = useHistory();
  const [voucher, setVoucher] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const organizationId = UseOrganizationContext()?.id;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalMailVoucher, setShowModalMailVoucher] = useState(false);

  useEffect(async () => {
    if (!organizationId) return;
    await refresh();
  }, [organizationId]);

  const refresh = async () => {
    try{
      const voucherData = await getVoucher(organizationId, id);
      setVoucher(voucherData);    
    } catch (err) {
      updateError(err);
    }
  };

  const _printVoucher = async () => {
    try{      
      setLoading(true);
      const result = await printVoucher(id);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };
  

  const _delete = async () => {
    try{
      await deleteVoucher(voucher.id);
      setShowModalDelete(false);
      history.push("/admin/vouchers");
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      <Page>
        {!voucher || loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/admin/vouchers"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/admin/vouchers">Cadeaubonnen</a>
                </li>
                <li>
                  <span className="selected">{voucher?.barcode}</span>
                </li>
              </ul>
            </div>      
            {showModalDelete && (
              <Modal title={`Verwijder cadeaubon "${voucher?.barcode}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
                <div>Bent u zeker dat u deze cadeaubon wil verwijderen?</div>
              </Modal>
            )}      
            {showModalMailVoucher && (
              <Modal title="Verstuur via e-mail" hideActions>
                <MailVoucherForm voucher={voucher} onClose={async () => {setShowModalMailVoucher(false); await refresh();}}/>
              </Modal>
            )}   
            <div className="page__header">  
              <h1></h1>  
              <PageHeaderSub show>
                <Button label="Verwijderen" onClick={async () => setShowModalDelete(true)} type="secondary"><Delete/></Button>              
                <Button label="Afdrukken" onClick={async () => _printVoucher()} type="secondary"><Print/></Button> 
                <Button label="Verstuur" onClick={async () => setShowModalMailVoucher(true)}><Send/></Button> 
                <Status className={voucher?.status} label={voucher?.status}/> 
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Cadeaubon">  
              {!voucher || loading ? (
                <Loader center />
              ) : (           
                <> 
                  <VoucherInfo voucher={voucher}/>
                  <VoucherHistory items={voucher.voucherHistoryItems}/>
                </>
              )
              }
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewAdminVoucher;
